import React from 'react';
import {Grid, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setUnits} from "../../../redux/reducers/cachedReducer/actions";
import StyledSelect from "../../StyledInputs/StyledSelect";


function GeneralSettings() {
	const {t} = useTranslation()
	const {units} = useSelector((state) => state.cachedReducer)
	const dispatch = useDispatch()

	return (
		<Grid container style={{padding: '10px', height: '100%'}}>
			<Grid item xs={12} sm={6} style={{border: 'solid 1px white', padding: '10px'}}>
				<div style={{height: '100%', position: 'relative'}}>
					<form>
						<div style={{marginTop: '5px'}}>
                            <span style={{fontSize: '17px', color: 'white', paddingRight: '10px'}}>{t('units')}:</span>
							<StyledSelect value={units} onChange={(e) => dispatch(setUnits(e.target.value))}>
							<MenuItem value="meters">{t('meters')}</MenuItem>
							<MenuItem value="inches">{t('inches')}</MenuItem>
						</StyledSelect>
						</div>
					</form>
				</div>
			</Grid>
		</Grid>
	);
}

export default GeneralSettings;
