import React from 'react';
import {useSelector} from "react-redux";
import ProcessAction from "../../GeneralComponents/ProcessAction/ProcessAction";
import StatusScanningError from "../../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../../GeneralComponents/UnknownStatus/UnknownStatus";
import {LABELING_ERROR, LABELING_IS_RUNNING, LABELING_ROBOT_ERROR} from "../../statusConstants";
import ProcessingActionScreen from "../../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";

const LabelingComponent = ({screenVersion}) => {
    const {labelingStatus} = useSelector(state => state.statusReducer)

    const statusRender = (status) => {
        switch (status) {
            case LABELING_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={status}/> :
                    <ProcessingActionScreen status={status}/>
            case LABELING_ERROR:
            case LABELING_ROBOT_ERROR:
                return <StatusScanningError status={status} screenVersion={screenVersion}/>
            default:
                return <UnknownStatus/>
        }
    }
    return statusRender(labelingStatus)
};

export default LabelingComponent;