import React from 'react';
import {Typography} from "@mui/material";
import AssembledCounter from "../../ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import PlannedCounter from "../../ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";
import {useTranslation} from "react-i18next";

const UnknownStatus = ({isMobile, executor, planner}) => {
    const {t} = useTranslation()
    return (
        <div style={isMobile? {textAlign: 'center', height: '100%', flexDirection: 'column'} :
          {height: '100%', flexDirection: 'column'}} className={'globalCentered'}>
            <Typography color={'white'} fontSize={isMobile ? 20 : 50}> {t('unknown_status')}</Typography>
          {executor && <AssembledCounter/>}
          {planner && <PlannedCounter/>}
        </div>
    );
};

export default UnknownStatus;
