import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GeneralArchiveTable from "../../components/Tables/ArchiveTable/GeneralArchiveTable";
import Columns from "./Columns";
import {selectArchivedTask, unselectArchiveTask} from "../../redux/reducers/archiveReducer/actions";

function ArchiveTable({filter, changeFilter}) {

  const headers = ['Status', 'CadModelHeader', 'RotationHeader', 'AssembleStarted', 'EfficientTime', 'OverallAssembleTime', 'Operator']
  const {archiveTasksList,archiveTasksAreLoading, selectedModelName, selectedTaskId} = useSelector((state => state.archiveReducer))
  const {rows}  = Columns(archiveTasksList)
  const dispatch = useDispatch()
  const onRowClick = (id, model_name) => {
    if(selectedModelName === model_name && id === selectedTaskId){
      dispatch(unselectArchiveTask())
    }else{
      dispatch(selectArchivedTask(id, model_name))
    }
  }
  return <GeneralArchiveTable headers={headers} rows={rows} onRowClick={onRowClick} tableIsLoading={archiveTasksAreLoading}
                              filter={filter} changeFilter={changeFilter} activeItem={selectedTaskId}/>
}

export default ArchiveTable;
