import React, {memo, useMemo} from 'react';
import SingleModelPart from "../Viewer3DContainer/Viewer3DLoader/SingleModelDynamic/SignleModelPart";
import {partGeometryParser} from "../../../utils/three/loader";


function Loader({viewerSettings, model, id}) {

	const geometry = useMemo(() => partGeometryParser({model, id}), [model])

	return (
		<group position={[0, 0, 0]} scale={10}>
			<SingleModelPart key={id} geometry={geometry} viewerSettings={viewerSettings} no_matrix={true}/>
		</group>
	);
}

export default memo(Loader, (prevProps, nextProps) =>
	prevProps.model === nextProps.model
);



