export const GET_ARCHIVE_TASKS_REQUEST = 'GET_ARCHIVE_TASKS_REQUEST'
export const GET_ARCHIVE_TASKS_SUCCESS = 'GET_ARCHIVE_TASKS_SUCCESS'
export const SELECT_ARCHIVED_TASK = 'SELECT_ARCHIVED_TASK'
export const UNSELECT_ARCHIVE_TASK = 'UNSELECT_ARCHIVE_TASK'
export const GET_ARCHIVE_MODEL_SUCCESS = 'GET_ARCHIVE_MODEL_SUCCESS'
export const GET_ARCHIVE_MODEL_FAIL = 'GET_ARCHIVE_MODEL_FAIL'
export const SET_ARCHIVE_MODELS_MISSED_PART = 'SET_ARCHIVE_MODELS_MISSED_PART'


export const GET_GRAPH_DATA = 'GET_GRAPH_DATA'
export const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS'
export const GET_GRAPH_EXCEL = 'GET_GRAPH_EXCEL'
export const GET_GRAPH_EXCEL_SUCCESS = 'GET_GRAPH_EXCEL_SUCCESS'
