import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  height: 70%;
  background-color: white;
  border-radius: 0;
  
  .insp_buttons{
    padding: 5px 20px;
    margin: 0;
    height: 100%;
    border-radius: 0;
    border: 1px solid white;
    background-color: #050505;
    color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .sel_insp_buttons{
    padding: 5px 20px;
    margin: 0;
    height: 100%;
    border-radius: 0;
    border: 1px solid white;
    background-color: #333;
    color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-bottom: 4px solid white;
  }
  .insp_buttons:hover, .sel_insp_buttons:hover{
    background-color: #333;
  }
`;
