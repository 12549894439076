import {SET_INITIAL_VIEWER_SETTINGS, SET_PARTS_TYPE, SET_UNITS, SET_VIEWER_CONSTANTS} from "./types";

export const setInitialViewerSettings = () => ({
    type: SET_INITIAL_VIEWER_SETTINGS
})

export const setViewerConstants = (value) => ({
    type: SET_VIEWER_CONSTANTS, payload: value
})

export const setPartsType = (value) => ({
    type: SET_PARTS_TYPE, payload: value
})

export const setUnits = (val) => ({
    type: SET_UNITS, payload: val
})