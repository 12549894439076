import React from 'react';
import {useSelector} from "react-redux";
import {
  IDLE,
  MATCHING_BEAM_CONFIRMED,
  MATCHING_BEAM_ERROR,
  MATCHING_BEAM_IS_RUNNING,
  MATCHING_BEAM_SUCCESS, MATCHING_BEAM_TIMEOUT_ERROR,
} from "../statusConstants";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import RackViewer from "../../../pages/StatusMainPage/ScanningBeam/RackViewer";
import StatusInfo from "../GeneralComponents/StatusInfro/StatusInfo";


const MatchingBeamComponent = ({screenVersion = false, isMobile}) => {
  const {matchingBeamStatus} = useSelector(state => state.statusReducer)

  const statusRender = (beam_status) => {
    switch (beam_status) {
      case IDLE:
      case MATCHING_BEAM_SUCCESS:
        return <StatusInfo status={beam_status} isMobile={isMobile}/>
      case MATCHING_BEAM_IS_RUNNING:
        return !screenVersion ? <ProcessAction status={beam_status}/> : <ProcessingActionScreen status={beam_status}/>
      case MATCHING_BEAM_ERROR:
      case MATCHING_BEAM_TIMEOUT_ERROR:
        return <StatusScanningError status={beam_status} screenVersion={screenVersion} isMobile={isMobile}/>
      default:
        return <UnknownStatus isMobile={isMobile}/>
    }
  }

  return statusRender(matchingBeamStatus)
};

export default MatchingBeamComponent;
