import {useDispatch, useSelector} from "react-redux";

import useStyles from "./Viewer3DLoaderStyle";

import React from "react";
import Viewer3D from "../../Viewer3D/Viewer3D";

function Viewer3DLoader({selectedModel, missedPartsList, beamTransform, page, skip_parts}) {
    const {viewerIsLoading, viewerFirstTimeRendered} = useSelector((state) => state.layoutReducer)
    const dispatch = useDispatch()


    const classes = useStyles({viewerIsLoading, viewerFirstTimeRendered})


    return <div className={classes.viewerHidden} style={{height: '100%'}}>
       <Viewer3D page={page} skip_parts={skip_parts} selectedModel={selectedModel} dispatch={dispatch} missedPartsList={missedPartsList} beamTransform={beamTransform}/>
    </div>


}


export default Viewer3DLoader
