import {streamAxios} from "../../../settingsFiles/axiosSettings";


export const getStreamListRequestCall = () => {
    return streamAxios.get('/list')
}

export const submitStreamRequestCall = () => {
    return streamAxios.post('/start', {
        uri: process.env.REACT_APP_RTSP_URI,
        alias: "camera1"
    })
}