import React from 'react';
import {Divider, Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import ModelSettings from "../../components/settings/ModelSettings/ModelSettings";
import Paper from "@mui/material/Paper";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import PropTypes from "prop-types";
import GeneralSettings from "../../components/settings/GeneralSettings/GeneralSettings";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: '90%' }}
            {...other}
        >
            {value === index && (
                <Box sx={{ height: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function SettingsPage({isMobile}) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const {t} = useTranslation()
    const divider = <Divider color={'#000000'} style={{height: '2px'}}/>

    return <Paper style={{backgroundColor: '#141414', borderRadius: '10px', position: 'relative', height: '100%'}}>
        <Box sx={{ borderBottom: 1, borderColor: 'white', height: '50px'}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:"#141414"}}>
                <Tab label={t('GeneralSettings')} {...a11yProps(0)} style={value === 0 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
                <Tab label={t('settingsModalHeader')} {...a11yProps(1)} style={value === 1 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
            </Tabs>
        </Box>
        {divider}
        <TabPanel value={value} index={0}>
            <GeneralSettings/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <ErrorBoundary message={t('unexpected_error')}>
                <ModelSettings isMobile={isMobile}/>
            </ErrorBoundary>
        </TabPanel>
    </Paper>

}

export default SettingsPage;

