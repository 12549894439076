import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    setActiveTable,
    setTableLength,
    setTableNumber,
    setTableOk
} from "../../../redux/reducers/statusReducer/actions";

const TableInput = () => {
    const dispatch = useDispatch()
    const {table_checks, tableNumber} = useSelector((state) => state.statusReducer)

    const setTable = (number) => {
        dispatch(setActiveTable(number, !table_checks[number]))
    }

    const calculateNumberOfTables = (activeTables) => {
        let counter = 0;
        Object.values(activeTables).forEach((item) => {
            if (item) counter++;
        })
        return counter
    }

    const checkPattern = (activeTables, tableNumber) => {
        let binaryRepresentation = ''
        Object.values(activeTables).forEach((item) => {
            if (item) {
                binaryRepresentation += '1'
            } else {
                binaryRepresentation += '0'
            }
        })
        return binaryRepresentation.search('1'.repeat(tableNumber)) !== -1
    }


    useEffect(() => {
        const tableNumber = calculateNumberOfTables(table_checks)
        dispatch(setTableNumber(tableNumber))
        dispatch(setTableOk(checkPattern(table_checks, tableNumber)))
        dispatch(setTableLength(tableNumber * 2000))

    }, [table_checks])

    return (
        <div>
            <div style={{display: 'flex'}}>
                {[1, 2, 3, 4, 5, 6].map((item) => {
                    return <div
                        onClick={() => {
                            setTable(item)
                        }}
                        key={item}
                        style={{
                            backgroundColor: !table_checks[item] ? '#212121' : '#54fcef',
                            flexBasis: '12%', height: '3.5vh',
                            border: '0.1px solid #141414',
                            cursor: 'pointer'
                        }}/>
                })}

            </div>

            <Typography color={'white'}
                        style={{fontSize: '1.5vh', marginTop: '10px'}}>Total {tableNumber} of
                6 tables</Typography>


        </div>
    );
};

export default TableInput;