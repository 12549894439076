import React from 'react';
import {Button, CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const ServiceButton = ({
                           buttonName = '',
                           color = 'success',
                           variant = 'outlined',
                           loadingCondition = false,
                           height = 40,
                           width = 100,
                           style,
                           ...props
                       }) => {

    const {t} = useTranslation();
    const buttonBody = !loadingCondition ? <Typography style={{fontSize: 14}}>   {t(buttonName)} </Typography> :
        <CircularProgress color={variant === 'contained' ? 'primary' : color} size={height - 10}/>

    return (
        <Button {...props} color={color} variant={variant} style={{height, width,
            pointerEvents: loadingCondition ? 'none': 'auto',
            ...style}}>
            {buttonBody}
        </Button>
    );
};

export default ServiceButton;