import React, {useContext} from 'react';
import {Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    EXECUTOR_ERROR,
    EXECUTOR_IS_RUNNING,
    EXECUTOR_ROBOT_ERROR,
    IDLE_STATUSES,
    LABELING_ERROR,
    LABELING_IS_RUNNING,
    LABELING_ROBOT_ERROR,
    PLANNER_ERROR,
    PLANNER_IS_RUNNING,
    SCANNING_BEAM_ERROR,
    SCANNING_BEAM_IS_RUNNING,
    SCANNING_BEAM_ROBOT_ERROR,
    MATCHING_LESS_PARTS_ERROR,
    SCANNING_PARTS_ERROR,
    SCANNING_PARTS_IS_RUNNING,
    SCANNING_PARTS_ROBOT_ERROR,
    MATCHING_UNMATCHED_PARTS_ERROR,
    MATCHING_PARTS_ERROR,
    MATCHING_BEAM_ERROR,
    SCANNING_BEAM_TIMEOUT_ERROR,
    MATCHING_BEAM_TIMEOUT_ERROR,
    MATCHING_PARTS_TIMEOUT_ERROR,
    SCANNING_PARTS_TIMEOUT_ERROR,
    LABELING_TIMEOUT_ERROR,
    PLANNER_TIMEOUT_ERROR,
    EXECUTOR_TIMEOUT_ERROR,
    INSPECTION_BEAM_ERROR,
    INSPECTION_BEAM_TIMEOUT_ERROR,
    INSPECTION_PARTS_ERROR, INSPECTION_PARTS_TIMEOUT_ERROR,
} from "../../statusConstants";
import {useDispatch, useSelector} from "react-redux";
import {
    cancelProcessingTaskRequest,
    setExecutorStatus,
    setLabelingStatus,
    setPlannerStatus,
    setScanningBeamStatus,
    setScanningPartsStatus,
    setStatuses
} from "../../../../redux/reducers/statusReducer/actions";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import AssembledCounter from "../../ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import PlannedCounter from "../../ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";
import check_role from "../../../../utils/utils";
import {saveAction} from "../../../../redux/reducers/userReducer/actions";


function StatusScanningError({status, screenVersion, isMobile}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {
        state: {ws},
    } = useContext(SocketContext);
    const {taskId} = useSelector((state) => state.statusReducer)
    const task_id = taskId
    const {username} = useSelector((state) => state.cachedReducer)

    const returnSocketEmitAndStatusName = (taskStatus) => {
        switch (taskStatus) {
            case SCANNING_BEAM_ERROR:
            case SCANNING_BEAM_TIMEOUT_ERROR:
            case SCANNING_BEAM_ROBOT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setScanningBeamStatus(SCANNING_BEAM_IS_RUNNING)),
                    emitName: 'start_scan_beam',
                    emitPayload: task_id
                }
            case MATCHING_BEAM_ERROR:
            case MATCHING_BEAM_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> {},
                    emitName: 'start_match_beam',
                    emitPayload: task_id
                }
            case INSPECTION_BEAM_ERROR:
            case INSPECTION_BEAM_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> {},
                    emitName: 'start_inspection_beam',
                    emitPayload: task_id
                }
            case MATCHING_LESS_PARTS_ERROR:
            case MATCHING_UNMATCHED_PARTS_ERROR:
            case MATCHING_PARTS_ERROR:
            case MATCHING_PARTS_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> {},
                    emitName: 'start_match_parts',
                    emitPayload: task_id
                }
            case INSPECTION_PARTS_ERROR:
            case INSPECTION_PARTS_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> {},
                    emitName: 'start_inspection_parts',
                    emitPayload: task_id
                }
            case SCANNING_PARTS_ERROR:
            case SCANNING_PARTS_ROBOT_ERROR:
            case SCANNING_PARTS_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setScanningPartsStatus(SCANNING_PARTS_IS_RUNNING)),
                    emitName: 'start_scan_parts',
                    emitPayload: taskId
                }
            case LABELING_ERROR:
            case LABELING_ROBOT_ERROR:
            case LABELING_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setLabelingStatus(LABELING_IS_RUNNING)),
                    emitName: 'start_label_task',
                    emitPayload: taskId
                }
            case PLANNER_ERROR:
            case PLANNER_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setPlannerStatus(PLANNER_IS_RUNNING)),
                    emitName: 'start_planner_task',
                    emitPayload: taskId
                }
            case EXECUTOR_ERROR:
            case EXECUTOR_ROBOT_ERROR:
            case EXECUTOR_TIMEOUT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setExecutorStatus(EXECUTOR_IS_RUNNING)),
                    emitName: 'start_executor_task',
                    emitPayload: taskId
                }
            default:
                return {changeStatus: null, emitName: '', emitPayload: {}}
        }
    }

    const startRescan = () => {
        const {changeStatus, emitName, emitPayload} = returnSocketEmitAndStatusName(status)
        changeStatus?.()
        dispatch(saveAction(taskId, "RETRY(Status, "+emitName+")"))
        ws.emit(emitName, emitPayload)
    }
    const stopAssembling = () => {
        dispatch(setStatuses(IDLE_STATUSES))
        dispatch(cancelProcessingTaskRequest(taskId))
    }

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width:"100%", padding: '0px 10px'}}>
              <Typography variant={'h4'} color={'white'} style={{textAlign: "center", paddingBottom: "20px"}}>
                {t(status)}
                {(status === EXECUTOR_ROBOT_ERROR || status === EXECUTOR_ERROR) && <AssembledCounter/>}
                {status === PLANNER_ERROR && <PlannedCounter/>}
              </Typography>
                {check_role(username) &&
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                    <Button color={'error'} variant={'contained'}
                            style={{flexBasis: '151px'}} onClick={() => {
                        stopAssembling()
                    }}> {t('StopAssembling')} </Button>
                      <Button color={'success'} variant={'contained'}
                           style={{flexBasis: '151px', marginLeft: '20px'}}
                           onClick={() => {
                               startRescan()
                           }}> {t('MakeReScan')}</Button>
                      {((status === MATCHING_BEAM_ERROR || status === MATCHING_BEAM_TIMEOUT_ERROR) ||
                        (status === INSPECTION_BEAM_ERROR || status === INSPECTION_BEAM_TIMEOUT_ERROR)
                       ) &&
                        <Button color={'success'} variant={'contained'}
                                style={{flexBasis: '151px', marginLeft: '20px'}}
                                onClick={() => {
                                    dispatch(saveAction(taskId, t('MakeReScanBeam').toUpperCase()+'(Status)'))
                                    ws.emit('start_scan_beam', task_id)
                                }}> {t('MakeReScanBeam')}</Button>
                      }
                      {(status === INSPECTION_BEAM_ERROR || status === INSPECTION_BEAM_TIMEOUT_ERROR) &&
                       <Button color={'success'} variant={'contained'}
                               style={{flexBasis: '151px', marginLeft: '20px'}}
                               onClick={() => {
                                   dispatch(saveAction(taskId, t('MakeReMatchBeam').toUpperCase()+'(Status)'))
                                   ws.emit('start_match_beam', task_id)
                               }}> {t('MakeReMatchBeam')}</Button>
                      }
                      {((status === MATCHING_LESS_PARTS_ERROR || status === MATCHING_PARTS_TIMEOUT_ERROR ||
                          status === MATCHING_UNMATCHED_PARTS_ERROR || status === MATCHING_PARTS_ERROR) ||
                        (status === INSPECTION_PARTS_ERROR || status === INSPECTION_PARTS_TIMEOUT_ERROR)
                       ) &&
                        <Button color={'success'} variant={'contained'}
                                style={{flexBasis: '151px', marginLeft: '20px'}}
                                onClick={() => {
                                    dispatch(saveAction(taskId, t('MakeReScanParts').toUpperCase()+'(Status)'))
                                    ws.emit('start_scan_parts', task_id)
                                }}> {t('MakeReScanParts')}</Button>
                      }
                      {(status === INSPECTION_PARTS_ERROR || status === INSPECTION_PARTS_TIMEOUT_ERROR) &&
                       <Button color={'success'} variant={'contained'}
                               style={{flexBasis: '151px', marginLeft: '20px'}}
                               onClick={() => {
                                   dispatch(saveAction(taskId, t('MakeReMatchParts').toUpperCase()+'(Status)'))
                                   ws.emit('start_match_parts', task_id)
                               }}> {t('MakeReMatchParts')}</Button>
                      }
                  </div>
                }
            </div>
        </div>
    );
}

export default StatusScanningError;
