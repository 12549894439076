import {authAxios} from "../../../settingsFiles/axiosSettings";

export const getArchiveTasksListRequest = (filter) => {
    return authAxios.post("tasks/archive", {...filter})
}
export const getGraphDataApiRequest = (dates, operator) => {
    return authAxios.post("graph/assembled_count", {...dates, operator})
}
export const getGraphExcelApiRequest = (dates, operator) => {
    return authAxios.post("graph/assembled_count/export_excel", {...dates, operator}, {responseType: 'blob'})
}
