import React from 'react';
import ProcessAction from "../../GeneralComponents/ProcessAction/ProcessAction";
import StatusScanningError from "../../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../../GeneralComponents/UnknownStatus/UnknownStatus";
import {
    EXECUTOR_ERROR,
    EXECUTOR_IS_RUNNING, EXECUTOR_ROBOT_ERROR,
    EXECUTOR_SUCCESS, EXECUTOR_TIMEOUT_ERROR, IDLE,

} from "../../statusConstants";
import StatusInfo from "../../GeneralComponents/StatusInfro/StatusInfo";
import ProcessingActionScreen from "../../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";


const ExecutorComponent = ({screenVersion, isMobile, executorStatus}) => {

    const statusRender = (status) => {
        switch (status) {
            case IDLE:
                return <StatusInfo status={status} isMobile={isMobile}/>
            case EXECUTOR_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={status} isMobile={isMobile}/> :
                    <ProcessingActionScreen status={status} isMobile={isMobile}/>
            case EXECUTOR_ERROR:
            case EXECUTOR_TIMEOUT_ERROR:
            case EXECUTOR_ROBOT_ERROR:
                return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
            case EXECUTOR_SUCCESS:
                return <StatusInfo status={status} isMobile={isMobile}/>
            default:
                return <UnknownStatus executor={true} isMobile={isMobile}/>
        }
    }
    return statusRender(executorStatus)
};

export default ExecutorComponent;
