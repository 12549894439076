import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setChosenNavTab} from "../../../redux/reducers/layoutReducer/actions";

function NotFoundPageHistoryPush() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/notfoundpage");
    }, []);
    return null;
}


function Routes404({children}) {

    let location = useLocation();
    const dispatch = useDispatch()
    useEffect(() => {
        if(location) {
            dispatch(setChosenNavTab(location.pathname))
        }
    }, [location])

    return (
        <Routes>
            {children}
            <Route path={'*'} element={<NotFoundPageHistoryPush/>}/>
        </Routes>


    );
}

export default Routes404;
