import {createTheme} from "@mui/material/styles";


export const mobileTheme = createTheme({
  palette: {
    primary: {
      main: '#141414',
      light: '#3a3a3a',
      dark: '#000000'
    },
    secondary: {
      main: '#54fcef',
      light: '#92ffff',
      dark: '#00c8bd'

    },
    action: {
      disabledBackground: '#425e57',
      disabled: 'black'
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'serif',
      textTransform: 'none',
      fontSize: 12
    },
    fontFamily: [
      '"Fira sans"',
      "sans-serif",
    ].join(","),
  },

})
