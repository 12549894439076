import React, {Suspense} from 'react';
import {useSelector} from "react-redux";
import {Canvas} from "@react-three/fiber";
import CameraControls from "../CameraControls/CameraControls";
import Loader from "./Loader";


const SinglePartViewer = ({model, id}) => {
	const {viewerSettings} = useSelector((state) => state.cachedReducer)

	return   <Canvas shadows style={{width: 'calc(100% - 2px)'}}>
		<Suspense fallback={null}>
			{model &&
			 <Loader viewerSettings={viewerSettings} model={model} id={id}/>
			}
		</Suspense>
		<CameraControls/>
		<ambientLight intensity={.7}/>
		<directionalLight
			intensity={1}
			castShadow
			shadow-mapSize-height={512}
			shadow-mapSize-width={512}
		/>
	</Canvas>
}

export default SinglePartViewer;
