import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {authAxios} from "../../settingsFiles/axiosSettings";
import compareKeys from "../../utils/helperFunctions/compareKeys";
import {setInitialViewerSettings} from "../../redux/reducers/cachedReducer/actions";

function LocalStorageHandler() {

    const {accessToken, viewerSettings} = useSelector((state) => state.cachedReducer)
    const dispatch = useDispatch()


    useEffect(() => {
        authAxios.defaults.headers['Authorization'] = `Bearer ${accessToken}`
    }, [accessToken])

    useEffect(()=>{
        if(!(viewerSettings && compareKeys(viewerSettings))){
            dispatch(setInitialViewerSettings())
        }
    }, [])


    return null
}

export default LocalStorageHandler;
