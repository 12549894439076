import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IDLE} from "../../components/StatusesComponets/statusConstants";
import ServiceButton from "../../components/ServiceButton/ServiceButton";
import {
  continueProcessingTaskRequest,
} from "../../redux/reducers/readyTasksReducer/actions";
import ContinueTaskModal from "./ContinueTaskModal";


function ContinueTask({isMobile}) {
  const dispatch = useDispatch()
  const {readyTasksList, selectedTask, selectedTaskName, submittingProcessingTask} = useSelector((state) => state.readyTasksReducer)
  const {taskStatus} = useSelector(state => state.statusReducer)
  const task = readyTasksList.find(item => item.name === selectedTaskName)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {taskStatus === IDLE && selectedTask && task && task.postponed ? <>
        {task.postponed.length > 1 ?
          <>
            <ServiceButton
              variant={'contained'}
              style={isMobile ? {position: 'absolute', marginRight: '10px',
                right: 0, bottom: '40%', padding: '3px 10px'} : {marginLeft: '20px'}}
              color={'secondary'}
              buttonName={'ContinueTask'}
              width={isMobile ? 'initial' : 131}
              height={isMobile ? 'initial' : 40}
              loadingCondition={submittingProcessingTask}
              onClick={() => {
                handleOpen()
              }}
            />
            <ContinueTaskModal open={open} handleClose={handleClose} tasks={task.postponed}
                               model_name={selectedTask.file.name} submittingProcessingTask={submittingProcessingTask}/>
          </>
          :
          <ServiceButton
            variant={'contained'}
            style={isMobile ? {position: 'absolute', marginRight: '10px',
              right: 0, bottom: '40%', padding: '3px 10px'} : {marginLeft: '20px'}}
            color={'secondary'}
            buttonName={'ContinueTask'}
            width={isMobile ? 'initial' : 131}
            height={isMobile ? 'initial' : 40}
            loadingCondition={submittingProcessingTask}
            onClick={() => {
              dispatch(continueProcessingTaskRequest(task.postponed[0], selectedTask.file.name))
            }}
          />
        }
      </> : null}
    </>
  );
}

export default ContinueTask;
