import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {extend, useFrame, useThree} from "@react-three/fiber";
import {useRef} from "react";

extend({OrbitControls});


const CameraControls = () => {
    const {
        camera,
        gl: {domElement}
    } = useThree();

    const controls = useRef();
    useFrame(state => {
        controls.current.update()
    });
    
    return (
        <orbitControls
            ref={controls}
            args={[camera, domElement]}
            enableZoom={true}
            maxPolarAngle={Math.PI}
            minPolarAngle={0}
        />
    );
};

export default CameraControls
