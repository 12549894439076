import React from 'react';
import {Link} from "react-router-dom";

function CustomLink({to, children, ...props}) {

    return (
        <Link to={to}
              style={{textDecoration: 'none'}}
              {...props} >

            {children}
        </Link>
    );
}

export default CustomLink;