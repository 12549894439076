import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deleteModelRequest, getSimulationTaskRequest} from "../../redux/reducers/modelsReducer/actions";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import {CircularProgress, FormControl, Grid, MenuItem, Pagination, Select} from "@mui/material";
import ModelsTable from "../../components/Tables/ModelsTable/ModelsTable";
import ModelsButtons from "../../components/ButtonsGroup/ModelsButton/ModelsButtons";
import useTableFilter from "../../utils/useTableFilter";
import {useTranslation} from "react-i18next";
import {StyledModelsPage} from "./StyledModelsPage";
import ModelInfoModal from "./ModelInfoModal/ModelInfoModal";
import ConfirmModal from "../../utils/ConfirmModal/ConfirmModal";

function ModelsPage({isMobile}) {
    const {selectedModel, missedPartsList, total_pages, singleModelIsLoading, modelInstance} = useSelector((state) => state.modelsReducer)
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const loadData = (filter) => dispatch(getSimulationTaskRequest(filter));
    const {filter, changeFilter, toPage, changePageSize} = useTableFilter(loadData, {
        sortField: 'sort_date',
        sortOrder: 'desc',
        pageNumber: 1,
        pageSize: 20
    });
    const [open, setOpen] = useState(false);
    const [confOpen, setConfOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
      <StyledModelsPage>
        <Grid container style={{width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '40%' : 'initial'}}>
                <ModelsTable isMobile={isMobile} filter={filter} changeFilter={changeFilter}/>
                <div style={{position: 'relative'}}>
                    <Pagination count={total_pages} color="primary" style={{padding: '5px 10px', backgroundColor: 'white'}}
                                onChange={(e , page) => {toPage(page)}}/>
                    <FormControl sx={{minWidth: 220, position: 'absolute', right: '5%', bottom: 0, height: '40px', display: 'block'}} size="small">
                        <div style={{display: 'inline-block', width: '130px'}}>{t('Quantity_rows')}:</div>
                        <Select
                          value={filter.pageSize}
                          style={{height: '37px', width: '90px'}}
                          onChange={(e) => {changePageSize(e.target.value)}}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '50%' : 'initial'}}>
                {selectedModel ? <div style={{backgroundColor: '#141414', height: '100%'}}>
                                   <div style={{height: '80%', border: 'solid 1px white'}}>
                                       <Viewer3DContainer selectedModel={selectedModel} missedPartsList={missedPartsList}
                                                          page={"models"} height="100%"
                                                          isMobile={isMobile}/>
                                   </div>
                                   <div style={{height: '20%', display: 'flex', alignItems: 'center',
                                       justifyContent: 'center', border: 'solid 1px white'}}>
                                       <ModelsButtons isMobile={isMobile} filter={filter} handleOpen={handleOpen}
                                       openConf={() => {setConfOpen(true)}}/>
                                   </div>
                               </div>
                               :
                 <div style={{height: '100%', backgroundColor: '#141414'}}>
                     <div style={{height: '80%', color: 'white', position: 'relative', border: 'solid 1px white'}}>
                         <div style={{position: 'absolute',
                                 top: '50%',
                                 left: '50%',
                             transform: 'translate(-50%, -50%)'}}>{singleModelIsLoading ?
                             <CircularProgress size={40} style={{color: 'white'}}/> : t('Choose_model')}</div>
                     </div>
                     <div style={{height: '20%', border: 'solid 1px white', position: 'relative', color: 'white'}}>
                         <div style={{
                             position: 'absolute',
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)'
                         }}>{singleModelIsLoading ?
                             <CircularProgress size={40} style={{color: 'white'}}/> : t('Choose_model')}</div>
                     </div>
                 </div>
                }
            </Grid>
            <ConfirmModal open={confOpen} handleClose={() => {setConfOpen(false)}} action={
                () => {dispatch(deleteModelRequest(modelInstance.name))}} text={'Are you sure you want to delete the model?'}/>
            <ModelInfoModal open={open} handleClose={handleClose}/>
        </Grid>
      </StyledModelsPage>
    );
}

export default ModelsPage;
