export const downloaderModule = 'downloaderModule';
export const SET_LOADING_PARTS = `${downloaderModule}/SET_LOADING_PARTS`;
export const SET_LOADING_BEAM = `${downloaderModule}/SET_LOADING_BEAM`;
export const SET_SHOW_MODAL = `${downloaderModule}/SET_SHOW_MODAL`;
export const SET_PARTS_FILES = `${downloaderModule}/SET_PARTS_FILES`;
export const SET_BEAM_FILES =  `${downloaderModule}/SET_BEAM_FILES`;
export const CLEAR_STATE = `${downloaderModule}/CLEAR_STATE`;
export const SET_PARTS_FILE_PERCENTAGE = `${downloaderModule}/SET_PARTS_FILE_PERCENTAGE`
export const SET_BEAM_FILE_PERCENTAGE = `${downloaderModule}/SET_BEAM_FILE_PERCENTAGE`
export const LOAD_FILES_PARTS = `${downloaderModule}/LOAD_FILES_PARTS`;
export const LOAD_FILES_BEAM = `${downloaderModule}/LOAD_FILES_BEAM`;

const initialState = {
	loading_parts: false,
	loading_beam: false,
	parts_files: [],
	beam_files: [],
	show_modal: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING_PARTS:
			return {...state, loading_parts: action.loading}
		case SET_LOADING_BEAM:
			return {...state, loading_beam: action.loading}
		case SET_SHOW_MODAL:
			return {...state, show_modal: action.payload}
		case SET_PARTS_FILES:
			return {...state, parts_files: action.data}
		case SET_BEAM_FILES:
			return {...state, beam_files: action.data}
		case CLEAR_STATE:
			return initialState
		case SET_PARTS_FILE_PERCENTAGE:
			return {...state,
				parts_files: state.parts_files.map(
					(file, i) => {
						return i === action.index ? {...file, percentage: action.percentCompleted} : file
					}
				)
			}
		case SET_BEAM_FILE_PERCENTAGE:
			return {...state,
				beam_files: state.beam_files.map(
					(file, i) => {
						return i === action.index ? {...file, percentage: action.percentCompleted} : file
					}
				)
			}
		default:
			return state;
	}
};

export default reducer
