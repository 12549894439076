import styled from 'styled-components';

export const StyledExecutorComponent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 4px;
  text-align: center;
  .success_btn {
    background-color: rgba(0, 255, 0, 0.65);
    width: 220px;
    height: 35px;
    margin: 20px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .success_btn:hover{
    background-color: rgba(0,255,0,1);
  }
  .unsuccess_btn {
    background-color: rgba(255, 0, 0, 0.65);
    width: 220px;
    height: 35px;
    margin: 20px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .unsuccess_btn:hover{
    background-color: rgba(255,0,0,1);
  }
`;
