import React, {memo, Suspense, useMemo} from 'react';
import {Canvas} from "@react-three/fiber";
import CameraControls from "../CameraControls/CameraControls";
import {useSelector} from "react-redux";
import {mainGeometryParser} from "../../../utils/three/loader";
import SingleModelPart from "../Viewer3DContainer/Viewer3DLoader/SingleModelDynamic/SignleModelPart";
import {PerspectiveCamera, Text} from "@react-three/drei";
import {AxesHelper} from "three";


const ModalViewer = () => {
    const {viewerSettings} = useSelector((state) => state.cachedReducer)
    const {selectedModel, beamTransform} = useSelector(state => state.statusReducer)
    const deg2rad = degrees => degrees * (Math.PI / 180);

    return <Canvas shadows style={{borderRadius: '13px'}}>
        <Suspense fallback={null}>
            <PerspectiveCamera rotation={[deg2rad(-60), deg2rad(0), deg2rad(-120)]}>
                {selectedModel ?
                    <SingleModelModal viewerSettings={viewerSettings} selectedModel={selectedModel}
                                      beamTransform={beamTransform}/> : null}
            </PerspectiveCamera>
        </Suspense>
        <CameraControls/>
        <fog attach="fog" args={["white", 0, 40]}/>
        <ambientLight intensity={0.7}/>
        <directionalLight
            intensity={1}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
        />
    </Canvas>
};

export default ModalViewer;


function _SingleModel({viewerSettings, selectedModel, beamTransform}) {

    const geomList = useMemo(() => mainGeometryParser({selectedModel, onlyBeam: true, beamTransform}), [selectedModel, beamTransform])

    return (
        <group>
            {geomList.map((item, index) => <SingleModelPart key={index} geometry={item}
                                                            viewerSettings={viewerSettings}/>)}

            <meshStandardMaterial attach="material" color="white"/>
            <mesh position={[0, 0, -0.5]}>
                <boxGeometry args={[1, 0.3, 1]}/>
                <meshStandardMaterial color={'gray'}/>
            </mesh>
            <Text color="red" anchorX="center" anchorY="middle" fontSize={0.5} position={[0.501, 0, -0.5]}
                  rotation={[0, Math.PI / 2, Math.PI / 2]}>
                1
            </Text>
            <primitive object={new AxesHelper(10)}/>

        </group>

    );
}

const SingleModelModal = memo(_SingleModel, (prevProps, nextProps) =>
    prevProps.selectedModel === nextProps.selectedModel
);


