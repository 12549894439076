import React from 'react';
import {useSelector} from "react-redux";
import {Navigate} from 'react-router-dom'


function RequireAuth({children}) {
    const {isAuthenticated} = useSelector(state => state.cachedReducer);
    return isAuthenticated ? children : <Navigate to="/login" replace/>;
}

export default RequireAuth;