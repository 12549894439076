import React, {memo, useEffect, useRef} from 'react';
import SingleModelPartStatic from "./SingleModelPartStatic";
import {PerspectiveCamera} from "@react-three/drei";


const Model = memo(function Model({geomList, setViewerIsLoading}) {

    const groupRef = useRef()

    useEffect(() => {
        if (groupRef.current) {
            setViewerIsLoading?.(false)
        }
    }, [groupRef])

    return <group ref={groupRef} >
        {geomList.map((item, index) => {
            return <SingleModelPartStatic key={index} geometry={item}/>
        })}
    </group>
})

function SingleModelStatic({geomList, side, view, setViewerIsLoading, attributes}) {
    const {offset, sideZoom,frontZoom} = attributes;
    const deg2rad = degrees => degrees * (Math.PI / 180);
    const views = {
        top: {
            position: [side !== 'left' ? -offset+0.1 : offset-0.1, 0, sideZoom],
            rotation: [deg2rad(0), deg2rad(0), deg2rad(-90)],
        },
        front: {
            position: [0, 0, frontZoom],
            rotation: [deg2rad(-90), 0, deg2rad(180)],
        },
        side: {
            position: [side !== 'left' ? -offset+0.1 : offset-0.1, 0, sideZoom],
            rotation: [deg2rad(-90), 0, deg2rad(-90)],
        },
    }


    return (
        <>
            <PerspectiveCamera position={views[view].position} rotation={views[view].rotation}>
                <Model geomList={geomList} side={side}
                       setViewerIsLoading={setViewerIsLoading}/>
            </PerspectiveCamera>
        </>
    );
}


export default SingleModelStatic
