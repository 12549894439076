import styled from 'styled-components';

export const DownloadProgressStyle = styled.div`
  background-color: #eee;
  border-radius: 8px;
  height: 300px;
  z-index: 9999;
  width: 300px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
  margin: 10px;
  overflow: scroll;
  .list_item{
    display: table;
  }
  .title{
    display: table-cell;
    vertical-align: middle;
  }
  .progress_bar{
    padding-left: 25px;
    display: table-cell;
    text-align: center;
  }
  .MuiCircularProgress-root{
    color: #1976d2;
  }
  .close_icon{
    position: absolute;
    right: 0;
  }
  .MuiIconButton-root:hover {
    color: red;
  }
`
