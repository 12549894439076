import styled from 'styled-components';

export const StyledModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%);
    width: 400px;
    height: 200px;
    border: 2px solid #000;
    box-shadow: 0 5px 15px rgba(0,0,0,0);
    padding: 2px;
		background-color: white;
		.text{
				height: 70%;
        display: flex;
        justify-content: center;
				font-weight: bold;
        align-items: center;
				font-size: 18px;
        text-align: center;
		}
		.action{
				height: 30%;
				text-align: center;
		}
`;
