import React from 'react'
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DownloadProgressStyle} from "./DownloadProgressStyle";
import {CircularProgressWithLabel} from "../../utils/ProgressBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {set_show_modal} from "../../redux/reducers/downloaderReducer/actions";

export default function DownloadProgress() {
    const {t} = useTranslation();
    const {parts_files, beam_files} = useSelector((state) => state.downloaderReducer)
    const dispatch = useDispatch()


    return (
        <DownloadProgressStyle>
            <IconButton onClick={() => dispatch(set_show_modal(false))} className={'close_icon'}>
                <CloseIcon />
            </IconButton>
            <Typography color={'black'} style={{textAlign: 'center'}}> {t('DownloadParts')}</Typography>
            {parts_files && parts_files.map((item, index) => {
                return (
                    <div className={'list_item'} key={index}>
                        <div className={'title'}>{item.name}</div>
                        <div className={'progress_bar'}>
                            <CircularProgressWithLabel value={item.percentage} color="secondary"/>
                        </div>
                    </div>
                )
            })}
            {beam_files && beam_files.map((item, index) => {
                return (
                    <div className={'list_item'} key={index}>
                        <div className={'title'}>{item.name}</div>
                        <div className={'progress_bar'}>
                            <CircularProgressWithLabel value={item.percentage} color="secondary"/>
                        </div>
                    </div>
                )
            })}
        </DownloadProgressStyle>
    )
}
