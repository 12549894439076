import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {startStream} from "../../redux/reducers/layoutReducer/actions";
import PlayerComponent from "../../components/PlayerComponent/PlayerComponent";


const StreamPage = () => {


    const {streamUri} = useSelector((state) => state.layoutReducer)
    const dispatch = useDispatch()



    useEffect(()=>{
        dispatch(startStream())
    },[dispatch])

    return (
        <div style={{width: '100%', height: '84vh'}}>
             <PlayerComponent streamUri={streamUri}/>
        </div>
    );
};

export default StreamPage;
