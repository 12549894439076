import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, TableHead, Typography} from "@mui/material";

import useStyles from "../../components/Tables/GeneralTable/GeneralTableStyle";
import {useTranslation} from "react-i18next";
import {StyledGeneralTable} from "../../components/Tables/GeneralTable/StyledGeneralTable";
import {useRef} from "react";
import {assemblyPlanPriorityReq, getPlannedTasksListSuccess} from "../../redux/reducers/readyTasksReducer/actions";
import {useDispatch, useSelector} from "react-redux";


export default function GeneralTable({   isMobile,
                                       headers = [],
                                       rows = [],
                                       onRowClick = null,
                                       activeItem = null,
                                       tableIsLoading = false
                                     }) {

  const classes = useStyles()
  const {t} = useTranslation();
  const {loadingAssemblyPlan, plannedTasksList} = useSelector((state => state.readyTasksReducer))
  const dispatch = useDispatch()
  const dragItem = useRef()
  const dragOverItem = useRef()
  const dragStart = (e) => {
    dragItem.current = e.target.id
  }
  const dragEnter = (e) => {
    dragOverItem.current = e.currentTarget.id
  }
  const drop = () => {
    if(loadingAssemblyPlan){
      return
    }
    const copyPlanList = plannedTasksList.reduce((acc, val) => {
      acc.push(val);
      return acc;
    }, []);
    const dragItemContent = copyPlanList[dragItem.current]
    copyPlanList.splice(dragItem.current, 1)
    copyPlanList.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = null
    dragOverItem.current = null
    let ind = 1
    let new_prios = []
    // eslint-disable-next-line array-callback-return
    copyPlanList.map((item) => {
      new_prios.push({'priority':ind, 'name': item.name})
      ind += 1
    })
    dispatch(assemblyPlanPriorityReq(new_prios))
    dispatch(getPlannedTasksListSuccess(copyPlanList))
  }

  return (
    <StyledGeneralTable>
      <TableContainer component={Paper}
                      style={{height: isMobile ? '100%' : '81vh', borderRadius: '5px', backgroundColor: '#141414'}}>
        <Table className={classes.mainTable} stickyHeader>
          <TableHead style={{height: '72px', borderRadius: '5px', backgroundColor: '#141414'}}>
            <TableRow style={{backgroundColor: '#1F1F1F !important', borderBottom: '2px solid #141414'}}>
              {headers.map((item, index) => {
                return <TableCell key={item} className={'header_cells'}
                                  style={ { backgroundColor: '#1F1F1F', borderBottom:'none',
                                    width: index === 0 ? '97px' : index === 1 ? '220px' : index === 5 ? '55px' : 'initial',
                                  }}>
                  <Typography color={'white'} fontSize={isMobile ? 12 : 15}>
                    <span dangerouslySetInnerHTML={{__html: t(item)}}/>
                  </Typography>
                </TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableIsLoading ? <TableRow style={{height: '70vh'}}>
                              <TableCell colSpan={6} style={{border: 'none'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                  <CircularProgress color={'secondary'} size={50}/></div>
                              </TableCell>
                            </TableRow> :


              rows.map((row, index) => (
               <TableRow key={index} draggable
                         onDragStart={(e) => dragStart(e)}
                         onDragEnter={(e) => dragEnter(e)}
                         onDragEnd={drop}
                         id={index}
                         className={index === activeItem ? classes.tableBodyRowSelected : classes.tableBodyRow}>
                 {Object.values(row).map((item, ind) => {
                   if(ind === 6){return null}
                   return <TableCell key={ind} align="left" style={{
                     borderBottom: index !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                     borderTop: index !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                     backgroundColor: index !== activeItem ? "initial" : "#303030",
                     color: '#EFF3F9',
                     fontSize: isMobile ? '12px' : '15px',
                     width: ind === 1 ? '275px' : 'initial',
                     wordBreak: 'break-all'
                   }}
                                     onClick={onRowClick ? () => {
                                       onRowClick(row.name, index, row.count, ind === 5)
                                     } : null}
                   >
                     {item}
                   </TableCell>
                 })}
               </TableRow>
             ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGeneralTable>
  );
}
