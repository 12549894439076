import {authAxios} from "../../../settingsFiles/axiosSettings";


export const getSimulationModelsTasks = (filter) => {
    return authAxios.post('tasks/simulation', {...filter})
}

export const getSingleModelTask = (modelName) => {
    return authAxios.post('models/by_name', {
        model: modelName
    })
}


export const updateSimulationTaskStatus = (task, status) => {
    return authAxios.put(`tasks/simulation/${task}/${status}`)
}

export const deleteModelApi = (modelName) => {
    return authAxios.post('models/delete_by_name', {
        model: modelName
    })
}

export const cancelSimApi = (model_name) => {
    return authAxios.post('tasks/cancel/simulation', {
        model: model_name
    })
}


export const startRosSimulation = (task) => {
    return authAxios.post(`tasks/simulation/${task}`)
}
