import {call, put, takeLatest} from "redux-saga/effects";
import {START_STREAM} from "./types";
import {
    getStreamFail,
    getStreamListRequest,
    getStreamListSuccess,
    sendGeneralMessage,
    submitStreamRequest, submitStreamSuccess
} from "./actions";
import {getStreamListRequestCall, submitStreamRequestCall} from "./requests";
import i18n from "../../../i18n";


function* submitStreamUriSaga() {
    try {
        yield put(getStreamListRequest())
        const {data} = yield call(getStreamListRequestCall)


        if (data.length > 0 && data[0]['running']) {
            const [stream] = data
            yield put(getStreamListSuccess(`${process.env.REACT_APP_STREAM_URL}${stream.uri}`))


        } else {
            yield put(submitStreamRequest())
            const {data} = yield call(submitStreamRequestCall)
            yield put(submitStreamSuccess(`${process.env.REACT_APP_STREAM_URL}${data.uri}`))

        }

    } catch (e) {
        yield put(getStreamFail())
        yield put(sendGeneralMessage(i18n.t('stream_error'), 'error'))

    }
}


export default function* layoutSaga() {
    yield takeLatest(START_STREAM, submitStreamUriSaga)
}