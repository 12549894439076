import {
    CLOSE_PLY_VIEWER_MODAL,
    CLOSE_STATUS_MODEL_VIEWER,
    GET_STREAM_FAIL,
    GET_STREAM_LIST_REQUEST,
    GET_STREAM_LIST_SUCCESS,
    HIDE_GENERAL_MESSAGE, OPEN_PLY_VIEWER_MODAL,
    OPEN_STATUS_MODEL_VIEWER,
    SEND_GENERAL_MESSAGE,
    SET_CHOSEN_MENU_TAB,
    SET_FIRST_TIME_RENDERED,
    SET_REDIRECT_PATH,
    START_STREAM,
    SUBMIT_STREAM_URL_REQUEST,
    SUBMIT_STREAM_URL_SUCCESS
} from "./types";

export const sendGeneralMessage = (message, type) => ({type: SEND_GENERAL_MESSAGE, payload: {message, type}})
export const closeGeneralMessage = () => ({type: HIDE_GENERAL_MESSAGE})
export const setChosenNavTab = (item) => ({type: SET_CHOSEN_MENU_TAB, payload: item})

export const setFirstTimeRendered = () => ({
    type: SET_FIRST_TIME_RENDERED
})

export const startStream = () => ({
    type: START_STREAM
})

export const openStatusModalViewer = () => ({
    type: OPEN_STATUS_MODEL_VIEWER
})
export const closeStatusModalViewer = () => ({
    type: CLOSE_STATUS_MODEL_VIEWER
})

export const openPlyViewerModal = () => ({
    type: OPEN_PLY_VIEWER_MODAL
})
export const closePlyViewerModal = () => ({
    type: CLOSE_PLY_VIEWER_MODAL
})

export const submitStreamRequest = () => ({
    type: SUBMIT_STREAM_URL_REQUEST
})
export const submitStreamSuccess = (streamUrl) => ({
    type: SUBMIT_STREAM_URL_SUCCESS, payload: streamUrl
})
export const getStreamFail = () => ({
    type: GET_STREAM_FAIL
})


export const getStreamListRequest = () => ({
    type: GET_STREAM_LIST_REQUEST
})
export const getStreamListSuccess = (streamUrl) => ({
    type: GET_STREAM_LIST_SUCCESS, payload: streamUrl
})


export const setRedirectPath = (path) => ({
    type: SET_REDIRECT_PATH, payload: path
})
