import React, {useContext, useEffect, useState} from 'react'
import {Button, Typography} from "@mui/material";
import StyledCheckbox from "../../components/StyledInputs/StyledCheckbox";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import {clearState} from "../../redux/reducers/downloaderReducer/actions";
import VTKViewer from "../../utils/VTKViewer";
import check_role from "../../utils/utils";
import {LOAD_FILES_PARTS} from "../../redux/reducers/downloaderReducer/reducer";
import {saveAction} from "../../redux/reducers/userReducer/actions";

export default function PartsViewer({hide_buttons, error}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [allChecked, setAllChecked] = useState(false)
  const {state: {ws}} = useContext(SocketContext);
  const {taskId: task_id} = useSelector((state) => state.statusReducer)
  const {username} = useSelector((state) => state.cachedReducer)
  const {parts_files, loading_parts} = useSelector((state) => state.downloaderReducer)

  useEffect(() => {
    if (task_id) {
      dispatch({type: LOAD_FILES_PARTS, dispatch, task_id});
    }
    return () => {
      dispatch(clearState())
    };
  }, [task_id])

  return (
    <div style={{height: '100%'}}>
      <div style={{
        height: '100%',
        flexBasis: '25%'
      }}>
        {/*<ModalCheck/>*/}
        <div style={{
          backgroundColor: '#141414',
          borderRadius: '8px',
          height: 'calc(100% - 20px)',
          padding: '10px'
        }}>
          {error ? <Typography color={'red'}>{t(error)}</Typography>
          :
           <Typography color={'white'}> {t('ScanResult')}</Typography>}
          <div style={{textAlign: 'center', height: '85%'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              {/*<DeskViewer3D isMobile={false}/>*/}
              <VTKViewer files={parts_files} class_name="parts_VTK_viewer" loading={loading_parts}/>
            </div>
          </div>

          {!check_role(username) || hide_buttons ? '' :
          <div style={{textAlign: 'center'}}>
            {error ? '' :
              <>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <StyledCheckbox color={'secondary'} checked={allChecked}
                                  onChange={(event, checked) => {
                                    // if(checked){dispatch(openPlyViewerModal())}
                                    setAllChecked(checked)
                                  }}/>
                  <Typography color={allChecked ? '#54fcef' : 'white'}
                              fontSize={'1.6vh'}>{t('allChecked')}</Typography>
                </div>
                <Button variant={'contained'} color={"secondary"} style={{marginTop: '10px'}}
                        onClick={() => {
                          dispatch(saveAction(task_id, t('ContinueAssembling').toUpperCase()+"(Status, Inspection parts)"))
                          ws.emit('inspection_parts_end', task_id)
                        }}
                        disabled={!(allChecked)}>{t('ContinueAssembling')}
                </Button>
              </>
            }
            <Button color={'success'} variant={'contained'}
                    style={{flexBasis: '250px', marginLeft: '20px', marginTop: '10px'}}
                    onClick={() => {
                      dispatch(saveAction(task_id, t('MakeReScanParts').toUpperCase()+'(Status)'))
                      ws.emit('start_scan_parts', task_id)
                    }}> {t('MakeReScanParts')}</Button>
            <Button color={'success'} variant={'contained'}
                    style={{flexBasis: '250px', marginLeft: '20px', marginTop: '10px'}}
                    onClick={() => {
                      dispatch(saveAction(task_id, t('MakeReMatchParts').toUpperCase()+'(Status)'))
                      ws.emit('start_match_parts', task_id)
                    }}> {t('MakeReMatchParts')}</Button>
            {error ? '' :
              <Button color={'success'} variant={'contained'}
                 style={{flexBasis: '250px', marginLeft: '20px', marginTop: '10px'}}
                 onClick={() => {
                   dispatch(saveAction(task_id, t('MakeReInspectionParts').toUpperCase()+'(Status)'))
                   ws.emit('start_inspection_parts', task_id)}}> {t('MakeReInspectionParts')}</Button>
            }
          </div>
          }
        </div>
      </div>
    </div>
  )
}
