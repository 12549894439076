import React, {useContext} from 'react';
import {Box, Button, Modal, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledConfirmModal} from "./StyledConfirmModal";
import {resetPartsCounter} from "../../../../redux/reducers/statusReducer/actions";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import warning_image from "../../../../assets/images/warning.jpg";


function ConfirmModal({open, handleClose, checks_ok, args}) {
	const {t} = useTranslation();
	const {state: {ws}} = useContext(SocketContext);
	const dispatch = useDispatch()
	const {beam_stand, parts_to_skip, racksAreEstablished, beamIsEstablished, beamLengthConfirm, detailsAreEstablished,
		tableNumber} = useSelector((state) => state.statusReducer)

	const get_beam_pos = () => {
		switch (beam_stand){
			case 90:
			case 270:
				return 'H'
			case 0:
			case 180:
			default:
				return 'I'
		}
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledConfirmModal>
				<Box sx={{ width: '100%', height: '100%' }}>
					<Box className="modal_body">
						<div className="modal_content">
							<img alt="" src={warning_image} style={{width: '90px', borderRadius: '10px'}}/>
							<div className="title"><b>Please confirm the following before proceeding:</b></div>
							<ul className="modal_list">
								<li><Typography color={'black'}>{t('racksAreEstablished')}</Typography></li>
								<li><Typography color={'black'}>{t('beamIsEstablished')}</Typography></li>
								<li><Typography color={'black'}>Beam position: {get_beam_pos()}</Typography></li>
								<li><Typography color={'black'}>{t('beamLengthConfirm')}</Typography></li>
								<li><Typography color={'black'}>{t('detailsAreEstablished')}</Typography></li>
								<li><Typography color={'black'}>Selected tables: {tableNumber}/6</Typography></li>
								<li><Typography color={'black'}>{t('excluded_parts')}:</Typography>
									{parts_to_skip && parts_to_skip.length > 0 &&
									 <table className="parts_table">
										 <tbody>
										 <tr>
											 <td>
												 <div className="parts_div">
													 {parts_to_skip.map((item, index) => {
														 return <span key={index} style={{color: 'black', padding: '0px 5px'}}>{item}</span>
													 })}
												 </div>
											 </td>
										 </tr>
										 </tbody>
									 </table>
									}
								</li>
							</ul>
						</div>
						<div className="warning">
							<div className="warning_body">
								<b>Warning:</b> Failure to accurately verify any of the above items may result in significant damage or
								unproductive functionality.
							</div>
						</div>
					</Box>
					<Box className="modal_footer">
						<Button variant={'contained'} color={'success'}
										disabled={checks_ok}
										onClick={() => {
											dispatch(resetPartsCounter())
											ws.emit('start_assembling', args)
										}}
										style={{width: '100px', margin: '0px 15px'}}>  {t('Confirm')} </Button>
						<Button variant={'contained'} color={'secondary'}
										disabled={checks_ok}
										onClick={() => {
											handleClose()
										}}
										style={{width: '100px', margin: '0px 15px'}}>  {t('Back')} </Button>
					</Box>
				</Box>
			</StyledConfirmModal>
		</Modal>
	);
}

export default ConfirmModal;
