import {createContext, useReducer} from "react";
import {SET_WS} from "./types";

const initialState = {
    ws: null

};

function init(initialState) {
    return {...initialState};
}

const reducer = (state, action) => {

    switch (action.type) {
        case SET_WS:
            return {...state, ws: action.payload}


        default:
            return state;
    }
};

export const SocketContext = createContext(null);

export function CustomReducerProvider(props) {
    const [state, update] = useReducer(reducer, initialState, init);

    return <SocketContext.Provider value={{state, update}}>{props.children}</SocketContext.Provider>;
}