import React from 'react';
import {Box, Modal, TableHead} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import {continueProcessingTaskRequest} from "../../redux/reducers/readyTasksReducer/actions";
import ServiceButton from "../../components/ServiceButton/ServiceButton";
import {useDispatch} from "react-redux";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
function ContinueTaskModal({open, handleClose, tasks, model_name, submittingProcessingTask}) {
  const dispatch = useDispatch()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"><b>Task ID</b></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks && tasks.map((item, index) =>
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{item}</TableCell>
                    <TableCell align="left">
                      <ServiceButton
                        variant={'contained'}
                        style={{marginLeft: '20px'}}
                        color={'secondary'}
                        buttonName={'ContinueTask'}
                        width={170}
                        height={40}
                        loadingCondition={submittingProcessingTask}
                        onClick={() => {
                          dispatch(continueProcessingTaskRequest(item, model_name))
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
      </Box>
    </Modal>
  );
}

export default ContinueTaskModal;
