import {authAxios} from "../../../settingsFiles/axiosSettings";
import axios from "axios";
import {SET_PERCENTAGE} from "./reducer";

export const loadFileList = async (task_id) => {
  return await authAxios.post('/plyviewer/get_file_list', {"task_id": task_id})
}

export const loadFile = async (name, task_id, index, dispatch) => {
  return await authAxios.post('/plyviewer/get_file',{"name": name, "task_id": task_id}, {
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({type: SET_PERCENTAGE, percentCompleted, index});
    },})
}

export const loadFileVTK = async (name, task_id, index, dispatch) => {
  return await authAxios.post('/vtkviewer/get_file',{"name": name, "task_id": task_id}, {
    responseType: 'arraybuffer',
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({type: SET_PERCENTAGE, percentCompleted, index});
    },})
}

export const loadLocalFile = async (name, index, dispatch) => {
  return await axios.get('/clouds/'+name+'.ply', {
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({type: SET_PERCENTAGE, percentCompleted, index});
    },})
}
