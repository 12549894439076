import React from 'react';
import {useSelector} from "react-redux";
import {
	IDLE,
	INSPECTION_BEAM_CONFIRMED,
	INSPECTION_BEAM_ERROR,
	INSPECTION_BEAM_IS_RUNNING,
	INSPECTION_BEAM_SUCCESS,
	INSPECTION_BEAM_TIMEOUT_ERROR,
} from "../statusConstants";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import RackViewer from "../../../pages/StatusMainPage/ScanningBeam/RackViewer";
import StatusInfo from "../GeneralComponents/StatusInfro/StatusInfo";


const InspectionBeamComponent = ({screenVersion = false, isMobile}) => {
	const {inspectionBeamStatus} = useSelector(state => state.statusReducer)

	const statusRender = (status) => {
		switch (status) {
			case IDLE:
			case INSPECTION_BEAM_CONFIRMED:
				return <StatusInfo status={status} isMobile={isMobile}/>
			case INSPECTION_BEAM_SUCCESS:
			case INSPECTION_BEAM_ERROR:
				return <RackViewer/>
			case INSPECTION_BEAM_IS_RUNNING:
				return !screenVersion ? <ProcessAction status={status}/> : <ProcessingActionScreen status={status}/>
			case INSPECTION_BEAM_TIMEOUT_ERROR:
				return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
			default:
				return <UnknownStatus isMobile={isMobile}/>
		}
	}

	return statusRender(inspectionBeamStatus)
};

export default InspectionBeamComponent;
