import {call, put, takeLatest} from "redux-saga/effects";
import {loading, set_data} from "./actions";
import {loadFileVTK} from "./requests";
import {sendGeneralMessage} from "../layoutReducer/actions";
import {LOAD_FILES_LIST} from "./reducer";
import i18n from "../../../i18n";

function* loadVTKScanPartsViewerVTK(action) {
  try {
    if(!action.task_id){
      return
    }
    yield put(loading(true))
    let result = [{
      "name": "matching.vtksz",
      "percentage": 0,
      "loaded": false,
      "file": null
    }];
    yield put(set_data(result))
    let {data: file} = yield call(loadFileVTK, result[0].name, action.task_id, 0, action.dispatch)
    if(file !== 'File not found'){
      result[0] = {
        "name": result[0].name,
        percentage: 100,
        "loaded": true,
        "file": file ?? null
      }
    }
    yield put(set_data(result))
    yield put(loading(false))
    yield put(sendGeneralMessage(i18n.t('vtk_loading_success'), 'success'))
    yield new Promise(resolve =>
      setTimeout(() => {
        action.setShowDownloads(false);
        resolve();
      }, 5000)
    );
  } catch (e) {
    console.log(e)
    yield put(sendGeneralMessage(i18n.t('vtk_loading_error'), 'error'))
  }
}

export default function* statusSaga() {
  // yield takeLatest(LOAD_FILES_LIST, loadPlyViewerLocalFileList)
  // yield takeLatest(LOAD_FILES_LIST, loadPlyViewerFileList)
  yield takeLatest(LOAD_FILES_LIST, loadVTKScanPartsViewerVTK)
}
