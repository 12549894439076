export const SEND_GENERAL_MESSAGE = 'SEND_GENERAL_MESSAGE'
export const HIDE_GENERAL_MESSAGE = 'HIDE_GENERAL_MESSAGE'
export const SET_CHOSEN_MENU_TAB = 'SET_CHOSEN_MENU_TAB'

export const SET_FIRST_TIME_RENDERED = 'SET_FIRST_TIME_RENDERED'

export const OPEN_SETTINGS_MODAL = 'OPEN_SETTINGS_MODAL'
export const CLOSE_SETTINGS_MODAL = 'CLOSE_SETTINGS_MODAL'


export const START_STREAM = 'START_STREAM'

export const SUBMIT_STREAM_URL_REQUEST = 'SUBMIT_STREAM_URL_REQUEST'
export const SUBMIT_STREAM_URL_SUCCESS = 'SUBMIT_STREAM_URL_SUCCESS'
export const GET_STREAM_FAIL = 'GET_STREAM_FAIL'

export const GET_STREAM_LIST_REQUEST = 'GET_STREAM_LIST_REQUEST'
export const GET_STREAM_LIST_SUCCESS = 'GET_STREAM_LIST_SUCCESS'

export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH'

export const OPEN_STATUS_MODEL_VIEWER = 'OPEN_STATUS_MODEL_VIEWER'
export const CLOSE_STATUS_MODEL_VIEWER = 'CLOSE_STATUS_MODEL_VIEWER'

export const OPEN_PLY_VIEWER_MODAL = 'OPEN_PLY_VIEWER_MODAL'
export const CLOSE_PLY_VIEWER_MODAL = 'CLOSE_PLY_VIEWER_MODAL'
