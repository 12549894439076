import {PDFDocument} from 'pdf-lib'


const get_string = (a,b) => {
  return a+'/'+b
}

const set_I_data = (form, inspection) => {
  const l_up = form.getTextField('l_up')
  const l_down = form.getTextField('l_down')
  const l_h_left = form.getTextField('l_h_left')
  const l_d_left = form.getTextField('l_d_left')
  const l_h_right = form.getTextField('l_h_right')
  const l_d_right = form.getTextField('l_d_right')
  const r_up = form.getTextField('r_up')
  const r_down = form.getTextField('r_down')
  const r_h_left = form.getTextField('r_h_left')
  const r_d_left = form.getTextField('r_d_left')
  const r_h_right = form.getTextField('r_h_right')
  const r_d_right = form.getTextField('r_d_right')
  let left_flange = inspection.flange_width.left_torec
  let right_flange = inspection.flange_width.right_torec
  let left_h = inspection.web.left_torec.height
  let left_d = inspection.web.left_torec.depth
  let right_h = inspection.web.right_torec.height
  let right_d = inspection.web.right_torec.depth
  l_up.setText(get_string(left_flange.mesh_up, left_flange.cloud_up))
  l_down.setText(get_string(left_flange.mesh_down, left_flange.cloud_down))
  l_h_left.setText(get_string(left_h.mesh_left, left_h.cloud_left))
  l_h_right.setText(get_string(left_h.mesh_right, left_h.cloud_right))
  l_d_left.setText(get_string(left_d.mesh_left, left_d.cloud_left))
  l_d_right.setText(get_string(left_d.mesh_right, left_d.cloud_right))
  r_up.setText(get_string(right_flange.mesh_up, right_flange.cloud_up))
  r_down.setText(get_string(right_flange.mesh_down, right_flange.cloud_down))
  r_h_left.setText(get_string(right_h.mesh_left, right_h.cloud_left))
  r_h_right.setText(get_string(right_h.mesh_right, right_h.cloud_right))
  r_d_left.setText(get_string(right_d.mesh_left, right_d.cloud_left))
  r_d_right.setText(get_string(right_d.mesh_right, right_d.cloud_right))
}

const set_H_data = (form, inspection) => {
  const l_left = form.getTextField('l_left')
  const l_right = form.getTextField('l_right')
  const l_d_down = form.getTextField('l_d_down')
  const l_d_up = form.getTextField('l_d_up')
  const l_h_down = form.getTextField('l_h_down')
  const l_h_up = form.getTextField('l_h_up')
  const r_left = form.getTextField('r_left')
  const r_right = form.getTextField('r_right')
  const r_d_down = form.getTextField('r_d_down')
  const r_d_up = form.getTextField('r_d_up')
  const r_h_down = form.getTextField('r_h_down')
  const r_h_up = form.getTextField('r_h_up')
  let left_flange = inspection.flange_width.left_torec
  let right_flange = inspection.flange_width.right_torec
  let left_h = inspection.web.left_torec.height
  let left_d = inspection.web.left_torec.depth
  let right_h = inspection.web.right_torec.height
  let right_d = inspection.web.right_torec.depth
  l_left.setText(get_string(left_flange.mesh_left, left_flange.cloud_left))
  l_right.setText(get_string(left_flange.mesh_right, left_flange.cloud_right))
  l_d_down.setText(get_string(left_d.mesh_down, left_d.cloud_down))
  l_d_up.setText(get_string(left_d.mesh_up, left_d.cloud_up))
  l_h_down.setText(get_string(left_h.mesh_down, left_h.cloud_down))
  l_h_up.setText(get_string(left_h.mesh_up, left_h.cloud_up))
  r_left.setText(get_string(right_flange.mesh_left, right_flange.cloud_left))
  r_right.setText(get_string(right_flange.mesh_right, right_flange.cloud_right))
  r_d_down.setText(get_string(right_d.mesh_down, right_d.cloud_down))
  r_d_up.setText(get_string(right_d.mesh_up, right_d.cloud_up))
  r_h_down.setText(get_string(right_h.mesh_down, right_h.cloud_down))
  r_h_up.setText(get_string(right_h.mesh_up, right_h.cloud_up))
}
export const getDocUrl = async (inspection) => {
  let blob = null
  try {
    let pdf_url = 'assets/inspection_I_Form.pdf'
    if(inspection.beam_orientation == 'H'){
      pdf_url = 'assets/inspection_H_Form.pdf'
    }
    let arr_buff = await fetch(pdf_url)
      .then(response => response.arrayBuffer())
      .then(arr_buff => {
        return arr_buff
      })
      .catch((error) => {
        console.error('Download error:', error)
        return null
      });

    const pdfDoc = await PDFDocument.load(arr_buff)
    const form = pdfDoc.getForm()

    switch(inspection.beam_orientation){
      case 'I':
        set_I_data(form, inspection)
        break;
      case 'H':
        set_H_data(form, inspection)
        break;
      default:
        console.log('No inspection data found')
        return null
    }
    const fields = form.getFields()
    fields.forEach(field => {
      field.enableReadOnly()
    })

    const pdfBytes = await pdfDoc.save()
    const bytes = new Uint8Array(pdfBytes);
    blob = new Blob([bytes], {type: "application/pdf"});
  } catch (e) {
    console.log(e)
  }
  const pdfUrl = URL.createObjectURL(blob);
  return pdfUrl
}
