import React from 'react';
import {AppBar, Button, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import RobotStatus from "../RobotStatus/RobotStatus";
import SettingsIcon from '@mui/icons-material/Settings';

import CustomLink from "../LogicalComponents/Routes/CustomLink";
import {makeStyles} from "@mui/styles";
import {openStatusModalViewer} from "../../redux/reducers/layoutReducer/actions";

const useStyles = makeStyles((theme) => ({
  appBarStyle: {height: '100%', borderRadius: '10px', padding: '5px'},
  mainFlexContainer: {height: '100%', display: 'flex', alignItems: 'center'},
  textStyle: {
    flexBasis: '150px',
  },
  robotStatusStyle: {
    width: '60%'
  },
  languageButtons: {flexBasis: '150px'},
  adminText: {flexBasis: '70px'},
  avatar: {
    border: '3px solid #2C2C2C'
  },
  avatarContainer: {
    flexBasis: '75px'
  },
  settingsButton: {
    verticalAlign: 'middle',
    padding: '5px'
  }
}))

function MobileNavBar() {

  const dispatch = useDispatch()
  const {assemblyName} = useSelector(state => state.statusReducer)
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBarStyle}>
      <div className={classes.mainFlexContainer}>
        <div className={classes.robotStatusStyle}>
          <RobotStatus/>
        </div>
        <div style={{width: '40%', textAlign: 'center'}}>
          <Button color={'secondary'} onClick={()=>{dispatch(openStatusModalViewer())}}>
            <Typography>{assemblyName}</Typography>
          </Button>
          <CustomLink to={'/settings'} className={classes.settingsButton}>
            <SettingsIcon style={{color: 'white'}}/>
          </CustomLink>
        </div>
      </div>
    </AppBar>
  );
}

export default MobileNavBar;
