import React from 'react';
import {Typography} from "@mui/material";
import {useSelector} from "react-redux";
import ideImage from '../../assets/images/ideStatus.gif'
import {
    EXECUTOR_AND_PLANNING_ACTION,
    GETTING_INITIAL_INFORMATION,
    IDLE,
    LABELING_AND_PLANNING_ACTION,
    MOUNTING,
    SCANNING_BEAM_ACTION,
    SCANNING_PARTS_ACTION, SUCCESSFULLY_ENDED, TURN_BEAM
} from "./statusConstants";
import {useTranslation} from "react-i18next";
import MountingComponent from "./GeneralComponents/MountingComponent/MountingComponent";
import ExecutorPlannerLabeling from "./ExecutorPlannerLabeling/ExecutorPlannerLabeling";
import UnknownStatus from "./GeneralComponents/UnknownStatus/UnknownStatus";
import StatusInfo from "./GeneralComponents/StatusInfro/StatusInfo";
import RotateBeam from "./GeneralComponents/RotateBeam/RotateBeam";
import MobileMounting from "./GeneralComponents/MountingComponent/MobileMounting";
import MobileRotateBeam from "./GeneralComponents/RotateBeam/MobileRotateBeam";
import Vision from "./Vision";


function StatusesComponents({isMobile}) {

    const {taskStatus} = useSelector(state => state.statusReducer)
    const {t} = useTranslation()

    const renderStatus = (status) => {
        // return <ScanningPartsComponent/>
        switch (status) {
            case IDLE:
                return (
                    <div style={isMobile ? {textAlign: 'center', height: '80vh'} : {height: '80vh'}} className={'globalCentered'}>
                        {isMobile ?
                          <div>
                              <img src={ideImage} alt="ok" style={{width: '100%'}}/>
                              <br/>
                              <Typography color={'white'}> {t('NoTasksToBeAssembled')}</Typography>
                          </div>
                          :
                          <>
                              <img src={ideImage} alt="ok"/>
                              <br/>
                              <Typography color={'white'}> {t('NoTasksToBeAssembled')}</Typography>
                          </>
                        }
                    </div>
                )
            case MOUNTING:
                return isMobile ? <MobileMounting/> : <MountingComponent/>
            case SCANNING_BEAM_ACTION:
            case SCANNING_PARTS_ACTION:
                return <Vision isMobile={isMobile}/>
            case LABELING_AND_PLANNING_ACTION:
            case EXECUTOR_AND_PLANNING_ACTION:
                return <ExecutorPlannerLabeling status={status} isMobile={isMobile}/>
            case GETTING_INITIAL_INFORMATION:
                return <StatusInfo status={GETTING_INITIAL_INFORMATION} isMobile={isMobile}/>
            case TURN_BEAM:
                return  isMobile ? <MobileRotateBeam/> : <RotateBeam/>
            case SUCCESSFULLY_ENDED:
                return <StatusInfo status={SUCCESSFULLY_ENDED} isMobile={isMobile}/>
            default:
                return <UnknownStatus isMobile={isMobile}/>
        }
    }


    return renderStatus(taskStatus)
}

export default StatusesComponents;
