

export const changeStatusProcessingTask = (data, name, status) => {
    const index = data.findIndex((item) => item.name === name)
    const copy = {...data[index], status: status}
    return [
        ...data.slice(0, index), copy, ...data.slice(index + 1)]
}


export const removeModel = (data, name) => {
    const index = data.findIndex((item) => item.name === name)
    return [...data.slice(0, index), ...data.slice(index + 1)]
}
