import React from 'react';
import {Box, Modal} from "@mui/material";
import Parts from "./Parts";
import {StyledSkippedParts} from "./StyledSkippedParts";


function SkippedParts({open, handleClose}) {

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledSkippedParts>
				<Box sx={{ width: '100%', height: '100%' }}>
						<Parts/>
				</Box>
			</StyledSkippedParts>
		</Modal>
	);
}

export default SkippedParts;
