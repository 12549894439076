import {LOGIN_FAIL, LOGIN_SUCCESS} from "../userReducer/types";
import {SET_CHOSEN_MENU_TAB} from "../layoutReducer/types";


import initialViewerSettings from "../../../utils/initialViewerSettings";
import {SET_INITIAL_VIEWER_SETTINGS, SET_PARTS_TYPE, SET_UNITS, SET_VIEWER_CONSTANTS} from "./types";


const initialState = {
    accessToken: null,
    full_name: null,
    username: null,
    isAuthenticated: false,
    chosenSection: 'Models',
    viewerSettings: initialViewerSettings,
    partsType: 'parts',
    units: 'meters'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {...state, accessToken: action.data.access_token, isAuthenticated: true,
                full_name: action.data.full_name, username: action.data.username}
        case LOGIN_FAIL:
            return {...state, accessToken: null, isAuthenticated: false, full_name: null, username: null}
        case SET_CHOSEN_MENU_TAB:
            return {...state, chosenSection: action.payload}
        case SET_INITIAL_VIEWER_SETTINGS:
            return {...state, viewerSettings: initialViewerSettings}
        case SET_VIEWER_CONSTANTS:

            return {
                ...state,
                viewerSettings: action.payload
            }
        case SET_PARTS_TYPE:
            return {...state, partsType: action.payload}
        case SET_UNITS:
            return {...state, units: action.payload}
        default:
            return state;
    }
};

export default reducer;
