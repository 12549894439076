import React, {useEffect, useState} from 'react'
import {initLocalFileLoader} from "./VTKjson";


export default function VTKViewer({files, class_name, loading}) {
  const [not_found, setNot_found] = useState(false)

  useEffect(() => {
    if(files.length > 0 && files[0].file){
      if(files[0].file === 'File not found'){
        setNot_found(true)
      }else{
        setNot_found(false)
        let elem = document.querySelector('.'+class_name)
        initLocalFileLoader(elem, files[0].file)
      }
    }
  }, [loading])

  return (
      <div style={{position: 'relative', height: '100%', width: '100%'}}>
        {not_found ?
         <div style={{color: 'white', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>File not found...</div>
          :
         <div className={class_name} style={{height: '100%'}}/>
        }
      </div>
  )
}
