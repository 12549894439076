import React from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getArchivedTasksListRequest} from "../../redux/reducers/archiveReducer/actions";
import ArchiveTable from "./ArchiveTable";
import {FormControl, Grid, MenuItem, Pagination, Select} from "@mui/material";
import SelectedTaskView from "./SelectedTaskView";
import useTableFilter from "../../utils/useTableFilter";
import {StyledArchive} from "./StyledArchive";

export default function Archive() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {total_pages, selectedTask} = useSelector((state => state.archiveReducer))
  const loadData = (filter) => dispatch(getArchivedTasksListRequest(t, filter));
  const {filter, changeFilter, toPage, changePageSize} = useTableFilter(loadData, {
    sortField: 'sort_date',
    sortOrder: 'desc',
    operator: 'all',
    pageNumber: 1,
    pageSize: 20
  });

  return(
    <StyledArchive>
      <Grid container style={{width: '100%', height: '100%'}} spacing={3}>
        <Grid item xs={12} sm={7}>
          <ArchiveTable filter={filter} changeFilter={changeFilter}/>
          <div style={{position: 'relative'}}>
            <Pagination count={total_pages} color="primary" style={{padding: '5px 10px', backgroundColor: 'white'}}
                        onChange={(e , page) => {toPage(page)}}/>
            <FormControl sx={{minWidth: 220, position: 'absolute', right: '5%', bottom: 0, height: '40px', display: 'block'}} size="small">
              <div style={{display: 'inline-block', width: '130px'}}>{t('Quantity_rows')}:</div>
              <Select
                value={filter.pageSize}
                style={{height: '37px', width: '90px'}}
                onChange={(e) => {changePageSize(e.target.value)}}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} style={{height: 'calc(100% + 20px)'}}>
          {selectedTask ?
           <div style={{backgroundColor: '#141414', height: 'calc(100% - 20px)', padding: '10px', overflow: 'scroll'}}>
             <SelectedTaskView/>
           </div>
                        :
           <div style={{height: '100%', backgroundColor: '#141414',
              color: 'white', position: 'relative', border: 'solid 1px white'}}>
               <div style={{
                 position: 'absolute',
                 top: '50%',
                 left: '50%',
                 transform: 'translate(-50%, -50%)'
               }}>{t('Choose_model')}</div>
           </div>
          }
        </Grid>
      </Grid>
    </StyledArchive>
  )
}
