import React from 'react';

function MeshMaterial({type, ...props}) {
    switch (type) {
        case "standardMesh":
            return <meshStandardMaterial {...props}/>
        case "physicalMesh":
            return <meshPhysicalMaterial {...props}/>
        case "normalMesh":
            return <meshNormalMaterial {...props}/>
        case "basicMesh":
            return <meshBasicMaterial {...props}/>
        case "depthMesh":
            return <meshDepthMaterial {...props}/>
        case "lambertMesh":
            return <meshLambertMaterial {...props}/>
        case "matcapMesh":
            return <meshMatcapMaterial {...props}/>
        case "phongMesh":
            return <meshPhongMaterial {...props}/>
        case "toonMesh":
            return <meshToonMaterial {...props}/>
        default:
            return <meshStandardMaterial {...props}/>
    }
}

export default MeshMaterial;