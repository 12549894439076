import React, {Component} from 'react';

import {connect} from 'react-redux';
import {Typography} from "@mui/material";


class ErrorBoundary extends Component {
    state = {
        hasError: false
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography>{this.props.message}</Typography>
                </div>
            )
        }
        return (
            this.props.children
        );
    }
}

export default connect(null, null)(ErrorBoundary)