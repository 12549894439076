export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') !== -1;
};

export const calc_units = (val, units) => {
  if(units === 'meters'){
    return toFixedN(val, 2)
  }else{
    return toFixedN(val*39.37, 4)
  }
}

function toFixedN(value, dp){
  return +parseFloat(value).toFixed( dp );
}