export const getModelsButtonState = (type) => {


    switch (type) {
        case"SIMULATION_NEW":
            return {
                showModelInfoButton: true,
                showSimulationBtn: true,
                showReSimulationBtn: false,
                showSpinnerBtn: false,
                showSaveTaskBtn: false,
                showCancelSimulation: false,
                showDeleteBtn: true,
            }
        case "SIMULATION_IN_PROCESS":
            return {
                showModelInfoButton: true,
                showSimulationBtn: false,
                showReSimulationBtn: false,
                showSpinnerBtn: true,
                showSaveTaskBtn: false,
                showCancelSimulation: true,
                showDeleteBtn: false,
            }
        case "SIMULATION_SUCCESS":
            return {
                showModelInfoButton: true,
                showSimulationBtn: false,
                showReSimulationBtn: true,
                showSpinnerBtn: false,
                showSaveTaskBtn: true,
                showCancelSimulation: false,
                showDeleteBtn: true,
            }
        case "SIMULATION_ERROR":
            return {
                showModelInfoButton: true,
                showSimulationBtn: false,
                showReSimulationBtn: true,
                showSpinnerBtn: false,
                showSaveTaskBtn: false,
                showCancelSimulation: false,
                showDeleteBtn: true,
            }
        default:
            return {
                showModelInfoButton: true,
                showSimulationBtn: false,
                showReSimulationBtn: false,
                showSpinnerBtn: false,
                showSaveTaskBtn: false,
                showCancelSimulation: false,
                showDeleteBtn: false,
            }
    }
}
