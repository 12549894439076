import React from 'react';
import ScreensComponent from "../../components/ScreensComponent/ScreensComponent";
import StatusSocket from "../../components/StatusesComponets/StatusSocket/StatusSocket";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import i18n from "../../i18n";

function Screen1Page() {


    return (
        <ErrorBoundary message={i18n.t('unexpected_error')}>
            <StatusSocket/>
            <ScreensComponent/>
        </ErrorBoundary>
    );
}

export default Screen1Page;