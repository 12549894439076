import {
    CLOSE_PLY_VIEWER_MODAL,
    CLOSE_SETTINGS_MODAL,
    CLOSE_STATUS_MODEL_VIEWER,
    GET_STREAM_FAIL,
    GET_STREAM_LIST_REQUEST,
    GET_STREAM_LIST_SUCCESS,
    HIDE_GENERAL_MESSAGE, OPEN_PLY_VIEWER_MODAL,
    OPEN_SETTINGS_MODAL,
    OPEN_STATUS_MODEL_VIEWER,
    SEND_GENERAL_MESSAGE,
    SET_FIRST_TIME_RENDERED,
    SET_REDIRECT_PATH,
    START_STREAM,
    SUBMIT_STREAM_URL_REQUEST,
    SUBMIT_STREAM_URL_SUCCESS
} from "./types";
import {TURN_OFF_VIEWER_LOADING, TURN_ON_VIEWER_LOADING} from "../modelsReducer/types";

const initialState = {
    messageIsShown: false,
    generalMessage: null,
    messageType: 'success',
    viewerIsLoading: false,
    viewerFirstTimeRendered: true,
    settingsModalIsOpen: false,
    streamUri: "",
    streamIsActive: false,
    streamInfoIsLoading: false,
    streamLoadingErrorOccurred: false,
    redirectPath: null,
    statusModelViewerModalIsOpen:false,
    statusPlyViewerModalIsOpen: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_GENERAL_MESSAGE:
            const {message, type} = action.payload
            return {...state, messageIsShown: true, messageType: type, generalMessage: message}
        case HIDE_GENERAL_MESSAGE:
            return {...state, messageIsShown: false, messageType: 'success', generalMessage: null}
        case TURN_ON_VIEWER_LOADING:
            return {...state, viewerIsLoading: true, viewerFirstTimeRendered: false}
        case TURN_OFF_VIEWER_LOADING:
            return {...state, viewerIsLoading: false}
        case SET_FIRST_TIME_RENDERED:
            return {...state, viewerFirstTimeRendered: true}
        case OPEN_SETTINGS_MODAL:
            return {...state, settingsModalIsOpen: true}
        case CLOSE_SETTINGS_MODAL:
            return {...state, settingsModalIsOpen: false}

        case START_STREAM:
            return {...state, streamIsActive: true}
        case SUBMIT_STREAM_URL_REQUEST:
        case GET_STREAM_LIST_REQUEST:
            return {...state, streamInfoIsLoading: true}
        case GET_STREAM_LIST_SUCCESS:
        case SUBMIT_STREAM_URL_SUCCESS:
            return {...state, streamUri: action.payload, streamInfoIsLoading: false}
        case GET_STREAM_FAIL:
            return {
                ...state,
                streamUri: '',
                streamInfoIsLoading: false,
                streamLoadingErrorOccurred: true,
                streamIsActive: false
            }
        case SET_REDIRECT_PATH:
            return {...state,redirectPath: action.payload}
        case OPEN_STATUS_MODEL_VIEWER:
            return {...state,statusModelViewerModalIsOpen: true}
        case CLOSE_STATUS_MODEL_VIEWER:
            return {...state,statusModelViewerModalIsOpen: false}
        case OPEN_PLY_VIEWER_MODAL:
            return {...state,statusPlyViewerModalIsOpen: true}
        case CLOSE_PLY_VIEWER_MODAL:
            return {...state,statusPlyViewerModalIsOpen: false}
        default:
            return state;
    }
};

export default reducer;
