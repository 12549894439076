import {all} from 'redux-saga/effects';
import userSaga from "./reducers/userReducer/sagas";
import modelsSaga from "./reducers/modelsReducer/sagas";
import readyTasksSaga from "./reducers/readyTasksReducer/sagas";
import statusSga from './reducers/statusReducer/sagas'
import layoutSaga from './reducers/layoutReducer/sagas'
import plyViewerSaga from './reducers/plyViewerReducer/sagas'
import scanBeamViewerSaga from './reducers/scanBeamViewerReducer/sagas'
import archiveSaga from './reducers/archiveReducer/sagas'
import downloaderSaga from './reducers/downloaderReducer/sagas'

export function* rootSaga() {
    yield all(
        [userSaga(),
            modelsSaga(),
            readyTasksSaga(),
            statusSga(),
            layoutSaga(),
            plyViewerSaga(),
            scanBeamViewerSaga(),
            archiveSaga(),
            downloaderSaga(),
        ])
}
