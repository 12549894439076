import React, {useContext, useState} from 'react';
import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  cancelProcessingTaskRequest,
  resetPartsCounter,
} from "../../../redux/reducers/statusReducer/actions";

import {SocketContext} from "../../../redux/customReducer/SocketContext";
import StyledCheckbox from "../../StyledInputs/StyledCheckbox";
import TableInput from "./TableInput";
import ServiceButton from "../../ServiceButton/ServiceButton";


function MobileStatusStartSettings() {

  const {
    state: {ws},
  } = useContext(SocketContext);
  const dispatch = useDispatch()

  const {taskId, beamPosition, tableLength, processingTaskIsCanceling} = useSelector((state) => state.statusReducer)

  const [allChecked, setAllChecked] = useState(false)
  const [tableIsOk, setTableIsOk] = useState(true)
  const {t} = useTranslation()

  const onCheck = (checked) => {
    setAllChecked(checked)
  }
  const {
    rackParts: {
      beam_offset,
      holder3_is_used,
      holder2_position,
      holder3_position,
      holder1_offset,
      holder2_offset,
      holder3_offset
    }
  }
    = useSelector(state => state.statusReducer)

  return (
    <>
      <Box style={{backgroundColor: '#141414', borderRadius: '8px', minHeight: '86%'}}>
        <br/>
        <div style={{marginLeft: '30px'}}>
          <div style={{color: 'white'}}>
            {t('holder2_position')}: {holder2_position} {t('m')}<br/>
            {holder3_is_used &&
              <>{t('holder3_position')}: {holder3_position} {t('m')}<br/></>
            }
            {t('beam_offset')}: {beam_offset} {t('mm')}<br/>
            {t('holder1_offset')}: {holder1_offset} {t('mm')}<br/>
            {t('holder2_offset')}: {holder2_offset} {t('mm')}<br/>
            {holder3_is_used &&
              <>{t('holder3_offset')}: {holder3_offset} {t('mm')}<br/></>
            }
          </div>
          <div style={{marginLeft: '-10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <StyledCheckbox checked={allChecked}
                              onChange={(event, checked) => {
                                onCheck(checked)
                              }}/>
              <Typography color={allChecked ? '#54fcef' : 'white'}
                          fontSize={'1.6vh'}>{t('allChecked')}</Typography>
            </div>
          </div>

          <Typography color={'white'}
                      style={{fontSize: '1.7vh', marginBottom: '4px'}}>  {t('TableStatusInput')}</Typography>
          <TableInput setTableIsOk={setTableIsOk}/>
          {!tableIsOk ? <Typography style={{fontSize: '1.5vh', flex: 1}}
                                    color={'yellow'}> {t('TableWarning')} </Typography> : null}
        </div>
      </Box>
      <div style={{marginTop: '20px', display: 'flex', padding: '0px 10px'}}>

        <Button variant={'contained'} color={'secondary'}

                disabled={
                  !(allChecked && tableLength !== 0 && tableIsOk)
                }
                onClick={() => {
                  dispatch(resetPartsCounter())
                  ws.emit('start_assembling', {taskId, beamPosition, tableLength})
                }}
                style={{width: '100px'}}>  {t('LaunchButton')} </Button>
        <div style={{marginLeft: '20px'}}>
          <ServiceButton
            color={'error'}
            width={190}
            variant={'outlined'}
            buttonName={'DeleteProcessingTask'}
            loadingCondition={processingTaskIsCanceling}
            onClick={() => {
              dispatch(cancelProcessingTaskRequest(taskId))
            }}/>
        </div>

      </div>
    </>
  );
}

export default MobileStatusStartSettings;
