import React, {useContext, useState} from 'react';
import {Button, Typography} from "@mui/material";
import rotateBeam from '../../../../assets/images/rotateBeam.svg'
import {useTranslation} from "react-i18next";
import StyledCheckbox from "../../../StyledInputs/StyledCheckbox";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import {resetPartsCounter} from "../../../../redux/reducers/statusReducer/actions";

function MobileRotateBeam() {
  const {t} = useTranslation();
  const [allChecked, setAllChecked] = useState(false)
  const onCheck = (checked) => {
    setAllChecked(checked)
  }
  const {
    state: {ws},
  } = useContext(SocketContext);
  const {taskId} = useSelector((state) => state.statusReducer)
  const dispatch = useDispatch()
  const {
    rackParts: {
      beam_offset,
      holder3_is_used,
      holder2_position,
      holder3_position,
      holder1_offset,
      holder2_offset,
      holder3_offset
    }
  }
    = useSelector(state => state.statusReducer)
  return (
    <div style={{ height: '100%' }}>
      <div style={{
        height: 'fit-content',
        flexBasis: '25%'
      }}>
        <Typography color={'white'}> {t('ReverseStep')}</Typography>
        <div style={{
          backgroundColor: '#141414',
          borderRadius: '8px',
          height: '100%',
          padding: '20px'
        }}>
          <div style={{textAlign: 'center'}}>
            <img alt="" src={rotateBeam} style={{height: '220px', margin: '10px 0px'}}/>
          </div>
          <div style={{color: 'white'}}>
            {t('holder2_position')}: {holder2_position} {t('m')}<br/>
            {holder3_is_used &&
              <>{t('holder3_position')}: {holder3_position} {t('m')}<br/></>
            }
            {t('beam_offset')}: {beam_offset} {t('mm')}<br/>
            {t('holder1_offset')}: {holder1_offset} {t('mm')}<br/>
            {t('holder2_offset')}: {holder2_offset} {t('mm')}<br/>
            {holder3_is_used &&
              <>{t('holder3_offset')}: {holder3_offset} {t('mm')}<br/></>
            }
          </div>
          <div style={{marginLeft: '-10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <StyledCheckbox checked={allChecked}
                              onChange={(event, checked) => {
                                onCheck(checked)
                              }}/>
              <Typography color={allChecked ? '#54fcef' : 'white'}
                          fontSize={'1.6vh'}>{t('allChecked')}</Typography>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <Button variant={'contained'} color={'secondary'} style={{marginTop: '10px'}}
                    onClick={()=>{
                      dispatch(resetPartsCounter())
                      ws.emit('start_scan_beam', {task_id: taskId})
                    }}
                    disabled={!(allChecked)}>{t('ContinueAssembling')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileRotateBeam;
