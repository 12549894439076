import styled from 'styled-components';

export const StyledStatusMainPage = styled.div`
  height: calc(100% - 2px);
  width: 100%;
  border: 1px solid white;
  .MuiTab-root{
	  border: 1px solid white;
  }
  .MuiTab-root:hover{
	  background-color: #404040;
  } 
	.MuiGrid-item{
		padding: 0;
	}
`;
