import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {initializeStatusPage} from "../../../redux/reducers/statusReducer/actions";
import {SocketContext} from "../../../redux/customReducer/SocketContext";


function StatusSocket() {
    const {
        state: {ws},
    } = useContext(SocketContext);

    const {statusPageWasInitialized} = useSelector(state => state.statusReducer)
    const dispatch = useDispatch()

    useEffect(() => {
       if(ws && !statusPageWasInitialized){
           // ws.emit('request_initial_data', {task_id: '62294528-a363-45b1-b9ae-84a11e0817fa'})
           ws.emit('request_initial_data')
           dispatch(initializeStatusPage())
       }
    }, [ws])
    return null
}

export default StatusSocket;
