import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {submitProcessingTaskRequest} from "../../../redux/reducers/readyTasksReducer/actions";
import ServiceButton from "../../ServiceButton/ServiceButton";
import {IDLE} from "../../StatusesComponets/statusConstants";
import {Button} from "@mui/material";

function TaskButtons({isMobile, page, openModal, selectedTaskName, handleOpen, t}) {
    const dispatch = useDispatch()
    const {selectedTask, selectedPlannedTask, submittingProcessingTask} = useSelector((state) => state.readyTasksReducer)
    const {taskStatus} = useSelector(state => state.statusReducer)

    return (
        <>
            <ServiceButton variant={'contained'}
                color={'secondary'}
                width={113}
                buttonName={'openModelInfo'}
                onClick={() => {openModal()}}
                style={{marginLeft: '20px', lineHeight: '1.3'}}/>
            {selectedTaskName && <Button variant="contained" color="secondary" style={{
                marginLeft: '20px', fontWeight: 'initial',
                height: '40px', width: '115px'
            }} onClick={handleOpen}> {t('AddToPlan')} </Button>}
            {taskStatus === IDLE && ((page === 'tasks' && selectedTask) || (page === 'planned' && selectedPlannedTask)) ? <>
                <ServiceButton
                    variant={'contained'}
                    style={isMobile ? {position: 'absolute', right: 0, bottom: '40%', padding: '3px 10px'} : {marginLeft: '20px'}}
                    color={'secondary'}
                    buttonName={'SubmitTask'}
                    width={isMobile ? 'initial' : 147}
                    height={isMobile ? 'initial' : 40}
                    loadingCondition={submittingProcessingTask}
                    onClick={() => {
                        dispatch(submitProcessingTaskRequest(page === 'planned' ? 'planned': null))
                    }}
                />
                {/*<ServiceButton variant={'outlined'}*/}
                {/*               width={180}*/}
                {/*               style={{marginLeft: '20px'}}*/}
                {/*               buttonName={'DeleteButtonTask'}*/}
                {/*               onClick={() => dispatch(taskDeleteRequest(selectedTaskName))}*/}
                {/*               color={'error'}/>  */}
            </> : null}
        </>
    );
}

export default TaskButtons;
