import React, {useState} from 'react';
import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
	cancelProcessingTaskRequest,
	setChecked,
} from "../../../redux/reducers/statusReducer/actions";

import StyledCheckbox from "../../StyledInputs/StyledCheckbox";
import TableInput from "./TableInput";
import ServiceButton from "../../ServiceButton/ServiceButton";
import check_role from "../../../utils/utils";
import i_beam from "../../../assets/images/i_beam.jpg";
import h_beam from "../../../assets/images/h_beam.jpg";
import ExcludePartsModal from "../../../pages/StatusMainPage/ExcludePartsModal/ExcludePartsModal";
import ConfirmModal from "./ConfirmModal/CofirmModal";


function StatusStartSettings() {

	const dispatch = useDispatch()

	const {
		beam_stand,
		taskId,
		beamPosition,
		tableLength,
		processingTaskIsCanceling,
		selectedModel,
		parts_to_skip,
		racksAreEstablished,
		beamIsEstablished,
		beamLengthConfirm,
		detailsAreEstablished,
		tableIsOk
	} = useSelector(
		(state) => state.statusReducer)
	const {username} = useSelector((state) => state.cachedReducer)
	const [excludePartsModal, setExcludePartsModal] = useState(false)
	const [confirmModal, setConfirmModal] = useState(false)
	const {t} = useTranslation()

	const onCheck = (name, checked) => {
		dispatch(setChecked(name, checked))
	}

	const get_beam_picture = () => {
		switch (beam_stand){
			case 90:
			case 270:
				return <img alt="" src={h_beam} style={{width: '50%', borderRadius: '10px'}}/>
			case 0:
			case 180:
			default:
				return <img alt="" src={i_beam} style={{width: '50%', borderRadius: '10px'}}/>
		}
	}

	return (
		<>
			<Box style={{backgroundColor: '#141414', borderRadius: '8px', height: '100%'}}>
				<div style={{width: '100%', height: '90%', display: 'flex'}}>
					<div style={{width: 'calc(50% - 30px)', height: 'calc(100% - 30px)', paddingLeft: '30px', paddingTop: '30px', border: 'solid 1px white'}}>
						<Typography color={'white'} style={{fontSize: '1.7vh'}}>  {t('StatusSettings')}</Typography>
						<div style={{marginLeft: '-10px'}}>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<StyledCheckbox checked={racksAreEstablished}
																onChange={(event, checked) => {
																	onCheck('racksAreEstablished', checked)
																}}/>
								<Typography color={racksAreEstablished ? '#54fcef' : 'white'}
														fontSize={'1.6vh'}>{t('racksAreEstablished')}</Typography>
							</div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<StyledCheckbox checked={beamIsEstablished}
																onChange={(event, checked) => {
																	onCheck('beamIsEstablished', checked)
																}}/>
								<Typography color={beamIsEstablished ? '#54fcef' : 'white'}
														fontSize={'1.6vh'}>{t('beamIsEstablished')}</Typography>
							</div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<StyledCheckbox checked={beamLengthConfirm}
																onChange={(event, checked) => {
																	onCheck('beamLengthConfirm', checked)
																}}/>
								<Typography color={beamLengthConfirm ? '#54fcef' : 'white'}
														fontSize={'1.6vh'}>{t('beamLengthConfirm')}</Typography>
							</div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<StyledCheckbox checked={detailsAreEstablished}
																onChange={(event, checked) => {
																	onCheck('detailsAreEstablished', checked)
																}}/>
								<Typography color={detailsAreEstablished ? '#54fcef' : 'white'}
														fontSize={'1.6vh'}>{t('detailsAreEstablished')}</Typography>
							</div>
						</div>

						<Typography color={'white'}
												style={{
													fontSize: '1.7vh',
													marginBottom: '4px'
												}}>{t('TableStatusInput')}</Typography>
						<div style={{maxWidth: "700px"}}>
							<TableInput/>
						</div>
						{!tableIsOk ? <Typography style={{fontSize: '1.5vh', flex: 1}}
																			color={'yellow'}> {t('TableWarning')} </Typography> : null}
						<ExcludePartsModal open={excludePartsModal} handleClose={() => {
							setExcludePartsModal(false)
						}} selectedModel={selectedModel} parts_to_skip={parts_to_skip}/>
						<hr/>
						<Button variant={'contained'} color={'secondary'} onClick={() => {
							setExcludePartsModal(true)
						}}
								style={{marginTop: '10px'}}>{t('ExcludeParts')}</Button>
						{parts_to_skip && parts_to_skip.length > 0 &&
							<table>
								<tbody>
									<tr>
										<td style={{color: 'white', width: '100px'}}>{t('excluded_parts')}:</td>
										<td><div style={{color: 'white', padding: '15px', wordBreak: 'break-all'}}>
											{parts_to_skip.map((item, index) => {
												return <span key={index} style={{color: 'white', padding: '0px 5px'}}>{item}</span>
											})}
										</div></td>
									</tr>
								</tbody>
							</table>
						}
					</div>
					<div style={{width: 'calc(50% - 30px)', height: 'calc(100% - 30px)', paddingLeft: '30px', paddingTop: '30px', border: 'solid 1px white'}}>
						<div style={{height: "100%", width: "100%", textAlign: 'center'}}>
							<Typography color={'white'}
										style={{
											fontSize: '1.7vh',
											marginBottom: '4px',
											textTransform: 'uppercase'
										}}>{t('RackPosition')}:</Typography>
							{get_beam_picture()}
						</div>
					</div>
				</div>
				<div style={{width: 'calc(100% - 30px)', height: 'calc(10% - 2px)', alignItems: 'center', justifyContent: 'left', display: 'flex', paddingLeft: '30px', border: 'solid 1px white'}}>
					{check_role(username) &&
					 <div style={{display: 'flex'}}>
						 <Button variant={'contained'} color={'secondary'}
								 disabled={!(racksAreEstablished && beamIsEstablished && beamLengthConfirm && detailsAreEstablished
								 && tableLength !== 0 && tableIsOk)}
								 onClick={() => {
									 setConfirmModal(true)
								 }}
								 style={{width: '139px'}}>  {t('LaunchButton')} </Button>
						 <div style={{marginLeft: '20px'}}>
							 <ServiceButton
								 color={'error'}
								 width={115}
								 variant={'contained'}
								 buttonName={'DeleteProcessingTask'}
								 loadingCondition={processingTaskIsCanceling}
								 onClick={() => {
									 dispatch(cancelProcessingTaskRequest(taskId))
								 }}/>
						 </div>
						 <ConfirmModal open={confirmModal} handleClose={()=>{setConfirmModal(false)}} checks_ok={!(racksAreEstablished && beamIsEstablished && beamLengthConfirm && detailsAreEstablished
					 && tableLength !== 0 && tableIsOk)} args={{taskId, beamPosition, tableLength, parts_to_skip}}/>
					 </div>
					}
				</div>
			</Box>
		</>
	);
}

export default StatusStartSettings;
