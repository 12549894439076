import {anonymousUser, authAxios} from "../../../settingsFiles/axiosSettings";


export const loginUserRequest = (credentials) => {
    return anonymousUser.post('token', credentials)
}
export const getOperatorsRequest = () => {
    return authAxios.post('get_operators')
}
export const sendLogsApi = (task_id) => {
    return authAxios.post('send_logs', {task_id: task_id})
}
export const saveActionRequest = (task_id, action, username) => {
    return authAxios.post('save_action', {task_id: task_id, action: action, username: username})
}