import React from 'react';
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const AssembledCounter = () => {
  const {assembled_parts, all_parts} = useSelector(state => state.statusReducer)
  const {t} = useTranslation()
  return(
    <div style={{ color: 'white'}}>
      <Typography variant={'h6'}> {t("assembled_parts")}: {assembled_parts}</Typography>
      <Typography variant={'h6'}> {t("all_parts")}: {all_parts}</Typography>
    </div>
  )
};

export default AssembledCounter;
