import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getReadyTasksListRequest} from "../../redux/reducers/readyTasksReducer/actions";
import {CircularProgress, FormControl, Grid, MenuItem, Pagination, Select} from "@mui/material";
import ReadyTasksTable from "../../components/Tables/ReadyTasksTable/ReadyTasksTable";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import TaskButtons from "../../components/ButtonsGroup/TaskButtons/TaskButtons";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import {useTranslation} from "react-i18next";
import ModalAddToPlan from "./ModalAddToPlan";
import useTableFilter from "../../utils/useTableFilter";
import ContinueTask from "./ContinueTask";
import {StyledTasksPage} from "./StyledTasksPage";
import check_role from "../../utils/utils";
import ModelInfoModal from "../ModelsPage/ModelInfoModal/ModelInfoModal";

function TasksPage({isMobile}) {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {selectedCount, selectedTask, missedPartsList, selectedTaskName, total_pages, singleTaskIsLoading} = useSelector((state) => state.readyTasksReducer)
    const {username} = useSelector((state) => state.cachedReducer)
    const [open, setOpen] = React.useState(false);
    const [openInfoModal, setOpenInfoModal] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const loadData = (filter) => dispatch(getReadyTasksListRequest(filter));
    const {filter, changeFilter, reloadPage, toPage, changePageSize} = useTableFilter(loadData, {
        sortField: 'sort_date',
        sortOrder: 'desc',
        pageNumber: 1,
        pageSize: 20
    });

    return (
      <StyledTasksPage>
        <Grid container style={isMobile ? {width: '100%', height: '100%', display: 'block'}
          : {width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '45%' : 'initial'}}>
                <ReadyTasksTable isMobile={isMobile} changeFilter={changeFilter} filter={filter}/>
                <div style={{position: 'relative'}}>
                    <Pagination count={total_pages} color="primary" style={{padding: '5px 10px', backgroundColor: 'white'}}
                                onChange={(e , page) => {toPage(page)}}/>
                    <FormControl sx={{minWidth: 220, position: 'absolute', right: '5%', bottom: 0, height: '40px', display: 'block'}} size="small">
                        <div style={{display: 'inline-block', width: '130px'}}>{t('Quantity_rows')}:</div>
                        <Select
                          value={filter.pageSize}
                          style={{height: '37px', width: '90px'}}
                          onChange={(e) => {changePageSize(e.target.value)}}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/*<TaskSummary/>*/}
            </Grid>
            <Grid item xs={12} sm={6} style={isMobile ? {height: '54%', marginTop: '5px'} : {height: 'initial'}}>
                {selectedTask ?
                 <div style={{backgroundColor: '#141414', height: '100%'}}>
                     <div style={{height: '80%', border: 'solid 1px white'}}>
                         <ErrorBoundary message={t('unexpected_error')}>
                             <Viewer3DContainer selectedModel={selectedTask} missedPartsList={missedPartsList}
                                                isMobile={isMobile} page={'tasks'} height="100%"/>
                         </ErrorBoundary>
                     </div>
                     {check_role(username) &&
                      <div style={{height: '20%', display: 'flex', alignItems: 'center',
                          justifyContent: 'center', border: 'solid 1px white'}}>
                          <TaskButtons isMobile={isMobile} page={'tasks'} t={t} handleOpen={handleOpen}
                                       openModal={() => setOpenInfoModal(true)}
                                       selectedTaskName={selectedTaskName}/>
                          <ContinueTask/>
                      </div>
                     }
                 </div>
                              :
                 <div style={{height: '100%', backgroundColor: '#141414'}}>
                         <div style={{height: '80%', color: 'white', position: 'relative', border: 'solid 1px white'}}>
                             <div style={{
                                 position: 'absolute',
                                 top: '50%',
                                 left: '50%',
                                 transform: 'translate(-50%, -50%)'
                             }}>{singleTaskIsLoading ?
                                 <CircularProgress size={40} style={{color: 'white'}}/> : t('Choose_model')}</div>
                         </div>
                         <div style={{height: '20%', border: 'solid 1px white', position: 'relative', color: 'white'}}>
                             <div style={{
                                 position: 'absolute',
                                 top: '50%',
                                 left: '50%',
                                 transform: 'translate(-50%, -50%)'
                             }}>{singleTaskIsLoading ?
                                 <CircularProgress size={40} style={{color: 'white'}}/> : t('Choose_model')}</div>
                         </div>
                     </div>
                }
            </Grid>
            <ModelInfoModal open={openInfoModal} handleClose={() => setOpenInfoModal(false)} page={"tasks"}/>
            <ModalAddToPlan open={open} handleClose={handleClose} count={selectedCount} reloadPage={reloadPage}
                total={selectedTask?.file?.modelTotal || 0}/>
        </Grid>
      </StyledTasksPage>
    );
}

export default TasksPage;
