import React from 'react';
import {Box, Button, Modal} from "@mui/material";
import {StyledModal} from "./StyledModal";


function ConfirmModal({open, handleClose, action, text}) {

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledModal>
				<Box sx={{ width: '100%', height: '100%' }}>
					<div className={"text"}>
						{text}
					</div>
					<div className={"action"}>
						<Button color="success" variant="contained" style={{margin: '0px 20px'}} onClick={()=> {
							action()
							handleClose()
						}}>
							Yes
						</Button>
						<Button color="secondary" variant="contained" style={{margin: '0px 20px'}} onClick={handleClose}>
							No
						</Button>
					</div>
				</Box>
			</StyledModal>
		</Modal>
	);
}

export default ConfirmModal;
