import {combineReducers} from 'redux';
import userReducer from "./reducers/userReducer/userReducer";
import layoutReducer from './reducers/layoutReducer/layoutReducer'
import cachedReducer from "./reducers/cachedReducer/cachedReducer";
import robotStatusReducer from './reducers/robotStatusReducer/robotStatusReducer'
import modelsReducer from "./reducers/modelsReducer/modelsReducer";
import readyTasksReducer from "./reducers/readyTasksReducer/readyTasksReducer";
import statusReducer from "./reducers/statusReducer/statusReducer";
import plyViewerReducer from "./reducers/plyViewerReducer/reducer"
import scanBeamViewerReducer from  "./reducers/scanBeamViewerReducer/reducer"
import archiveReducer from "./reducers/archiveReducer/reducer"
import downloaderReducer from "./reducers/downloaderReducer/reducer"

export default combineReducers({
    userReducer,
    layoutReducer,
    cachedReducer,
    robotStatusReducer,
    modelsReducer,
    readyTasksReducer,
    statusReducer,
    plyViewerReducer,
    scanBeamViewerReducer,
    archiveReducer,
    downloaderReducer
})
