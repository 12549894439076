import {
    ASSEMBLY_PLAN_PRIORITY_END,
    ASSEMBLY_PLAN_PRIORITY_REQUEST,
    CONTINUE_TASK_PROCESSING_REQUEST,
    GET_PLANNED_TASKS_FAIL,
    GET_PLANNED_TASKS_REQUEST,
    GET_PLANNED_TASKS_SUCCESS,
    GET_READY_TASKS_FAIL,
    GET_READY_TASKS_REQUEST,
    GET_READY_TASKS_SUCCESS,
    GET_SINGLE_PLANNED_TASK_FAIL,
    GET_SINGLE_PLANNED_TASK_REQUEST,
    GET_SINGLE_PLANNED_TASK_SUCCESS,
    GET_SINGLE_READY_TASK_FAIL,
    GET_SINGLE_READY_TASK_REQUEST,
    GET_SINGLE_READY_TASK_SUCCESS,
    SET_LIST_OF_MISSED_PARTS, SET_LIST_OF_MISSED_PARTS_PLANNED,
    SUBMIT_TASK_PROCESSING_FAIL,
    SUBMIT_TASK_PROCESSING_REQUEST,
    SUBMIT_TASK_PROCESSING_SUCCESS,
    TASK_DELETE_MODEL_FAIL,
    TASK_DELETE_MODEL_REQUEST,
    TASK_DELETE_MODEL_SUCCESS,
    UNSELECT_PLANNED_TASK, UNSELECT_TASK,
} from "./types";

export const getPlannedTasksListRequest = () => ({
    type: GET_PLANNED_TASKS_REQUEST
})

export const getPlannedTasksListSuccess = (data) => ({
    type: GET_PLANNED_TASKS_SUCCESS, payload: data
})

export const getPlannedTasksListFail = () => ({
    type: GET_PLANNED_TASKS_FAIL
})

export const getReadyTasksListRequest = (filter) => ({
    type: GET_READY_TASKS_REQUEST, filter
})

export const getReadyTasksListSuccess = (tasks, total_pages) => ({
    type: GET_READY_TASKS_SUCCESS, tasks, total_pages
})

export const getReadyTasksListFail = () => ({
    type: GET_READY_TASKS_FAIL
})


export const getSingleReadyTaskRequest = (taskName, ind, count) => ({
    type: GET_SINGLE_READY_TASK_REQUEST, payload: taskName, ind, count
})

export const getSingleReadyTaskSuccess = (data) => ({
    type: GET_SINGLE_READY_TASK_SUCCESS, payload: data
})

export const getSingleReadyTaskFail = () => ({
    type: GET_SINGLE_READY_TASK_FAIL
})

export const setListOfMissedParts = (list) => ({
    type: SET_LIST_OF_MISSED_PARTS, payload: list
})
export const setListOfMissedPartsPlanned = (list) => ({
    type: SET_LIST_OF_MISSED_PARTS_PLANNED, payload: list
})
export const getSinglePlannedTaskRequest = (taskName, ind, count) => ({
    type: GET_SINGLE_PLANNED_TASK_REQUEST, payload: taskName, ind, count
})

export const getSinglePlannedTaskSuccess = (data) => ({
    type: GET_SINGLE_PLANNED_TASK_SUCCESS, payload: data
})

export const getSinglePlannedTaskFail = () => ({
    type: GET_SINGLE_PLANNED_TASK_FAIL
})
export const unselectPlannedTask = () => ({
    type: UNSELECT_PLANNED_TASK
})
export const unselectTask = () => ({
    type: UNSELECT_TASK
})

export const taskDeleteRequest = (taskName) => ({
    type: TASK_DELETE_MODEL_REQUEST, payload: taskName
})
export const taskDeleteSuccess = (taskName) => ({
    type: TASK_DELETE_MODEL_SUCCESS, payload: taskName
})
export const taskDeleteFail = () => ({
    type: TASK_DELETE_MODEL_FAIL
})



export const continueProcessingTaskRequest = (task_id, model_name) => ({
    type: CONTINUE_TASK_PROCESSING_REQUEST, payload: {task_id: task_id, model_name: model_name}
})
export const submitProcessingTaskRequest = (planned) => ({
    type: SUBMIT_TASK_PROCESSING_REQUEST, planned
})
export const submitProcessingTaskSuccess = (newTask) => ({
    type: SUBMIT_TASK_PROCESSING_SUCCESS, payload: newTask
})
export const submitProcessingTaskFail = () => ({
    type: SUBMIT_TASK_PROCESSING_FAIL
})

export const assemblyPlanPriorityReq = (data) => ({
    type: ASSEMBLY_PLAN_PRIORITY_REQUEST, data
})
export const endAssemblyPlanReq = () => ({
    type: ASSEMBLY_PLAN_PRIORITY_END
})
