import React from 'react';
import {Checkbox} from "@mui/material";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'


const CheckBoxIcon = () => {
    return  <CheckBoxRoundedIcon />
}

const StyledCheckbox = ({...props}) => {
    return (
        <Checkbox
            {...props}
            checkedIcon={<CheckBoxIcon/>}

            sx={{

                color: "#FFFFFF",
                "& .MuiSvgIcon-root": {
                    fontSize: '2.2vh',
                }
            }}
            color={'secondary'}/>
    );
};

export default StyledCheckbox;
