import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    mainTable: {
        width: '100%'
    },
    tableFooterRow: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    tableHead: {
        backgroundColor: '#1F1F1F',
        borderBottom:'none'
    },
    tableHeadCell: {
        // color: '#414141',
        borderBottom:'1px solid #272727',

    },
    tableBodyRow: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            cursor: 'pointer!important',

        },
    },
    tableBodyRowSelected: {borderBottom:'3px solid #54fcef'},
}))

export default useStyles