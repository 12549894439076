import React, {useState} from 'react';
import {Button, Modal, Typography} from "@mui/material";
import ErrorBoundary from "../../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import Viewer3DContainer from "../../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {StyledExcludePartsModal} from "./StyledExcludePartsModal";
import {selectAllParts} from "../../../redux/reducers/statusReducer/actions";


function ExcludePartsModal({open, handleClose, selectedModel, parts_to_skip}) {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const {viewerSettings} = useSelector((state) => state.cachedReducer)
	const [all_selected, setAll_selected] = useState(false)
	const select_all = () => {
		let all_parts_ids = []
		if(!all_selected){
			Object.keys(selectedModel.parts).forEach((part_id) => {
				if(part_id != selectedModel.beam.ID){
					all_parts_ids.push(part_id)
				}
			})
		}
		setAll_selected(!all_selected)
		dispatch(selectAllParts(all_parts_ids))
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledExcludePartsModal>
				<div id="modal-modal-description">
					<div style={{borderBottom: 'solid 1px black', height: '40px'}}>
						{parts_to_skip && parts_to_skip.length > 0 &&
							<table style={{width: '90%', position: 'relative', zIndex: '10'}}>
								<tbody>
									<tr>
										<td style={{width: '115px'}}>{t('excluded_parts')}:</td>
										<td><div style={{padding: '5px 15px', wordBreak: 'break-all'}}>
											{parts_to_skip.map((item, index) => {
												return <span key={index} style={{padding: '0px 5px'}}>{item}</span>
											})}
										</div></td>
									</tr>
								</tbody>
							</table>
						}
						<Button variant={'contained'} color={'secondary'} onClick={() => {
							handleClose()
						}} className={"close_btn"}>{t('close')}</Button>
					</div>
					<div style={{height: '74vh'}}>
						<ErrorBoundary message={t('unexpected_error')}>
							<Viewer3DContainer selectedModel={selectedModel} skip_parts={true}/>
						</ErrorBoundary>
					</div>
					<div className={"parts_legend"}>
						<div className={"parts_box"} style={{background: viewerSettings.missedParts.color}}></div>
						- {t('skip_parts')}<br/>
						<Button variant={'contained'} color={'secondary'} onClick={() => {
							select_all()
						}} className={"select_btn"}>{t('exclude_all')}</Button>
					</div>
				</div>
			</StyledExcludePartsModal>
		</Modal>
	);
}

export default ExcludePartsModal;
