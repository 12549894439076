import {LoadingManager, Matrix4} from "three";

import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

const manager = new LoadingManager()
const loaderObj = new OBJLoader(manager);


function multiplyMatrices(m1, m2) {
	let result = [];
	for (let i = 0; i < m1.length; i++) {
		result[i] = [];
		for (let j = 0; j < m2[0].length; j++) {
			let sum = 0;
			for (let k = 0; k < m1[0].length; k++) {
				sum += m1[i][k] * m2[k][j];
			}
			result[i][j] = sum;
		}
	}
	return result;
}

const getGeom = (item) => {
	const [mesh] = loaderObj.parse(item).children
	let geom = null;
	mesh.traverse((item) => {
		if (item.type === "Mesh") {
			geom = item.geometry;
		}
	});
	return geom
}


const getTransformationMatrix = ({value, beamTransform}) => {
	const {transformMatrix} = value

	if (transformMatrix) {
		const {
			'00': x00,
			'01': x01,
			'02': x02,
			'10': x10,
			'11': x11,
			'12': x12,
			'20': x20,
			'21': x21,
			'22': x22,
			'30': x30,
			'31': x31,
			'32': x32
		} = transformMatrix

		const matrix = new Matrix4()
		if (beamTransform) {
			const multiplied = multiplyMatrices(
				beamTransform,
				[[x00, x10, x20, x30], [x01, x11, x21, x31], [x02, x12, x22, x32], [0, 0, 0, 1]]
			)
			const [[n11, n12, n13, n14], [n21, n22, n23, n24], [n31, n32, n33, n34], [n41, n42, n43, n44]] = multiplied
			matrix.set(
				n11, n12, n13, n14 / 1000, n21, n22, n23, n24 / 1000, n31, n32, n33, n34 / 1000, n41, n42, n43, n44)

		} else {
			matrix.set(x00, x10, x20, x30 / 1000, x01, x11, x21, x31 / 1000, x02, x12, x22, x32 / 1000, 0, 0, 0, 1)
		}


		return matrix
	} else {
		return null;
	}
}

export const getMatrix = (beamTransform) => {
	let mainMatrix;
	beamTransform = beamTransform ? beamTransform : []
	if (beamTransform.length) {
		const [[n11, n12, n13, n14], [n21, n22, n23, n24], [n31, n32, n33, n34]] = beamTransform
		mainMatrix = new Matrix4()
		mainMatrix.set(n11, n12, n13, n14 / 1000, n21, n22, n23, n24 / 1000, n31, n32, n33, n34 / 1000, 0, 0, 0, 1)

	}

	return mainMatrix
}


export const meshesParser = (selectedObject, missedPartsList = [], onlyBeam = false, beamTransform = undefined) => {

	let rawData, parts;
	missedPartsList = missedPartsList ? missedPartsList : []
	if (!selectedObject) {
		return []
	}

	if (!onlyBeam) {
		parts = selectedObject.parts
	} else {
		const beamId = selectedObject?.beam?.ID
		if (beamId) {
			parts = {[beamId]: selectedObject.parts[selectedObject?.beam?.ID]}
		} else {
			parts = {}
		}

	}

	try {
		rawData = Object.entries(parts).map(([key, value]) => {
			return {
				geometry: value['obj'].body,
				type: missedPartsList.includes(key) ? 'missedPart' : 'mainPart',
				matrix: getTransformationMatrix(value, beamTransform)
			}
		}).map((item) => {


			return {...item, geometry: getGeom(item.geometry,)}
		});
	} catch (e) {
		rawData = []
	}
	return rawData

}


export const mainGeometryParser = ({
									   selectedModel,
									   missedPartsList,
									   onlyBeam = false,
									   beamTransform = false,
									   parts_to_skip = []
								   }) => {

	let rawData, parts;
	missedPartsList = missedPartsList ? missedPartsList : []

	if (!selectedModel) {
		return []
	}

	const beamId = selectedModel?.beam?.ID
	if (!onlyBeam) {
		parts = selectedModel.parts
	} else {
		if (beamId) {
			parts = {[beamId]: selectedModel.parts[selectedModel?.beam?.ID]}
		} else {
			parts = {}
		}
	}

	try {
		rawData = Object.entries(parts).map(([key, value]) => {
			return {
				geometry: value['obj'].body,
				type: missedPartsList.includes(key) || (parts_to_skip && parts_to_skip.includes(key)) ? 'missedPart' : 'mainPart',
				matrix: getTransformationMatrix({value, beamTransform}),
				isBeam: parseInt(key) === beamId,
				id: key,
			}
		}).map((item) => {
			return {...item, geometry: getGeom(item.geometry,)}
		});
	} catch (e) {
		console.log("mainGeometryParser")
		console.log(e)
		rawData = []
	}
	return rawData
}

export const partGeometryParser = ({model, id}) => {

	let rawData;
	let value = model

	if (!value) {
		return {}
	}

	try {
		let beam_trans = false
		rawData = {
			geometry: getGeom(value['obj'].body),
			type: 'mainPart',
			matrix: getTransformationMatrix({value, beam_trans}),
			isBeam: false,
			id: id,
		}
	} catch (e) {
		console.log("partGeometryParser")
		console.log(e)
		rawData = {}
	}
	return rawData
}


export const meshesParserRevert = (selectedModel, scannedParts) => {
	if (!selectedModel || !scannedParts) {
		return []
	}
	let rawData;
	const scannedKeys = Object.keys(scannedParts)
	try {

		rawData = Object.entries(selectedModel.parts).reduce((acc, [key, value]) => {

			if (scannedKeys.includes(key)) {

				let generalMatrix, xMatrix;

				// // if (scannedParts[key].matched) {need_to_revert
				const [[n11, n12, n13, n14], [n21, n22, n23, n24], [n31, n32, n33, n34], [n41, n42, n43, n44]] = scannedParts[key].transformation

				generalMatrix = new Matrix4()
				generalMatrix.set(
					n11, n12, n13, n14 / 1000, n21, n22, n23, n24 / 1000, n31, n32, n33, n34 / 1000, n41, n42, n43, n44)
				xMatrix = new Matrix4()
				xMatrix.set(1, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1);
				// // }
				acc.push({
							 part_id: key,
							 geometry: value['obj'].body,
							 color: scannedParts[key].matched ? scannedParts[key].need_to_revert ? 'red' : 'green'
															  : 'grey',
							 generalMatrix,
							 xMatrix
						 })
			}
			return acc
		}, []).map((item) => {
			return {...item, geometry: getGeom(item.geometry)}
		});
	} catch (e) {
		rawData = []
	}
	return rawData
}

export const beamApproximatedAttributes = ({beam}) => {
	if (beam) {
		const {length, height} = beam
		const frontZoom = -0.0005144186046511628 * length + 5.166511627906977 - height / 1000
		const sideZoom = -0.0008 * height + 4.9
		return {offset: length / 2000, sideZoom, frontZoom}
	} else {
		return {offset: 0, sideZoom: 0, frontZoom: 0}
	}

}

export const normalizeBeam = (beamTransform) => {
	if (beamTransform) {
		const [[n11, n12, n13], [n21, n22, n23], [n31, n32, n33], [n41, n42, n43]] = beamTransform
		return [[n11, n12, n13, 0], [n21, n22, n23, 0], [n31, n32, n33, 0], [n41, n42, n43, 0]]

	} else {
		return beamTransform
	}
}
