import React from 'react';
import StatusStartSettings from "../../StatusStartSettings/StatusStartSettings";


function MountingComponent() {

    return <div style={{height: '100%'}}>
        <StatusStartSettings/>
    </div>
}

export default MountingComponent;
