import styled from 'styled-components';

export const StyledGeneralTable = styled.div`
	.header_cells{
	  background-color: #1F1F1F;
	  border-bottom: none;
	}
	.MuiIconButton-root{
	  padding: 0;
	}
	.MuiTableCell-root{
	  padding: 10px;
	}
	.operator_cell{
		padding-right: 20px;
	}
	.search_icon{
			position: absolute;
			color: black;
			right: 20%;
	}
	.search_input{
		height: 20px;
		margin-left: 5px;
		width: 80%;
	}
`;
