import {useSelector} from "react-redux";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import {
	IDLE,
	INSPECTION_PARTS_CONFIRMED,
	INSPECTION_PARTS_ERROR,
	INSPECTION_PARTS_IS_RUNNING,
	INSPECTION_PARTS_SUCCESS,
	INSPECTION_PARTS_TIMEOUT_ERROR,
} from "../statusConstants";
import React from "react";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import PartsViewer from "../../../pages/PartsViewer/PartsViewer";
import StatusInfo from "../GeneralComponents/StatusInfro/StatusInfo";


const InspectionPartsComponent = ({screenVersion = false, isMobile}) => {
	const {inspectionPartsStatus} = useSelector(state => state.statusReducer)

	const statusRender = (status) => {
		switch (status) {
			case IDLE:
			case INSPECTION_PARTS_CONFIRMED:
				return <StatusInfo status={status} isMobile={isMobile}/>
			case INSPECTION_PARTS_IS_RUNNING:
				return !screenVersion ? <ProcessAction status={status}/> :
							 <ProcessingActionScreen status={status}/>
			case INSPECTION_PARTS_SUCCESS:
			case INSPECTION_PARTS_ERROR:
				return <PartsViewer/>
			case INSPECTION_PARTS_TIMEOUT_ERROR:
				return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
			default:
				return <UnknownStatus isMobile={isMobile}/>
		}
	}
	return statusRender(inspectionPartsStatus)
};

export default InspectionPartsComponent;
