import {
    GET_ARCHIVE_MODEL_FAIL,
    GET_ARCHIVE_MODEL_SUCCESS,
    GET_ARCHIVE_TASKS_REQUEST,
    GET_ARCHIVE_TASKS_SUCCESS,
    GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_EXCEL, GET_GRAPH_EXCEL_SUCCESS,
    SELECT_ARCHIVED_TASK, SET_ARCHIVE_MODELS_MISSED_PART, UNSELECT_ARCHIVE_TASK,
} from "./types";

export const getArchivedTasksListRequest = (t, filter) => ({
    type: GET_ARCHIVE_TASKS_REQUEST, payload: t, filter
})

export const getArchiveTasksListSuccess = (tasks, total_pages) => ({
    type: GET_ARCHIVE_TASKS_SUCCESS, tasks, total_pages
})

export const selectArchivedTask = (id, data) => ({
    type: SELECT_ARCHIVED_TASK, payload: data, id: id
})
export const unselectArchiveTask = () => ({
    type: UNSELECT_ARCHIVE_TASK
})
export const getArchiveModelSuccess = (data) => ({type: GET_ARCHIVE_MODEL_SUCCESS, payload: data})
export const getArchiveModelFail = () => ({type: GET_ARCHIVE_MODEL_FAIL})
export const setMissedPartsArchiveModel = (list) => ({
    type: SET_ARCHIVE_MODELS_MISSED_PART, payload: list
})


export const getGraphDataRequest = (t, dates, operator) => ({
    type: GET_GRAPH_DATA, t, dates, operator
})
export const getGraphDataApiRequestSuccess = (data, maxValue, dates, weights, total_weight) => ({
    type: GET_GRAPH_DATA_SUCCESS, payload: data, maxValue, dates, weights, total_weight
})
export const getGraphExcelRequest = (dates, operator) => ({
    type: GET_GRAPH_EXCEL, dates, operator
})
export const getGraphExcelRequestSuccess = () => ({
    type: GET_GRAPH_EXCEL_SUCCESS
})
