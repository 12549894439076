import {
    CALCULATE_INITIAL_TASK_DATA,
    DEINITIALIZE_STATUS_PAGE,
    CANCEL_PROCESSING_TASK_FAIL,
    CANCEL_PROCESSING_TASK_REQUEST,
    CANCEL_PROCESSING_TASK_SUCCESS,
    INITIALIZE_STATUS_PAGE,
    RESET_PARTS_COUNTER,
    SET_ASSEMBLED_PARTS,
    SET_EXECUTOR_STATUS,
    SET_INITIAL_TASK_DATA,
    SET_LABELING_STATUS,
    SET_PLANNED_PARTS,
    SET_PLANNER_STATUS,
    SET_SCANNING_BEAM_STATUS,
    SET_SCANNING_PARTS_STATUS,
    SET_STATUSES,
    SET_TABLE_LENGTH,
    SET_TASK_STATUS,
    DELETE_SKIP_PART,
    ADD_SKIP_PART,
    SELECT_ALL_PARTS,
    SET_CHECKED,
    RESET_CHECKS,
    SET_ACTIVE_TABLE,
    SET_TABLE_OK, SET_TABLE_NUMBER, SET_PART_STATUS
} from "./types";
import {GETTING_INITIAL_INFORMATION, IDLE,} from "../../../components/StatusesComponets/statusConstants";


const modelInitialData = {
    statusPageWasInitialized: false,
    assemblyName: null,
    selectedModel: null,
    scannedParts: null,
    beamPosition: null,
    beamTransform: null,
    partsToReverse: null,
    tableLength: null,
    rackParts: {
        holder3_is_used: true,
        beam_offset: 0,
        holder1_position: 0,
        holder2_position: 0,
        holder3_position: 0,
        holder1_offset: 0,
        holder2_offset: 0,
        holder3_offset: 0
    },
}


const initialState = {
    calculatingInitialData: false,
    ...modelInitialData,

    taskStatus: GETTING_INITIAL_INFORMATION,
    beam_action: IDLE,
    parts_action: IDLE,
    scanningBeamStatus: IDLE,
    matchingBeamStatus: IDLE,
    scanningPartsStatus: IDLE,
    matchingPartsStatus: IDLE,
    labelingStatus: IDLE,
    executorStatus: IDLE,
    plannerStatus: IDLE,

    processingTaskIsCanceling: false,
    processingTaskCancelingErrorOccurred: false,

    assembled_parts: 0,
    planned_parts: 0,
    all_parts: 0,
    parts_to_skip: [],
    racksAreEstablished: false,
    beamIsEstablished: false,
    detailsAreEstablished: false,
    table_checks: {1: false, 2: false, 3: false, 4: false, 5: false, 6:false},
    beamLengthConfirm: false,
    tableIsOk: true,
    tableNumber: 0,
    parts_statuses: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATUSES:
            return {
                ...state, ...action.payload.statuses,
            }
        case INITIALIZE_STATUS_PAGE:
            return {...state, statusPageWasInitialized: true}
        case DEINITIALIZE_STATUS_PAGE:
            return {...state, statusPageWasInitialized: false}
        case CALCULATE_INITIAL_TASK_DATA:
            return {...state, calculatingInitialData: true}
        case SET_INITIAL_TASK_DATA:
            const {
                beam_stand,
                beamPosition,
                plannerStatus,
                labelStatus,
                executorStatus,
                beamTransform,
                taskId,
                assemblyName,
                selectedModel,
                scannedParts,
                rackParts,
                inspection,
                parts_statuses,
                parts_to_skip
            } = action.payload
            return {
                ...state,
                calculatingInitialData: false,
                beam_stand,
                beamPosition,
                beamTransform,
                plannerStatus,
                labelStatus,
                executorStatus,
                taskId,
                assemblyName,
                selectedModel,
                scannedParts,
                rackParts,
                inspection,
                parts_statuses,
                parts_to_skip
            }

        case SET_TASK_STATUS:
            return {...state, taskStatus: action.payload}

        case SET_TABLE_LENGTH:
            return {...state, tableLength: action.payload}

        case CANCEL_PROCESSING_TASK_REQUEST:
            return {...state, ...modelInitialData, processingTaskIsCanceling: true}
        case CANCEL_PROCESSING_TASK_SUCCESS:
            return {...state, processingTaskIsCanceling: false}
        case CANCEL_PROCESSING_TASK_FAIL:
            return {...state, processingTaskIsCanceling: false, processingTaskCancelingErrorOccurred: true}
        case SET_LABELING_STATUS:
            return {...state, labelingStatus: action.payload}
        case SET_EXECUTOR_STATUS:
            return {...state, executorStatus: action.payload}
        case SET_PLANNER_STATUS:
            return {...state, plannerStatus: action.payload}
        case SET_SCANNING_BEAM_STATUS:
            return {...state, scanningBeamStatus: action.payload}
        case SET_SCANNING_PARTS_STATUS:
            return {...state, scanningPartsStatus: action.payload}
        case SET_ASSEMBLED_PARTS:
            return {...state, assembled_parts: action.payload.assembled, all_parts: action.payload.all}
        case SET_PLANNED_PARTS:
            return {...state, planned_parts: action.payload.planned, all_parts: action.payload.all}
        case RESET_PARTS_COUNTER:
            return {...state, planned_parts: 0, all_parts: 0, assembled_parts: 0}
        case ADD_SKIP_PART:
            if(!Array.isArray(state.parts_to_skip)){
                return {...state, parts_to_skip: [action.payload]}
            }
            if(state.parts_to_skip.includes(action.payload)){
                return state
            }
            return {...state, parts_to_skip: [...state.parts_to_skip, action.payload]}
        case DELETE_SKIP_PART:
            return {...state, parts_to_skip: state.parts_to_skip.filter(item => item !== action.payload)}
        case SELECT_ALL_PARTS:
            return {...state, parts_to_skip: action.payload}
        case SET_CHECKED:
            return {...state, [action.name]: action.checked}
        case RESET_CHECKS:
            return {...state, racksAreEstablished: false, beamIsEstablished: false, detailsAreEstablished: false,
            parts_to_skip: [], table_checks: {1: false, 2: false, 3: false, 4: false, 5: false, 6:false},
            tableIsOk: true, tableNumber: 0, beamLengthConfirm: false}
        case SET_ACTIVE_TABLE:
            return {...state, table_checks: {...state.table_checks, [action.name]: action.checked}}
        case SET_TABLE_OK:
            return {...state, tableIsOk: action.payload}
        case SET_TABLE_NUMBER:
            return {...state, tableNumber: action.payload}
        case SET_PART_STATUS:
            return {...state, parts_statuses: state.parts_statuses.map(
                  (item) => item.id === action.payload.part_id ? {...item, status: action.payload.status}
                                          : item
                )}
        default:
            return state
    }
}

export default reducer
