import React from 'react';


function SingleModelPartStatic({geometry,scale}) {



    const getMesh = (mesh) => {
        if (!mesh || mesh._matrixIsApplied) return
        mesh.applyMatrix4(geometry.matrix)
        mesh._matrixIsApplied = true
    }


    return (
        <mesh
            ref={getMesh}
            position={geometry.position}
            geometry={geometry.geometry} scale={scale}>
            <meshBasicMaterial
                attach="material"
                color={'#2a8a78'}
                opacity={0.5}
            />
        </mesh>
    );
}

export default SingleModelPartStatic;
