import {call, put, select, takeLatest} from 'redux-saga/effects'
import {GET_OPERATORS, LOGIN_REQUEST, SAVE_ACTION, SEND_LOGS_REQUEST} from "./types";
import {getOperatorsRequest, loginUserRequest, saveActionRequest, sendLogsApi} from "./requests";
import {
    getOperatorsSuccess,
    sendLogsFail,
    sendLogsSuccess,
    userLoginFail,
    userLoginSuccess
} from "./actions";
import {sendGeneralMessage} from "../layoutReducer/actions";
import i18n from "../../../i18n";


function* userLoginSaga(action) {
    try {
        const response = yield call(loginUserRequest, action.payload)
        yield put(userLoginSuccess(response.data))
        action.ws.emit('set_username', response.data.username)
    } catch (err) {
        const {response} = err
        let message = response && response.status === 401 ? response.data.detail : i18n.t('SOCKET_CONNECTION_ERROR')
        yield put(userLoginFail())
        yield put(sendGeneralMessage(message, 'error'))
    }
}

function* sendLogsSaga(action) {
    try {
        const res = yield call(sendLogsApi, action.task_id)
        if(res){
            yield put(sendGeneralMessage('Email sent successfully', 'success'))
        }else{
            yield put(sendGeneralMessage('Could not send email', 'error'))
        }
        yield put(sendLogsSuccess())
    } catch (err) {
        const {response} = err
        let message = response && response.status === 401 ? response.data.detail : i18n.t('SOCKET_CONNECTION_ERROR')
        yield put(sendLogsFail())
        yield put(sendGeneralMessage(message, 'error'))
    }
}

function* getOperators(action){
    try {
        const response = yield call(getOperatorsRequest)
        yield put(getOperatorsSuccess(response.data))
    } catch (err) {
        yield put(sendGeneralMessage('get operators list failed', 'error'))
    }
}

function* saveAction(action){
    try {
        const {username} = yield select((state) => state.cachedReducer)
        yield call(saveActionRequest, action.task_id, action.action_name, username)
    } catch (err) {
        console.log(err)
    }
}


export default function* userSaga() {
    yield takeLatest(LOGIN_REQUEST, userLoginSaga)
    yield takeLatest(GET_OPERATORS, getOperators)
    yield takeLatest(SEND_LOGS_REQUEST, sendLogsSaga)
    yield takeLatest(SAVE_ACTION, saveAction)
}
