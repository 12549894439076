import React, {useState} from 'react';
import {createColor, ColorPicker} from "mui-color";
import {useDispatch, useSelector} from "react-redux";
import {setViewerConstants} from "../../../redux/reducers/cachedReducer/actions";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({

    inputText: {
        color: "white !important"
    },
    selectStyle: {
        "&.css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderColor: "red !important",
            border: '2px solid !important'
        },
    },

})

function ColorPickerModal({entry, type}) {
    const classes = useStyles()
    const [key] = entry
    const {viewerSettings} = useSelector((state) => state.cachedReducer)
    const dispatch = useDispatch()
    const [color, setColor] = useState(createColor(viewerSettings[type][key]));

    const handleChange = (newValue) => {
        dispatch(setViewerConstants({
            ...viewerSettings,
            [type]: {...viewerSettings[type], [key]: `#${newValue.hex}`}
        }))
        setColor(newValue);
    };

    return <ColorPicker value={color} className={classes.selectStyle} onChange={handleChange} />
}

export default ColorPickerModal;