import React from 'react';
import {makeStyles} from "@mui/styles";
import {Select} from "@mui/material";

const useStyles = makeStyles({

    inputText: {
        color: "white !important"
    },
    icon: {
        fill: 'white',
    },

})

function StyledSelect({children, ...props}) {
    const classes = useStyles()
    return (
        <Select {...props}
                className={classes.selectStyle}
                inputProps={{
                    className: classes.inputText,
                    classes: {
                        icon: classes.icon,
                    },
                }}
                variant={'outlined'}
                color={'secondary'}
        >
            {children}
        </Select>
    );
}

export default StyledSelect;