import {
	GET_OPERATORS,
	GET_OPERATORS_SUCCESS,
	LOGIN_FAIL,
	LOGIN_REQUEST,
	LOGIN_SUCCESS, SAVE_ACTION, SEND_LOGS_FAIL,
	SEND_LOGS_REQUEST, SEND_LOGS_SUCCESS
} from "./types";

const queryString = require('query-string');

export const userLoginRequest = (credentials, ws) => ({type: LOGIN_REQUEST, payload: queryString.stringify(credentials), ws})
export const userLoginSuccess = (data) => ({type: LOGIN_SUCCESS, data})
export const userLoginFail = () => ({type: LOGIN_FAIL})

export const getOperators = () => ({type: GET_OPERATORS})
export const getOperatorsSuccess = (data) => ({type: GET_OPERATORS_SUCCESS, payload: data})

export const sendLogsRequest = (task_id) => ({type: SEND_LOGS_REQUEST, task_id})
export const sendLogsSuccess = () => ({type: SEND_LOGS_SUCCESS})
export const sendLogsFail = () => ({type: SEND_LOGS_FAIL})

export const saveAction = (task_id, action_name) => ({
	type: SAVE_ACTION, task_id, action_name
})