import React, {useContext, useEffect} from 'react';
import {EXECUTOR_SUCCESS, LABELING_AND_PLANNING_ACTION,} from "../statusConstants";
import PlannerComponent from "./PlannerComponent/PlannerComponent";
import ExecutorComponent from "./ExecutorComponent/ExecutorComponent";
import LabelingComponent from "./LabelingComponent/LabelingComponent";
import {SocketContext} from "../../../redux/customReducer/SocketContext";
import {StyledExecutorComponent} from "./ExecutorComponent/StyledExecutorComponent";
import {Button, Modal, Typography} from "@mui/material";
import check_role from "../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {checkNeedTurn} from "../../../redux/reducers/statusReducer/actions";
import {useTranslation} from "react-i18next";


const ExecutorPlannerLabeling = ({status, screenVersion, isMobile}) => {
  const {state: {ws}} = useContext(SocketContext)
	const dispatch = useDispatch()
	const {t} = useTranslation()
	const {executorStatus} = useSelector(state => state.statusReducer)
  const {username} = useSelector((state) => state.cachedReducer)
	const {taskId, assemblyName} = useSelector(state => state.statusReducer)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

	useEffect(() =>{
		if(executorStatus === EXECUTOR_SUCCESS){
			dispatch(checkNeedTurn(taskId, handleOpen))
		}
	}, [executorStatus])

  useEffect(() => {
    ws.emit('request_assembly_counters')
  }, [])

  return (
    <div style={isMobile? {height: '100%'} : {position: 'relative', height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '50% 50%'}}>
      <div style={{height: isMobile ? '50%' : '100%'}}>
        <PlannerComponent screenVersion={screenVersion} isMobile={isMobile}/>
      </div>
      <div style={{height: isMobile ? '50%' : '100%', borderLeft: '2px solid #141414'}}>
        {status === LABELING_AND_PLANNING_ACTION ? <LabelingComponent screenVersion={screenVersion}/> :
         <ExecutorComponent screenVersion={screenVersion} isMobile={isMobile} executorStatus={executorStatus}/>
        }
      </div>

			{open && <StyledExecutorComponent>
				<Typography id="modal-modal-description" sx={{mt: 2}}>
					<b>{t('assembly_ended_modal_text')}:</b>
				</Typography>
				{check_role(username) &&
				 <>
					 <Button className={"success_btn"}
									 onClick={() => {
										 ws.emit('successfully_end_assembly', taskId, assemblyName)
									 }}>
						 <b>{t('succ_end_but')}</b></Button>
					 <br/>
					 <Button className={"unsuccess_btn"}
									 onClick={() => {
										 ws.emit('send_task_to_archive', {task_id: taskId, action: 'ARCHIVE('+t('cancel_end_but').toUpperCase()+')'})
									 }}>
						 <b>{t('cancel_end_but')}</b></Button>
				 </>
				}
			</StyledExecutorComponent>}
    </div>
  );
};

export default ExecutorPlannerLabeling;
