import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import {useTranslation} from "react-i18next";
import {log} from "three/nodes";
import SinglePartViewer from "../../../components/ThreeJsComponents/SinglePartViewer/SinglePartViewer";

function Parts({simulation, model}){
	const {t} = useTranslation();

	return <TableContainer component={Paper} style={{overflow: 'scroll', height: '100%'}}>
		<Table sx={{ minWidth: 250 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell align="left"><b>{t('Position')}</b></TableCell>
					<TableCell align="left"><b>{t('Quantity')}</b></TableCell>
					<TableCell align="left"><b>{t('3DView')}</b></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{simulation && simulation['parts'] && Object.entries(simulation['parts']).map(([k,v]) =>
				  <TableRow
					  key={k}
					  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
				  >
					  <TableCell align="left">{k}</TableCell>
					  <TableCell align="left">{v.count}</TableCell>
						<TableCell align="left" style={{padding: 0, minHeight: '250px', border: '1px solid black'}}><SinglePartViewer model={model.parts[v.id]} id={v.id}/></TableCell>
				  </TableRow>
				)}
			</TableBody>
		</Table>
	</TableContainer>
}
export default Parts;