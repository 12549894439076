import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {StyledTaskInfo} from "./StyledTaskInfo";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Button, CircularProgress} from "@mui/material";
import SkippedParts from "./SkippedParts/SkippedParts";
import {sendLogsRequest} from "../../../redux/reducers/userReducer/actions";

function TaskInfo() {
  const {t} = useTranslation()
  const {taskId} = useSelector((state) => state.statusReducer)
  const {sendLogsLoading} = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const CopyToClipboard = () => {
    navigator.clipboard.writeText(taskId)
    setVisible(true)
    setTimeout(() =>{
      setVisible(false)
    }, 1000)
  }

  return (
    <StyledTaskInfo>
      <Box style={{
        backgroundColor: '#141414',
        color: 'white',
        height: 'calc(100% - 96px)',
        padding: '48px 24px 48px 24px'
      }}>
        <div className="tooltip" onClick={() => CopyToClipboard()}
             style={{cursor: 'pointer', width: 'max-content'}}>
          <span className={visible ? 'tooltiptextvisible' : 'tooltiptext'}>{t('CopyToClipboard')}</span>
          Task ID: {taskId}
          <ContentCopyIcon sx={{verticalAlign: "middle", marginLeft: "10px"}}/>
        </div>
        <hr/>
        <div>
          <Button variant={'contained'} color={'secondary'} onClick={() => handleOpen()}
                  style={{marginTop: '10px'}}>{t('ShowSkippedParts')}</Button>
        </div>
        <hr/>
        <div style={{padding: '10px 0px'}}>
          {t('SendLogsMsg')}: {sendLogsLoading ?
                               <CircularProgress color="secondary" size="20px" style={{padding: '0px 30px'}}/>
                                               :
                               <Button variant="contained" color="secondary" component="label"
                                  onClick={() => {
                                    dispatch(sendLogsRequest(taskId))
                                  }}>{t('send_logs')}</Button>}
        </div>
        <SkippedParts open={open} handleClose={handleClose}/>
      </Box>
    </StyledTaskInfo>
  );
}

export default TaskInfo;
