import React, {useEffect} from 'react';
import StatusesComponents from "../../components/StatusesComponets/StatusesComponents";
import StatusSocket from "../../components/StatusesComponets/StatusSocket/StatusSocket";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import Box from "@mui/material/Box";
import {Grid, Tab, Tabs} from "@mui/material";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import {useDispatch, useSelector} from "react-redux";
import PdfScheme from "./PdfScheme";
import Rack from "../../components/Rack/Rack";
import {turnOnViewerLoading} from "../../redux/reducers/modelsReducer/actions";
import TaskInfo from "./TaskInfo/TaskInfo";
import MatchingResults from "./MatchingResults/MatchingResults";
import {StyledStatusMainPage} from "./StyledStatusMainPage";
import Debug from "./Debug/Debug";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: 'calc(100% - 54px)' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function StatusMainPage({isMobile}) {
  const {t} = useTranslation()
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch()
  const {selectedModel, beamTransform} = useSelector((state) => state.statusReducer)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    dispatch(turnOnViewerLoading())
  }, [])

  return (
    <StyledStatusMainPage>
      <ErrorBoundary message={t('unexpected_error')}>
        <StatusSocket/>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'white', height: '50px'}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:"#141414"}}>
              <Tab label={t('status')} {...a11yProps(0)} style={value === 0 ? {backgroundColor: '#404040', color:"white"} : {color:"white"}}/>
              <Tab label={t('general_view')} {...a11yProps(1)} style={value === 1 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
              <Tab label={t('virtual_view')} {...a11yProps(2)} style={value === 2 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
              <Tab label={t('scheme')} {...a11yProps(3)} style={value === 3 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
              <Tab label={t('task_info')} {...a11yProps(4)} style={value === 4 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
              <Tab label={t('matching_result')} {...a11yProps(5)} style={value === 5 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
              <Tab label={t('debug')} {...a11yProps(6)} style={value === 6 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} style={{height: 'calc(100% - 51px)'}}>
            <StatusesComponents isMobile={isMobile}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ErrorBoundary message={t('unexpected_error')}>
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: '100%', width: '100%'}}>
                <div style={{height: "100%", width: "100%"}}>
                  <Rack/>
                </div>
              </div>
            </ErrorBoundary>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container style={isMobile ? {width: '100%', height: '100%', display: 'block'}
              : {width: '100%', height: '100%', margin: '-1px', paddingLeft: '2px'}} spacing={isMobile ? 0 : 3}>
              <Grid item xs={12} sm={12} style={isMobile ? {height: '54%', marginTop: '5px'} : {height: '100%', backgroundColor: '#141414'}}>
                <ErrorBoundary message={t('unexpected_error')} style={{padding: '0px'}}>
                  <Viewer3DContainer selectedModel={selectedModel} beamTransform={beamTransform} isMobile={isMobile} page={"status_page"}/>
                </ErrorBoundary>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PdfScheme selectedModel={selectedModel}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <TaskInfo/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <MatchingResults/>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Debug/>
          </TabPanel>
        </Box>
      </ErrorBoundary>
    </StyledStatusMainPage>
  );
}

export default StatusMainPage;
