import styled from 'styled-components';

export const StyledExcludePartsModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 2px;
  .parts_legend{
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #a6a6a5;
    padding: 20px 10px;
    text-align: center;
  }
  .parts_box{
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .close_btn{
    right: 10px;
    position: absolute;
    height: 20px;
    font-size: small;
    top: 10px;
  }
  .select_btn{
    margin-top: 10px;
  }
`;
