import {ROBOT_STATUSES_ARE_READY, SET_ROBOTS_STATUSES} from "./types";


const initialState = {
    robotStatusIsLoading: true,
    errorOccurred: false,
    status: {
        IRB2600: {Motors: '0', Server: '0', Logger: '0'},
        IRB6700: {Motors: '0', Server: '0', Logger: '0'}
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROBOTS_STATUSES:
            return {...state, status: action.payload}
        case ROBOT_STATUSES_ARE_READY:

            return {...state, robotStatusIsLoading: false}

        default:
            return state;
    }
};

export default reducer;