import React, {useContext, useState} from 'react';
import {Button, Typography} from "@mui/material";
import reverseBulk from '../../../../assets/images/reverseBulk.png'
import {useTranslation} from "react-i18next";
import StyledCheckbox from "../../../StyledInputs/StyledCheckbox";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import {resetPartsCounter, setStatuses} from "../../../../redux/reducers/statusReducer/actions";
import {IDLE_STATUSES} from "../../statusConstants";
import check_role from "../../../../utils/utils";

function RotateBeam({isMobile}) {
    const {t} = useTranslation();
    const [checks, setChecks] = useState({
        beamIsRotated: false,
        beamIsEstablished: false,

    })
    const onCheck = (name, checked) => {
        setChecks({...checks, [name]: checked})
    }
    const labelsCheckbox = ['beamIsRotated', 'beamIsEstablished']
    const {
        state: {ws},
    } = useContext(SocketContext);
    const {taskId: task_id} = useSelector((state) => state.statusReducer)
    const {username} = useSelector((state) => state.cachedReducer)
    const dispatch = useDispatch()

    return (
        <div style={isMobile ? { height: '100%' } :
            {
            height: '100%',
            display: 'grid',
        }}>
            <div style={{
                height: isMobile ? 'fit-content' : '100%',
                minHeight: '280px',
            }}>
                <div style={{
                    backgroundColor: '#141414',
                    borderRadius: '8px',
                    height: 'calc(100% - 48px)', padding: '24px'
                }}>
                    <Typography color={'white'} style={{paddingLeft: '30px'}}> {t('ReverseStep')}:</Typography>
                    <div style={isMobile ? {textAlign: 'center'}: {display: 'inline-block', width: '300px', minHeight: '280px',
                        position: 'relative', verticalAlign: 'middle'}}>
                        <img alt="" src={reverseBulk} style={isMobile ? {height: '220px'}: {height: '220px', position: 'absolute', top: 0, bottom: 0,
                            margin: 'auto', paddingLeft: '30px'}}/>
                    </div>
                    {check_role(username) &&
                      <div style={isMobile ? {textAlign: 'center'} : {
                            display: 'inline-block',
                            width: '50%',
                            verticalAlign: 'middle'
                        }}>
                            <Typography color={'white'} style={{fontSize: '1.1rem'}}>  {t('StatusSettings')}</Typography>
                            {labelsCheckbox.map((item) => {
                                return <div key={item} style={{display: 'flex', alignItems: 'center'}}>
                                    <StyledCheckbox checked={checks[item]}
                                                    onChange={(event, checked) => {
                                                        onCheck(item, checked)
                                                    }}/>
                                    <Typography color={checks[item] ? '#54fcef' : 'white'}
                                                fontSize={'1rem'}>{t(item)}</Typography>
                                </div>
                            })}
                            <Button variant={'contained'} color={'secondary'} style={{marginTop: '10px'}}
                                    onClick={() => {
                                        dispatch(resetPartsCounter())
                                        ws.emit('start_assembling', {'task_id': task_id, 'turn_beam': true})
                                    }}
                                    disabled={!(checks.beamIsEstablished && checks.beamIsRotated)}>{t('ContinueAssembling')}
                            </Button>
                            <br/>
                            <Button variant={'contained'} color={'secondary'}
                                    style={{marginTop: '10px', backgroundColor: 'rgba(255,100,30)'}}
                                    onClick={() => {
                                        dispatch(resetPartsCounter())
                                        ws.emit('postpone_assembly_task', task_id)
                                        dispatch(setStatuses(IDLE_STATUSES))
                                    }}>{t('PostponeAssembling')}
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default RotateBeam;
