const initialState = {
  loading: true,
  files: [],
};

export const scanBeamViewerModule = 'scanBeamViewerModule';
export const LOADING = `${scanBeamViewerModule}/LOADING`;
export const SET_FILE_LIST = `${scanBeamViewerModule}/SET_FILE_LIST`;
export const CLEAR_STATE = `${scanBeamViewerModule}/CLEAR_STATE`;
export const SET_PERCENTAGE = `${scanBeamViewerModule}/SET_PERCENTAGE`
export const LOAD_FILES_LIST = `${scanBeamViewerModule}/LOAD_FILES_LIST`;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.loading}
    case SET_FILE_LIST:
      return {...state, files: action.data}
    case CLEAR_STATE:
      return initialState
    case SET_PERCENTAGE:
      return {...state,
        files: state.files.map(
          (file, i) => {
            return i === action.index ? {...file, percentage: action.percentCompleted} : file
          }
        )
      }
    default:
      return state;
  }
};

export default reducer
