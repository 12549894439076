import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {beamApproximatedAttributes, mainGeometryParser, normalizeBeam} from "../../../utils/three/loader";
import {Typography} from "@mui/material";
import Viewer3DStatic from "../../ThreeJsComponents/StatusViewer/Viewer3DStatic/Viewer3DStatic";
import {useTranslation} from "react-i18next";

const Screen2Mounting = () => {
    const {selectedModel, assemblyName,beamTransform} = useSelector(state => state.statusReducer)
    const geomList = useMemo(() => mainGeometryParser({selectedModel, onlyBeam: true, beamTransform:normalizeBeam(beamTransform)}), [assemblyName])
    const {t} = useTranslation()
    const attributes = beamApproximatedAttributes({beam:selectedModel?.beam})

    return <div style={{height: '100%', width: '100wv', display: 'flex', alignItems: 'center'}}>

        <div style={{width: '33%', height: '50%'}}>
            <Typography color={'white'} fontSize={30}> {t('CameraTopView')} </Typography>
            <Viewer3DStatic view={'top'} geomList={geomList} attributes={attributes}/>
        </div>

        <div style={{width: '33%', height: '50%'}}>
            <Typography color={'white'} fontSize={30}> {t('CameraSideView')} </Typography>
            <Viewer3DStatic view={'side'} geomList={geomList} attributes={attributes}/>
        </div>

        <div style={{width: '33%', height: '50%'}}>
            <Typography color={'white'} fontSize={30}> {t('CameraFrontView')} </Typography>
            <Viewer3DStatic view={'front'} geomList={geomList} attributes={attributes}/>
        </div>
    </div>

};

export default Screen2Mounting;