import '@kitware/vtk.js/favicon';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/OpenGL/Profiles/All';

import macro from '@kitware/vtk.js/macros';
import Base64 from '@kitware/vtk.js/Common/Core/Base64';
import DataAccessHelper from '@kitware/vtk.js/IO/Core/DataAccessHelper';
import HttpDataAccessHelper from '@kitware/vtk.js/IO/Core/DataAccessHelper/HttpDataAccessHelper';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkSynchronizableRenderWindow from '@kitware/vtk.js/Rendering/Misc/SynchronizableRenderWindow';

// Force DataAccessHelper to have access to various data source
import '@kitware/vtk.js/IO/Core/DataAccessHelper/HtmlDataAccessHelper';
import '@kitware/vtk.js/IO/Core/DataAccessHelper/JSZipDataAccessHelper';

let autoInit = true;

function emptyContainer(container) {
  while (container.firstChild) {
    container.removeChild(container.firstChild);
  }
}

export function load(container, options) {
  autoInit = false;
  emptyContainer(container);

  const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
    background: [1, 1, 1],
    rootContainer: container,
    containerStyle: { height: '100%', width: '100%', position: 'absolute' },
  });
  const renderWindow = fullScreenRenderer.getRenderWindow();
  const syncCTX = vtkSynchronizableRenderWindow.getSynchronizerContext();
  const syncRW = vtkSynchronizableRenderWindow.decorate(renderWindow);
  global.renderWindow = renderWindow;

  function onReady(data) {
    syncCTX.setFetchArrayFunction((sha) =>
      Promise.resolve(data.hashes[sha].content)
    );
    console.log(Object.keys(data));
    syncRW.synchronize(data.scene);
    syncRW.render();
  }

  if (options.fileURL || options.url) {
    const progressContainer = document.createElement('div');
    container.appendChild(progressContainer);

    const progressCallback = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const percent = Math.floor(
          (100 * progressEvent.loaded) / progressEvent.total
        );
        progressContainer.innerHTML = `Loading ${percent}%`;
      } else {
        progressContainer.innerHTML = macro.formatBytesToProperUnit(
          progressEvent.loaded
        );
      }
    };

    if (options.fileURL) {
      HttpDataAccessHelper.fetchBinary(options.fileURL, {
        progressCallback,
      }).then((zipContent) => {
        container.removeChild(progressContainer);
        const dataAccessHelper = DataAccessHelper.get('zip', {
          zipContent,
          callback: (zip) => {
            dataAccessHelper.fetchJSON(null, 'index.json').then(onReady);
          },
        });
      });
    } else {
      HttpDataAccessHelper.fetchJSON(options.url, {
        progressCallback,
      }).then((data) => {
        container.removeChild(progressContainer);
        onReady(data);
      });
    }
  } else if (options.file) {
    const dataAccessHelper = DataAccessHelper.get('zip', {
      zipContent: options.file,
      callback: (zip) => {
        dataAccessHelper.fetchJSON(null, 'index.json').then(onReady);
      },
    });
  } else if (options.base64Str) {
    const zipContent = Base64.toArrayBuffer(options.base64Str);
    const dataAccessHelper = DataAccessHelper.get('zip', {
      zipContent,
      callback: (zip) => {
        dataAccessHelper.fetchJSON(null, 'index.json').then(onReady);
      },
    });
  }
}

export function initLocalFileLoader(container, file) {
  load(container, { file: file });
}

window.OfflineLocalView = {
  initLocalFileLoader,
  load,
};
