import React from 'react';
import RackViewer from "../ScanningBeam/RackViewer";
import PartsViewer from "../../PartsViewer/PartsViewer";


function  MatchingResults({isMobile}) {

  return (
    <div style={isMobile? {height: '100%'} : {height: '100%', width: '100%', display: 'flex', flexDirection: "row"}}>
      <div style={{height: isMobile ? '50%' : '100%', width: '50%'}}>
        <div style={{height: '100%'}}>
          <PartsViewer hide_buttons={true}/>
        </div>
      </div>
      <div style={{height: isMobile ? '50%' : '100%', borderLeft: '2px solid white', width: '50%'}}>
        <div style={{height: '100%'}}>
          <RackViewer hide_buttons={true}/>
        </div>
      </div>
    </div>
  )
}

export default MatchingResults;
