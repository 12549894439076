import {authAxios} from "../../../settingsFiles/axiosSettings";
import {SET_BEAM_FILE_PERCENTAGE, SET_PARTS_FILE_PERCENTAGE} from "./reducer";

export const load_beam_file = async (name, task_id, index, dispatch) => {
	return await authAxios.post('/vtkviewer/get_file',{"name": name, "task_id": task_id}, {
		responseType: 'arraybuffer',
		onDownloadProgress: (progressEvent) => {
			let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			dispatch({type: SET_BEAM_FILE_PERCENTAGE, percentCompleted, index});
		},})
}

export const load_parts_file = async (name, task_id, index, dispatch) => {
	return await authAxios.post('/vtkviewer/get_file',{"name": name, "task_id": task_id}, {
		responseType: 'arraybuffer',
		onDownloadProgress: (progressEvent) => {
			let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			dispatch({type: SET_PARTS_FILE_PERCENTAGE, percentCompleted, index});
		},})
}