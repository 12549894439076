import React from 'react';
import {Grid, MenuItem} from "@mui/material";
import {initialViewerTypes} from "../../../utils/initialViewerSettings";
import ModelSettingsInput from "./ModelSettingsInput";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import StyledSelect from "../../StyledInputs/StyledSelect";
import ExampleModel from "./ExampleModel";
import {setPartsType} from "../../../redux/reducers/cachedReducer/actions";


function ModelSettings({isMobile}) {

    const {partsType} = useSelector((state) => state.cachedReducer)


    const dispatch = useDispatch()
    const {t} = useTranslation()

    const handleChange = (event) => {
        dispatch(setPartsType(event.target.value))
    };
    return (
        <Grid container style={{padding: '10px', height: '100%'}}>
            <Grid item xs={12} sm={6} style={{border: 'solid 1px white', padding: '10px', height: '100%'}}>
                <div style={{height: '100%', position: 'relative'}}>
                    <form>
                        <div style={{marginTop: '5px'}}>
                            <span style={{fontSize: '17px', color: 'white', paddingRight: '10px'}}>{t(
                                'partsType')}:</span>
                            <StyledSelect
                                value={partsType}
                                onChange={handleChange}>
                                <MenuItem value={'parts'}>{t('parts')}</MenuItem>
                                <MenuItem value={'missedParts'}>{t('missedParts')}</MenuItem>
                            </StyledSelect>
                            <hr align="left" style={{marginBottom: '25px'}}/>

                            {Object.entries(initialViewerTypes).map((entry) => {
                                return <ModelSettingsInput key={`${partsType}${entry[0]}`} entry={entry}
                                                           type={partsType}/>
                            })}

                        </div>
                    </form>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{border: 'solid 1px white', padding: '5px'}}>
                <div style={{height: '50%'}}>
                    <ExampleModel/>
                </div>
            </Grid>
        </Grid>
    );
}

export default ModelSettings;
