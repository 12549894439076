import styled from 'styled-components';

export const StyledPlanner = styled.div`
  height: 100%;
  width: 100%;
	.planner_body{
      padding: 20px;
			height: calc(100% - 82px);
			width: calc(100% - 40px);
			overflow-y: scroll;
	}
  .parts_table{
    border-collapse: collapse;
    width: 80%;
  }
  .parts_table tr{
    width: 100%;
  }
  .parts_table td, .parts_table th{
    border: 1px solid white;
    padding: 5px 15px;
    height: 30px;
    text-align: center;
  }
  .parts_table td > * {
    vertical-align : middle;
  }
  .part_td{
    padding: 0px!important;
    width: 250px!important;
    background-color: white;
  }
  .part_td_div{
    width: 250px;
  }
`;
