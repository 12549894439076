import {
    GET_ARCHIVE_MODEL_FAIL,
    GET_ARCHIVE_MODEL_SUCCESS,
    GET_ARCHIVE_TASKS_REQUEST,
    GET_ARCHIVE_TASKS_SUCCESS,
    GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_EXCEL, GET_GRAPH_EXCEL_SUCCESS,
    SELECT_ARCHIVED_TASK, SET_ARCHIVE_MODELS_MISSED_PART, UNSELECT_ARCHIVE_TASK

} from "./types";


const initialState = {
    archiveTasksList: [],
    total_pages: 0,
    archiveTasksAreLoading: false,
    selectedTask: null,
    selectedTaskId: null,
    selectedModel: null,
    selectedModelName: null,
    missedPartsList: [],
    archiveModelIsLoading: false,
    archiveModelErrorOccurred: false,

    graphDataLoading: false,
    graphData: [],
    maxValue: 100,
    first_load: true,
    filter_start: '',
    filter_end: '',
    weights: [],
    total_weight: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARCHIVE_TASKS_REQUEST:
            return {...state, archiveTasksAreLoading: true}
        case GET_GRAPH_DATA:
            return {...state, graphDataLoading: true}
        case GET_GRAPH_DATA_SUCCESS:
            return {...state, graphData: action.payload, maxValue: action.maxValue, graphDataLoading: false,
                first_load: false, filter_start: action.dates.start, filter_end: action.dates.end,
                weights: action.weights, total_weight: action.total_weight}
        case GET_GRAPH_EXCEL:
            return {...state, graphExcelLoading: true}
        case GET_GRAPH_EXCEL_SUCCESS:
            return {...state,  graphExcelLoading: false}
        case GET_ARCHIVE_TASKS_SUCCESS:
            return {...state, archiveTasksList: action.tasks, total_pages: action.total_pages, archiveTasksAreLoading: false}
        case SELECT_ARCHIVED_TASK:
            let task = state.archiveTasksList[action.id];
            return {...state, selectedTask: task, selectedTaskId: action.id,
                archiveModelIsLoading: true, selectedModelName: action.payload, selectedModel: null}
        case UNSELECT_ARCHIVE_TASK:
            return {...state, selectedTask: null, selectedTaskId: null, missedPartsList: [],
                archiveModelIsLoading: false, selectedModelName: null, selectedModel: null}
        case SET_ARCHIVE_MODELS_MISSED_PART:
            return {...state, missedPartsList: action.payload}
        case GET_ARCHIVE_MODEL_SUCCESS:
            return {...state, archiveModelIsLoading: false, selectedModel: action.payload}
        case GET_ARCHIVE_MODEL_FAIL:
            return {...state, archiveModelIsLoading: false, selectedModel: null, archiveModelErrorOccurred: true}
        default:
            return state;
    }
};

export default reducer;
