import styled from 'styled-components';

export const StyledTaskView = styled.div`
  height: 100%;
  width: 100%;
  color: white;
  font-family: "Fira sans",sans-serif;
  .header{
    text-align: center;
    width: 100%;
  }
  div{
    margin: 5px 0px;
  }
`;
