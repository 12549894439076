import React, {memo, useEffect, useMemo, useRef} from 'react';
import SingleModelPart from "./SignleModelPart";
import {Plane, Text} from "@react-three/drei";
import {turnOffViewerLoading} from "../../../../../redux/reducers/modelsReducer/actions";
import {mainGeometryParser} from "../../../../../utils/three/loader";
import {AxesHelper} from "three";
import {useSelector} from "react-redux";


function SingleModel({dispatch, viewerSettings, selectedModel, missedPartsList, beamTransform, page, skip_parts}) {

	const {parts_to_skip} = useSelector((state) => state.statusReducer)
	const geomList = useMemo(() => mainGeometryParser({
														  selectedModel,
														  missedPartsList,
														  beamTransform: beamTransform,
														  parts_to_skip: skip_parts ? parts_to_skip : []
													  }), [selectedModel, missedPartsList, parts_to_skip, beamTransform, skip_parts])
	const groupRef = useRef()

	useEffect(() => {
		if (groupRef.current) {
			dispatch?.(turnOffViewerLoading())
		}
	}, [groupRef, geomList])

	return (
		<group ref={groupRef} rotation={[-Math.PI / 2, 0, page === 'status_page' ? -Math.PI / 2 : Math.PI]}>
			{geomList.map((item, index) => <SingleModelPart key={item.type+index} geometry={item}
															viewerSettings={viewerSettings} skip_parts={skip_parts}/>)}
			<Plane
				receiveShadow
				rotation={[0, 0, 0]}
				position={[0, 0, -1.25]}
				args={[1000, 1000]}
			>
				<meshStandardMaterial attach="material" color="white"/>
			</Plane>
			{page === 'status_page' &&
			 <>
				 <mesh position={[0, 0, -0.75]} rotation={[0, 0, 0]}>
					 <boxGeometry args={[1, 0.3, 1]}/>
					 <meshStandardMaterial color={'gray'}/>
				 </mesh>
				 <Text color="red" fontSize={0.5} position={[0.508, 0, -0.5]} rotation={[0, Math.PI / 2, Math.PI / 2]}>
					 1
				 </Text>
				 <primitive object={new AxesHelper(10)}/>
			 </>
			}
		</group>
	);
}

export default memo(SingleModel, (prevProps, nextProps) =>
	prevProps.selectedModel === nextProps.selectedModel
);



