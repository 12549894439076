import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GeneralTable from "../GeneralTable/GeneralTable";
import {getSingleReadyTaskRequest, unselectTask} from "../../../redux/reducers/readyTasksReducer/actions";
import useCreateTableData from "../../LogicalComponents/Hooks/useCreateTableData";

function ReadyTasksTable({isMobile, changeFilter, filter}) {

    const headers = isMobile ? ['CadModelHeader', 'WillBeAssembledHeader','RotationHeader']
      : ['Status', 'CadModelHeader','DownloadTimeHeader', 'WillBeAssembledHeader','RotationHeader']
    const {readyTasksList, readyTasksAreLoading, selectedTaskInd} = useSelector((state => state.readyTasksReducer))
    const {rows}  = useCreateTableData(readyTasksList, isMobile)
    const dispatch = useDispatch()
    const onRowClick = (model_name, ind, count) => {
        if(selectedTaskInd === ind){
            dispatch(unselectTask())
        }else{
            dispatch(getSingleReadyTaskRequest(model_name, ind, count))
        }
    }
    return <GeneralTable headers={headers} rows={rows} onRowClick={onRowClick} isMobile={isMobile}
                         activeItem={selectedTaskInd} tableIsLoading={readyTasksAreLoading}
                         changeFilter={changeFilter} filter={filter}/>
}

export default ReadyTasksTable;
