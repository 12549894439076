import React from 'react';
import {useTranslation} from "react-i18next";
import ScanningBeamComponent from "./ScanningBeamComponent/ScanningBeamComponent";
import ScanningPartsComponent from "./ScanningPartsComponent/ScanningPartsComponent";
import {useSelector} from "react-redux";
import {
  IDLE, INSPECTION_BEAM_ACTION, INSPECTION_PARTS_ACTION,
  MATCHING_BEAM_ACTION,
  MATCHING_PARTS_ACTION,
  SCANNING_BEAM_ACTION, SCANNING_PARTS_ACTION
} from "./statusConstants";
import StatusInfo from "./GeneralComponents/StatusInfro/StatusInfo";
import MatchingBeamComponent from "./ScanningBeamComponent/MatchingBeamComponent";
import MatchingPartsComponent from "./ScanningPartsComponent/MatchingPartsComponent";
import UnknownStatus from "./GeneralComponents/UnknownStatus/UnknownStatus";
import InspectionBeamComponent from "./ScanningBeamComponent/InspectionBeamComponent";
import InspectionPartsComponent from "./ScanningPartsComponent/InspectionPartsComponent";


function Vision({isMobile}) {

  const {t} = useTranslation()
  const {beam_action, parts_action} = useSelector(state => state.statusReducer)
  const renderStatus = (status) => {
    switch (status) {
      case IDLE:
        return <StatusInfo status={status} isMobile={isMobile}/>
      case SCANNING_BEAM_ACTION:
        return <ScanningBeamComponent isMobile={isMobile}/>
      case MATCHING_BEAM_ACTION:
        return <MatchingBeamComponent/>
      case INSPECTION_BEAM_ACTION:
        return <InspectionBeamComponent/>
      case SCANNING_PARTS_ACTION:
        return <ScanningPartsComponent isMobile={isMobile}/>
      case MATCHING_PARTS_ACTION:
        return <MatchingPartsComponent/>
      case INSPECTION_PARTS_ACTION:
        return <InspectionPartsComponent/>
      default:
        return <UnknownStatus isMobile={isMobile}/>
    }
  }

  return (
    <div style={isMobile ? {height: '100%'} : {height: '100%', width: '100%', display: 'flex', flexDirection: "row"}}>
      <div style={{height: isMobile ? '50%' : '100%', width: '50%'}}>
        <div style={{width: '100%', height: '40px', display: 'flex', borderBottom: '1px solid white', justifyContent: 'center', alignItems: 'center'}}>
            <h3 style={{color: 'white'}}>{t(parts_action)}</h3>
        </div>
        <div style={{height: 'calc(100% - 41px)'}}>
          {renderStatus(parts_action)}
        </div>
      </div>
      <div style={{height: isMobile ? '50%' : '100%', borderLeft: '2px solid white', width: '50%'}}>
        <div style={{width: '100%', height: '40px', display: 'flex', borderBottom: '1px solid white', justifyContent: 'center', alignItems: 'center'}}>
          <h3 style={{color: 'white'}}>{t(beam_action)}</h3>
        </div>
        <div style={{height: 'calc(100% - 41px)'}}>
          {renderStatus(beam_action)}
        </div>
      </div>
    </div>
  )
}

export default Vision;
