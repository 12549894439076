import React from 'react';
import {useTranslation} from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {useSelector} from "react-redux";
import check_role from "../../utils/utils";


function useCreateTableData(data, isMobile, dispatch, openDel, openPlan) {
  const {t} = useTranslation();
  const {username} = useSelector((state) => state.cachedReducer)

  return {
    rows: data ? data.map((item) => {
      const {planning_info} = item
      let progress = planning_info?.total_amount_of_parts || 0
      if (progress && progress !== 0) progress = Math.round((planning_info?.total_amount_of_assembled_parts || 0) * 100 / progress)

      return isMobile ?
        {
          name: item.name,
          assemble: `${planning_info?.total_amount_of_assembled_parts || 0} ${t('OutOf')} ${
            planning_info?.total_amount_of_parts || 0
          }`,
          rotations: planning_info?.total_number_of_iterations - 1 || 0
        }
        :
        {
          count: item?.count || 0,
          name: item.name,
          upload_time: item.upload_time,

          assemble: `${planning_info?.total_amount_of_assembled_parts || 0} ${t('OutOf')} ${
            planning_info?.total_amount_of_parts || 0
          }`,
          rotations: planning_info?.total_number_of_iterations - 1 || 0,
          delete: check_role(username) ? <>
            <IconButton aria-label="edit" onClick={
              () => {
                openPlan()
              }
            }>
              <EditIcon style={{color: 'white', marginRight: '5px'}}/>
            </IconButton>
            <IconButton aria-label="delete" onClick={
              () => {
                openDel()
              }
            }>
            <DeleteIcon style={{color: 'white'}}/>
          </IconButton></> : <></>
        }
    }) : []
  }
}

export default useCreateTableData;

