import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, TableHead, Typography} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import useStyles from "../GeneralTable/GeneralTableStyle";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {StyledGeneralTable} from "../GeneralTable/StyledGeneralTable";
import {useSelector} from "react-redux";


export default function GeneralArchiveTable({   isMobile,
                                       headers = [],
                                       rows = [],
                                       onRowClick = null,
                                       activeItem = null,
                                       tableIsLoading = false,
                                       filter, changeFilter
                                     }) {
  const classes = useStyles()
  const {t} = useTranslation();
  const [searchText, setSearchText] = React.useState('');
  const {operators} = useSelector((state) => state.userReducer)

  const search = (e) => {
    setSearchText(e.target.value)
    changeFilter({search: e.target.value})
  }

  const sort_table = (type) => {
    if(filter.sortField === type){
      if(filter.sortOrder === 'asc'){
        changeFilter({sortField: type, sortOrder: 'desc'})
      }else{
        changeFilter({sortField: type, sortOrder: 'asc'})
      }
    }else{
      changeFilter({sortField: type, sortOrder: 'desc'})
    }
  }

  const filterByOperator = (e) => {
    changeFilter({operator: e.target.value})
  }

  return (
    <StyledGeneralTable>
      <TableContainer component={Paper}
                      style={{height: isMobile ? '100%' : '81vh', borderRadius: '5px', backgroundColor: '#141414'}}>
        <Table className={classes.mainTable} stickyHeader>
          <TableHead style={{height: isMobile ? '100%' : '72px', borderRadius: '5px', backgroundColor: '#141414'}}>
            <TableRow style={{backgroundColor: '#1F1F1F !important', borderBottom: '2px solid #141414'}}>
              {headers.map((item, index) => {
                switch (item) {
                  case('CadModelHeader'):
                    return <TableCell key={item} style={{width: '200px'}} className={"header_cells"}>
                      <IconButton aria-label="filter" onClick={
                        () => sort_table('assembly_name')
                      }>
                        <ImportExportIcon style={{color: 'white'}}/>
                      </IconButton>
                      <Typography color={'white'} fontSize={isMobile ? 12 : 15}
                                  style={{display: 'inline-block'}}> {t(item)}</Typography><br/>
                      <input type="text" value={searchText} className={"search_input"}
                             onChange={(e) => search(e)}/>
                      <SearchIcon className={"search_icon"}/>
                    </TableCell>
                  case('AssembleStarted'):
                    return <TableCell key={item} className={"header_cells"}>
                      <IconButton aria-label="filter" onClick={
                        () => sort_table('sort_date')
                      }>
                        <ImportExportIcon style={{color: 'white'}}/>
                        <Typography color={'white'} fontSize={isMobile ? 12 : 15}> {t('AssembleStarted1')}</Typography>
                      </IconButton>
                      <Typography color={'white'} fontSize={isMobile ? 12 : 15}>{t('AssembleStarted2')}</Typography>
                    </TableCell>
                  case('EfficientTime'):
                    return <TableCell key={item} className={"header_cells"}>
                      <Typography color={'white'} fontSize={isMobile ? 12 : 15}>
                        <span>{t('EfficientTime1')}</span><br/>
                        <span>{t('EfficientTime2')}</span>
                      </Typography>
                    </TableCell>
                  case('OverallAssembleTime'):
                    return <TableCell key={item} className={"header_cells"}>
                      <Typography color={'white'} fontSize={isMobile ? 12 : 15}>
                        <span>{t('OverallAssembleTime1')}</span><br/>
                        <span>{t('OverallAssembleTime2')}</span>
                      </Typography>
                    </TableCell>
                  case('Operator'):
                    return <TableCell key={item} className={"header_cells operator_cell"}>
                      <Typography color={'white'} fontSize={isMobile ? 12 : 15}>
                        <span dangerouslySetInnerHTML={{__html: t(item)}}/>
                      </Typography>
                      <select value={filter.operator} onChange={(e)=>{filterByOperator(e)}}>
                        <option key={"all"} value="all">All</option>
                        {operators.map((item)=> {
                          return <option key={item.username} value={item.username}>{item.username}</option>
                        })}
                      </select>
                    </TableCell>
                  default:
                    return <TableCell key={item} className={"header_cells"}>
                      <Typography color={'white'} fontSize={isMobile ? 12 : 15}>
                        <span dangerouslySetInnerHTML={{__html: t(item)}}/>
                      </Typography>
                    </TableCell>
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableIsLoading ? <TableRow style={{height: '70vh'}}>
                <TableCell colSpan={7} style={{border: 'none'}}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color={'secondary'} size={50}/></div>
                </TableCell>
              </TableRow> :
              rows.map((row, index) => (
                <TableRow key={index}
                          className={index === activeItem ? classes.tableBodyRowSelected : classes.tableBodyRow}
                          onClick={onRowClick ? () => {
                            onRowClick(index, row.name)
                          } : null}>
                  {Object.values(row).map((item, ind) => {
                    return <TableCell key={ind} align="left" style={{
                      borderBottom: index !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                      borderTop: index !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                      backgroundColor: index !== activeItem ? "initial" : "#303030",
                      color: '#EFF3F9',
                      fontSize: isMobile ? '12px' : '15px'
                    }}>
                      {item}
                    </TableCell>
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGeneralTable>
  );
}
