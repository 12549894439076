import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {store, persistor} from "./redux/store";
import {PersistGate} from 'redux-persist/integration/react'
import './i18n'
import 'typeface-fira-sans'
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
         <App/>
     </PersistGate>
  </Provider>);

// ReactDOM.render(
//     <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//             <App/>
//         </PersistGate>
//     </Provider>,
//     document.getElementById('root')
// )



