import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPlannedTasksListRequest} from "../../redux/reducers/readyTasksReducer/actions";
import {CircularProgress, Grid} from "@mui/material";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import TaskButtons from "../../components/ButtonsGroup/TaskButtons/TaskButtons";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import PlannedTasksTable from "./PlannedTasksTable";
import ContinueTask from "./ContinueTask";
import check_role from "../../utils/utils";
import {useTranslation} from "react-i18next";
import {StyledAssemblyPlanPage} from "./StyledAssemblyPlanPage";
import ModelInfoModal from "../ModelsPage/ModelInfoModal/ModelInfoModal";
import ConfirmModal from "../../utils/ConfirmModal/ConfirmModal";
import {SET_PLANNED_TASK_REQUEST} from "../../redux/reducers/readyTasksReducer/types";
import ModalAddToPlan from "../TasksPage/ModalAddToPlan";


function AssemblyPlanPage({isMobile}) {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {selectedPlannedTask, selectedPlannedTaskName, missedPartsList, singlePlannedTaskIsLoading, selectedPlannedCount} = useSelector((state) => state.readyTasksReducer)
    const {username} = useSelector((state) => state.cachedReducer)
    const [openInfoModal, setOpenInfoModal] = React.useState(false);
    const [confOpen, setConfOpen] = useState(false)
    const [planOpen, setPlanOpen] = useState(false)


    useEffect(() => {
        dispatch(getPlannedTasksListRequest())
    }, [dispatch])

    return (
        <StyledAssemblyPlanPage>
            <Grid container style={isMobile ? {width: '100%', height: '100%', display: 'block'}
              : {width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
                <Grid item xs={12} sm={6} style={{height: isMobile ? '45%' : 'initial'}}>
                    <PlannedTasksTable isMobile={isMobile} openDel={() => setConfOpen(true)} openPlan={() => setPlanOpen(true)}/>
                    {/*<TaskSummary/>*/}
                    <div style={{position: 'relative', height: '42px'}}></div>
                </Grid>
                <Grid item xs={12} sm={6} style={isMobile ? {height: '54%', marginTop: '5px'} : {height: 'initial'}}>
                    {selectedPlannedTask ?
                     <div style={{backgroundColor: '#141414', height: '100%'}}>
                         <div style={{height: '80%', border: 'solid 1px white'}}>
                             <ErrorBoundary message={t('unexpected_error')}>
                                 <Viewer3DContainer selectedModel={selectedPlannedTask} missedPartsList={missedPartsList}
                                                    height="100%" isMobile={isMobile} page={'tasks'}/>
                             </ErrorBoundary>
                         </div>
                         {check_role(username) &&
                          <div style={{height: '20%', display: 'flex', alignItems: 'center',
                              justifyContent: 'center', border: 'solid 1px white'}}>
                              <TaskButtons isMobile={isMobile} page={'planned'} openModal={() => setOpenInfoModal(true)}/>
                              <ContinueTask/>
                          </div>
                         }
                     </div>
                                         :
                     <div style={{height: '100%', backgroundColor: '#141414'}}>
                         <div style={{height: '80%', color: 'white', position: 'relative', border: 'solid 1px white'}}>
                             <div style={{
                                 position: 'absolute',
                                 top: '50%',
                                 left: '50%',
                                 transform: 'translate(-50%, -50%)'
                             }}>{singlePlannedTaskIsLoading ?
                                 <CircularProgress size={40} style={{color: 'white'}}/> : t('Choose_model')}</div>
                         </div>
                         <div style={{height: '20%', border: 'solid 1px white', position: 'relative', color: 'white'}}>
                             <div style={{
                                 position: 'absolute',
                                 top: '50%',
                                 left: '50%',
                                 transform: 'translate(-50%, -50%)'
                             }}>{singlePlannedTaskIsLoading ?
                                 <CircularProgress size={40} style={{color: 'white'}}/> : t('Choose_model')}</div>
                         </div>
                     </div>
                    }
                </Grid>
                <ModelInfoModal open={openInfoModal} handleClose={() => setOpenInfoModal(false)} page={"planned"}/>
                <ConfirmModal open={confOpen} handleClose={() => {setConfOpen(false)}} action={
                    () => {
                        dispatch({type: SET_PLANNED_TASK_REQUEST, model_name: selectedPlannedTaskName, count: 0, loadPlanned: () =>{
                                dispatch(getPlannedTasksListRequest())
                            }})
                    }
                    } text={'Are you sure you want to delete the model from the assembly plan?'}/>
                <ModalAddToPlan open={planOpen} handleClose={() => setPlanOpen(false)} count={selectedPlannedCount} reloadPage={() => dispatch(getPlannedTasksListRequest())}
                                total={selectedPlannedTask?.file?.modelTotal || 0} page={'plan'}/>
            </Grid>
        </StyledAssemblyPlanPage>
    );
}

export default AssemblyPlanPage;
