import {
    GET_SIMULATION_TASKS_REQUESTS,
    GET_SIMULATION_TASKS_FAIL,
    GET_SIMULATION_TASKS_SUCCESS,
    GET_SINGLE_TASK_SUCCESS,
    GET_SINGLE_TASK_REQUEST,
    GET_SINGLE_TASK_FAIL,
    SET_SINGLE_MODELS_STATE,
    UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST,
    UPDATE_MODEL_ASSEMBLING_STATUS_SUCCESS,
    UPDATE_MODEL_ASSEMBLING_STATUS_FAIL,
    UPDATE_MODEL_SEND_TO_TASK_REQUEST,
    UPDATE_MODEL_SEND_TO_TASK_SUCCESS,
    UPDATE_MODEL_SEND_TO_TASK_FAIL,
    DELETE_MODEL_REQUEST,
    DELETE_MODEL_SUCCESS,
    DELETE_MODEL_FAIL,
    CLEAR_MODELS_PAGE_RESOURCES,
    UPDATE_MODEL_STATE_IN_REDUCER,
    SET_MODELS_MISSED_PART_LIST,
    CANCEL_SIMULATION,
    CANCEL_SIMULATION_FAIL, CANCEL_SIMULATION_SUCCESS,
} from "./types";
import {changeStatusProcessingTask, removeModel} from "../../../utils/helperFunctions/creatingTableData";
import {getModelsButtonState} from "../../../utils/helperFunctions/buttonsState";

const initialState = {
    simulationTasksAreLoading: false,
    simulationTasks: [],
    total_pages: 0,
    errorOccurred: false,
    selectedModel: null,
    selectedModelInd: null,
    modelInstance: null,
    selectedModelName: null,
    singleModelIsLoading: false,
    singleModelErrorOccurred: false,
    updatingModelStatus: false,
    updatingModelStatusErrorOccurred: false,

    modelIsDeleting: false,
    missedPartsList: [],
    modelsButtonsState: {
        showModelInfoButton: false,
        showSimulationBtn: false,
        showReSimulationBtn: false,
        showSpinnerBtn: false,
        showSaveTaskBtn: false,
        showCancelSimulation: false,
        showDeleteBtn: false,
    }

}


const reducer = (state = initialState, action) => {
        switch (action.type) {
            case GET_SIMULATION_TASKS_REQUESTS:
                return {...state, simulationTasksAreLoading: true}
            case GET_SIMULATION_TASKS_SUCCESS:
                return {...state, simulationTasks: action.models, total_pages: action.total_pages, simulationTasksAreLoading: false}
            case GET_SIMULATION_TASKS_FAIL:
                return {...state, simulationTasks: null, errorOccurred: true, simulationTasksAreLoading: false}
            case GET_SINGLE_TASK_REQUEST:
                return {...state, singleModelIsLoading: true, selectedModelName: action.payload, selectedModel: null, selectedModelInd: action.ind}
            case GET_SINGLE_TASK_SUCCESS:
                return {...state, singleModelIsLoading: false, selectedModel: action.payload}
            case GET_SINGLE_TASK_FAIL:
                return {...state, singleModelIsLoading: false, selectedModel: null, singleModelErrorOccurred: true}
            case SET_SINGLE_MODELS_STATE:
                const {model, btnState} = action.payload
                return {...state, modelsButtonsState: btnState, modelInstance: model}
            case UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST:
                return {
                    ...state, updatingModelStatus: true,
                    updatingModelStatusErrorOccurred: false,
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        ...getModelsButtonState('SIMULATION_IN_PROCESS')
                    }
                }
            case UPDATE_MODEL_ASSEMBLING_STATUS_SUCCESS:
                return {
                    ...state,
                    updatingModelStatus: false,
                    simulationTasks: changeStatusProcessingTask(state.simulationTasks, action.payload, "SIMULATION_IN_PROCESS")
                }

            case UPDATE_MODEL_ASSEMBLING_STATUS_FAIL:
                return {
                    ...state, updatingModelStatus: false, updatingModelStatusErrorOccurred: true,
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        ...getModelsButtonState('SIMULATION_NEW')
                    }
                }
            case UPDATE_MODEL_SEND_TO_TASK_REQUEST:
                return {
                    ...state, updatingModelStatus: true,
                    updatingModelStatusErrorOccurred: false
                }
            case UPDATE_MODEL_SEND_TO_TASK_SUCCESS:
                return {
                    ...state,
                    updatingModelStatus: false,
                    simulationTasks: changeStatusProcessingTask(state.simulationTasks, action.payload, 'SIMULATION_SUCCESS'),
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        ...getModelsButtonState('SIMULATION_SUCCESS')
                    }
                }
            case UPDATE_MODEL_SEND_TO_TASK_FAIL:
                return {
                    ...state, updatingModelStatus: false,
                    updatingModelStatusErrorOccurred: true,
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        ...getModelsButtonState('SIMULATION_SUCCESS')
                    }
                }
            case DELETE_MODEL_REQUEST:
                return {...state, modelIsDeleting: true}
            case DELETE_MODEL_SUCCESS:
                return {
                    ...state, modelIsDeleting: false,
                    selectedModel: null,
                    modelInstance: null,
                    simulationTasks: removeModel(state.simulationTasks, action.payload),
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        showModelInfoButton: false,
                        showSimulationBtn: false,
                        showSpinnerBtn: false,
                        showSaveTaskBtn: false,
                        showCancelSimulation: false,
                        showDeleteBtn: false,
                    }
                }
            case DELETE_MODEL_FAIL:
                return {...state, modelIsDeleting: false, errorOccurred: true}

            case CANCEL_SIMULATION:
                return {...state, modelIsCanceling: true}
            case CANCEL_SIMULATION_SUCCESS:
                return {...state, modelIsCanceling: false,
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        ...getModelsButtonState('SIMULATION_ERROR')
                    }
                }
            case CANCEL_SIMULATION_FAIL:
                return {...state, modelIsCanceling: false, errorOccurred: true,
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        ...getModelsButtonState('SIMULATION_ERROR')
                    }
                }


            case CLEAR_MODELS_PAGE_RESOURCES:
                return {
                    ...state,
                    singleModelIsLoading: false,
                    selectedModelName: null,
                    selectedModel: null,
                    selectedModelInd: null,
                    modelInstance: null,
                    modelsButtonsState: {
                        ...state.modelsButtonsState,
                        showModelInfoButton: false,
                        showSimulationBtn: false,
                        showSpinnerBtn: false,
                        showSaveTaskBtn: false,
                        showDeleteBtn: false,
                        showCancelSimulation: false,
                    }
                }
            case UPDATE_MODEL_STATE_IN_REDUCER:
                return {
                    ...state,
                    simulationTasks: changeStatusProcessingTask(state.simulationTasks, action.payload.modelName, action.payload.state),
                }
            case SET_MODELS_MISSED_PART_LIST:
                return {...state, missedPartsList: action.payload}
            default:
                return state;
        }
    }
;

export default reducer;
