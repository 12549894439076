import React, {Suspense} from 'react';
import {Canvas} from "@react-three/fiber";
import SingleModel from "../Viewer3DContainer/Viewer3DLoader/SingleModelDynamic/SingleModel";
import CameraControls from "../CameraControls/CameraControls";
import {useSelector} from "react-redux";

const Viewer3D = ({selectedModel,missedPartsList,dispatch, beamTransform, page, skip_parts}) => {
    const {viewerSettings} = useSelector((state) => state.cachedReducer)

    return   <Canvas shadows style={{ borderRadius:'13px'}}>
        <Suspense fallback={null}>
            {selectedModel ?
                <SingleModel dispatch={dispatch} viewerSettings={viewerSettings} selectedModel={selectedModel}
                             skip_parts={skip_parts} page={page} missedPartsList={missedPartsList} beamTransform={beamTransform}/> : null}
        </Suspense>
        <CameraControls/>
        <ambientLight intensity={.7}/>
        <directionalLight
            intensity={1}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
        />
    </Canvas>
};

export default Viewer3D;
