import {call, put, takeLatest} from "redux-saga/effects";
import {CANCEL_PROCESSING_TASK_REQUEST, CHECK_NEED_TURN} from "./types";
import {
    cancelProcessingTaskFail,
    cancelProcessingTaskSuccess,
    setStatuses
} from "./actions";
import {apiCheckNeedTurnRequest, cancelProcessingTaskRequestCall} from "./requests";
import {sendGeneralMessage} from "../layoutReducer/actions";
import {IDLE_STATUSES} from "../../../components/StatusesComponets/statusConstants";
import i18n from "../../../i18n";


function* cancelProcessingTaskSaga(action) {
    try {
        yield call(cancelProcessingTaskRequestCall, action.payload)
        yield put(cancelProcessingTaskSuccess())
        yield put(setStatuses(IDLE_STATUSES))
    } catch (e) {
        yield put(cancelProcessingTaskFail())
        yield put(sendGeneralMessage(i18n.t('task_cancel_error'), 'error'))


    }
}

function* apiCheckNeedTurn(action) {
    try {
        const {data} = yield call(apiCheckNeedTurnRequest, action.task_id)
        if (data && data.result === 1){
            action.handleOpen()
        }
    } catch (e) {
        yield put(sendGeneralMessage(i18n.t('beam_check_reverse_error'), 'error'))
    }
}

export default function* statusSaga() {
    // yield takeLatest(CALCULATE_INITIAL_TASK_DATA, calculateInitialTaskDataSaga)
    yield takeLatest(CANCEL_PROCESSING_TASK_REQUEST, cancelProcessingTaskSaga)
    yield takeLatest(CHECK_NEED_TURN, apiCheckNeedTurn)
}
