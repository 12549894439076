import {
	set_parts_files,
	loading_parts,
	set_show_modal,
	loading_beam,
	set_beam_files
} from "../downloaderReducer/actions";
import {sendGeneralMessage} from "../layoutReducer/actions";
import i18n from "../../../i18n";
import {call, put, takeLatest} from "redux-saga/effects";
import {LOAD_FILES_BEAM, LOAD_FILES_PARTS} from "./reducer";
import {select} from 'redux-saga/effects'
import {load_beam_file, load_parts_file} from "./requests";


function* loadVTKScanPartsViewerVTK(action) {
	try {
		if(!action.task_id){
			throw expect('no task_id')
		}
		yield put(set_show_modal(true))
		yield put(loading_parts(true))
		let result = [{
			"name": "matching.vtksz",
			"percentage": 0,
			"loaded": false,
			"file": null
		}];
		yield put(set_parts_files(result))
		let {data: file} = yield call(load_parts_file, result[0].name, action.task_id, 0, action.dispatch)
		let not_found = false
		if(file.byteLength === 16){
			let enc = new TextDecoder("utf-8");
			let arr = new Uint8Array(file);
			if(enc.decode(arr) !== 'File not found'){
				not_found = "File not found"
				file = null
			}
		}
		result[0] = {
			"name": result[0].name,
			percentage: 100,
			"loaded": true,
			"file": file ?? not_found
		}
		yield put(set_parts_files(result))
		yield put(loading_parts(false))
		yield put(sendGeneralMessage(i18n.t('parts_vtk_loading_success'), 'success'))
		const state = yield select()
		if(!state.downloaderReducer.loading_beam){
			yield new Promise(resolve =>
								  setTimeout(() => {
									  action.dispatch(set_show_modal(false));
									  resolve();
								  }, 5000)
			);
		}
	} catch (e) {
		console.log(e)
		yield put(sendGeneralMessage(i18n.t('parts_vtk_loading_error'), 'error'))
	}
}

function* loadScanBeamViewerVTK(action) {
	try {
		if(!action.task_id){
			return
		}
		yield put(loading_beam(true))
		let result = [{
			"name": "beam.vtksz",
			"percentage": 0,
			"loaded": false,
			"file": null
		}];
		yield put(set_beam_files(result))
		let {data: file} = yield call(load_beam_file, result[0].name, action.task_id, 0, action.dispatch)
		let not_found = false
		if(file.byteLength === 16){
			let enc = new TextDecoder("utf-8");
			let arr = new Uint8Array(file);
			if(enc.decode(arr) !== 'File not found'){
				not_found = "File not found"
				file = null
			}
		}
		result[0] = {
			"name": result[0].name,
			percentage: 100,
			"loaded": true,
			"file": file ?? not_found
		}
		yield put(set_beam_files(result))
		yield put(loading_beam(false))
		yield put(sendGeneralMessage(i18n.t('beam_vtk_loading_success'), 'success'))
		const state = yield select()
		if(!state.downloaderReducer.loading_parts){
			yield new Promise(resolve =>
								  setTimeout(() => {
									  action.dispatch(set_show_modal(false))
									  resolve();
								  }, 5000)
			);
		}
	} catch (e) {
		console.log(e)
		yield put(sendGeneralMessage(i18n.t('beam_vtk_loading_error'), 'error'))
	}
}

export default function* statusSaga() {
	yield takeLatest(LOAD_FILES_PARTS, loadVTKScanPartsViewerVTK)
	yield takeLatest(LOAD_FILES_BEAM, loadScanBeamViewerVTK)
}