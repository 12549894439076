import React from 'react';
import GeneralTable from "../GeneralTable/GeneralTable";
import {useDispatch, useSelector} from "react-redux";
import {clearModelsPageResources, getSingleModelRequest} from "../../../redux/reducers/modelsReducer/actions";
import useCreateTableData from "../../LogicalComponents/Hooks/useCreateTableData";

function ModelsTable({isMobile, filter, changeFilter}) {
    const headers = isMobile ? ['CadModelHeader','WillBeAssembledHeader','RotationHeader']
      : ['Status', 'CadModelHeader','DownloadTimeHeader', 'WillBeAssembledHeader','RotationHeader'];
    const {simulationTasks, simulationTasksAreLoading, selectedModelInd} = useSelector((state => state.modelsReducer))
    let {rows} = useCreateTableData(simulationTasks, isMobile)
    const dispatch = useDispatch()

    const onRowClick = (model_name, ind) => {
        if(selectedModelInd === ind){
            dispatch(clearModelsPageResources())
        }else{
            dispatch(getSingleModelRequest(model_name, ind))
        }
    }
    return (
        <GeneralTable headers={headers} rows={rows} onRowClick={onRowClick} isMobile={isMobile}
                      activeItem={selectedModelInd} tableIsLoading={simulationTasksAreLoading}
                      filter={filter} changeFilter={changeFilter}/>
    );
}

export default ModelsTable;
