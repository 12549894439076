import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Modal} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StyledModal} from "./StyledModal";
import {getDocUrl} from "./PdfFormFiller";
import {useSelector} from "react-redux";


function Inspection({open, handleClose, task_id}) {
	const {t} = useTranslation();
	const {inspection} = useSelector((state) => state.statusReducer)
	const [pdfUrl, setPdfUrl] = useState(null)
	const [selected, setSelected] = useState(null)
	const [loading, setLoading] = useState(false)

	async function fetchData(key){
		setPdfUrl(await getDocUrl(inspection[key]))
	}

	useEffect(() => {
		if(inspection && '0' in inspection && inspection[0].beam_orientation !== null){
			loadData(0).then(r => console.log('first time loaded'))
		}
	}, [inspection]);

	const loadData = async (key) => {
		if(selected == key){
			return
		}
		setLoading(true)
		setSelected(key)
		await fetchData(key).then(r => console.log('done')).catch((e) => {
			console.log(e);
		})
		setLoading(false)
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledModal>
				<Box sx={{ width: '100%', height: '100%' }}>{loading ?
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
						<CircularProgress size={40} style={{color: 'black'}}/></div> :
					inspection ? <>
						<Box sx={{ borderBottom: 1, borderColor: 'white', height: '40px'}}>
							{Object.keys(inspection).map((key, index) => (
								<Button key={index} color='secondary' variant='contained' onClick={() => {loadData(key)}} className={selected == key ? 'sel_insp_buttons' : 'insp_buttons'}>
									Beam orientation {inspection[key].beam_orientation ?? key}
								</Button>
							))}
						</Box>
						<Box sx={{ borderBottom: 1, borderColor: 'white', height: 'calc(100% - 40px)'}}>
							<iframe title="scheme" src={pdfUrl+'#navpanes=0'} type="application/pdf"
											style={{height: 'calc(100% - 4px)', width: 'calc(100% - 4px)'}}></iframe>
						</Box>
					</>
						:
						<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
							Inspection is emtpy...</div>
				}
				</Box>
			</StyledModal>
		</Modal>
	);
}

export default Inspection;
