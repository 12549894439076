import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    imgLogo: {
        width: '300px',
        height: '300px'
    },
    input: {
        minWidth: '400px!important',
        "& .MuiInput-underline:before": {
            borderBottomColor: '#333333'
        },

    },
    inputText: {
        color: "white !important"
    },

    textLabel: {
        color: 'white',
        // fontFamily:'Inter'
    },
    checkBoxLabel: {
        fontSize: '15px',
        color: '#333333'
    },
    headerControl: {color: '#EFF3F9', fontSize: '35px !important'},
    secondHeader: {color: '#EFF3F9', fontSize: '18px !important', marginTop: '10px'},
    checkboxLabel: {position: 'relative', marginTop: '20px'},
    forgotLink: {
        position: 'absolute',
        right: '0px',
        top: '10px',
        color: "#54fcef",
        fontFamily: '"Fira sans",sans-serif'
    },
    signUpButton: {width: '175px', height: '40px'},
    loginButton: {width: '175px', height: '40px'},
    buttonContainer: {marginTop: '40px', display:'flex',justifyContent:'center'},
    asterisk: {
        display: 'none'
    },
    textField: {
        fontSize: 50,
    },
    loader: {left: '50%', position: 'absolute'}
});

export default useStyles
