import React from 'react';
import Paper from "@mui/material/Paper";
import RobotControlServices from "../../components/RobotControlServices/RobotControlServices";
import {useSelector} from "react-redux";
import check_role from "../../utils/utils";

function ServicesPage({isMobile}) {
    const {username} = useSelector((state) => state.cachedReducer)
    return <Paper style={{
        backgroundColor: '#141414',
        borderRadius: '10px',
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <div style={{width: '100%'}}>
            {check_role(username) &&
                <RobotControlServices isMobile={isMobile}/>
            }
        </div>
    </Paper>
}

export default ServicesPage;
