import React from 'react';
import {CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AssembledCounter from "../../ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import {EXECUTOR_IS_RUNNING, PLANNER_IS_RUNNING} from "../../statusConstants";
import PlannedCounter from "../../ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";

function ProcessAction({status, isMobile}) {
    const {t} = useTranslation()
    return (
        <div style={{height: isMobile ? '100%' : '100%', flexDirection: 'column'}} className={'globalCentered'}>
            <Typography variant={'h4'} color={'white'}> {t(status)}</Typography>
            <CircularProgress color="secondary"  style={{marginLeft:'30px', padding: '10px 0px'}}/>
            {status === EXECUTOR_IS_RUNNING && <AssembledCounter/>}
            {status === PLANNER_IS_RUNNING && <PlannedCounter/>}
        </div>
    );
}

export default ProcessAction;
