export const GET_READY_TASKS_REQUEST = 'GET_READY_TASKS_REQUEST'
export const GET_READY_TASKS_SUCCESS = 'GET_READY_TASKS_SUCCESS'
export const GET_READY_TASKS_FAIL = 'GET_READY_TASKS_FAIL'

export const SET_PLANNED_TASK_REQUEST = 'SET_PLANNED_TASK_REQUEST'
export const GET_PLANNED_TASKS_REQUEST = 'GET_PLANNED_TASKS_REQUEST'
export const GET_PLANNED_TASKS_SUCCESS = 'GET_PLANNED_TASKS_SUCCESS'
export const GET_PLANNED_TASKS_FAIL = 'GET_PLANNED_TASKS_FAIL'

export const GET_SINGLE_READY_TASK_REQUEST = 'GET_SINGLE_READY_TASK_REQUEST'
export const GET_SINGLE_READY_TASK_SUCCESS = 'GET_SINGLE_READY_TASK_SUCCESS'
export const GET_SINGLE_READY_TASK_FAIL = 'GET_SINGLE_READY_TASK_FAIL'


export const SET_LIST_OF_MISSED_PARTS = 'SET_LIST_OF_MISSED_PARTS'
export const SET_LIST_OF_MISSED_PARTS_PLANNED = 'SET_LIST_OF_MISSED_PARTS_PLANNED'

export const GET_SINGLE_PLANNED_TASK_REQUEST = 'GET_SINGLE_PLANNED_TASK_REQUEST'
export const GET_SINGLE_PLANNED_TASK_SUCCESS = 'GET_SINGLE_PLANNED_TASK_SUCCESS'
export const GET_SINGLE_PLANNED_TASK_FAIL = 'GET_SINGLE_PLANNED_TASK_FAIL'
export const UNSELECT_PLANNED_TASK = 'UNSELECT_PLANNED_TASK'
export const UNSELECT_TASK = 'UNSELECT_TASK'

export const TASK_DELETE_MODEL_REQUEST = 'TASK_DELETE_MODEL_REQUEST'
export const TASK_DELETE_MODEL_SUCCESS = 'TASK_DELETE_MODEL_SUCCESS'
export const TASK_DELETE_MODEL_FAIL = 'TASK_DELETE_MODEL_FAIL'


export const CONTINUE_TASK_PROCESSING_REQUEST = 'CONTINUE_TASK_PROCESSING_REQUEST'
export const SUBMIT_TASK_PROCESSING_REQUEST = 'SUBMIT_TASK_PROCESSING_REQUEST'
export const SUBMIT_TASK_PROCESSING_SUCCESS = 'SUBMIT_TASK_PROCESSING_SUCCESS'
export const SUBMIT_TASK_PROCESSING_FAIL = 'SUBMIT_TASK_PROCESSING_FAIL'

export const ASSEMBLY_PLAN_PRIORITY_REQUEST = 'ASSEMBLY_PLAN_PRIORITY_REQUEST'
export const ASSEMBLY_PLAN_PRIORITY_END = 'ASSEMBLY_PLAN_PRIORITY_END'
