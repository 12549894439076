import {
    GET_OPERATORS_SUCCESS,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS, SEND_LOGS_FAIL,
    SEND_LOGS_REQUEST,
    SEND_LOGS_SUCCESS
} from "./types";

const initialState = {
    userIsLoading: false,
    operators: [],
    sendLogsLoading: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {...state, userIsLoading: true}
        case LOGIN_SUCCESS:
            return {...state, userIsLoading: false}
        case LOGIN_FAIL:
            return {...state, userIsLoading: false}
        case GET_OPERATORS_SUCCESS:
            return {...state, operators: action.payload}
        case SEND_LOGS_REQUEST:
            return {...state, sendLogsLoading: true}
        case SEND_LOGS_SUCCESS:
            return {...state, sendLogsLoading: false}
        case SEND_LOGS_FAIL:
            return {...state, sendLogsLoading: false}
        default:
            return state;
    }
};

export default reducer;