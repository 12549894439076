import React from 'react';
import {useSelector} from "react-redux";
import {
    IDLE,
    SCANNING_BEAM_ERROR,
    SCANNING_BEAM_IS_RUNNING,
    SCANNING_BEAM_ROBOT_ERROR,
    SCANNING_BEAM_SUCCESS, SCANNING_BEAM_TIMEOUT_ERROR,
} from "../statusConstants";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import StatusInfo from "../GeneralComponents/StatusInfro/StatusInfo";


const ScanningBeamComponent = ({screenVersion =false, isMobile}) => {
    const {scanningBeamStatus} = useSelector(state => state.statusReducer)

    const statusRender = (status) => {
        switch (status) {
            case IDLE:
            case SCANNING_BEAM_SUCCESS:
                return <StatusInfo status={status} isMobile={isMobile}/>
            case SCANNING_BEAM_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={status}/> : <ProcessingActionScreen status={status}/>
            case SCANNING_BEAM_ERROR:
            case SCANNING_BEAM_TIMEOUT_ERROR:
            case SCANNING_BEAM_ROBOT_ERROR:
                return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
            default:
                return <UnknownStatus isMobile={isMobile}/>
        }
    }

    return statusRender(scanningBeamStatus)
};

export default ScanningBeamComponent;
