import React, {useRef} from 'react';
import ReactHlsPlayer from "react-hls-player";
import './PlayerComponentStyle.css'

const PlayerComponent = ({streamUri}) => {
    const playerRef = useRef();

    return (
        <div style={{width: '100%', height: '100%', position: 'relative', backGroundColor: '#333333'}}>
            {streamUri && <ReactHlsPlayer
                src={streamUri}
                muted={true}
                autoPlay={true}
                controls={true}
                height='100%'
                width="100%"
                playerRef={playerRef}
            />}
        </div>


    );
};

export default PlayerComponent;