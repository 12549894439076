import React from 'react';
import Box from "@mui/material/Box";

function PdfScheme({selectedModel}) {

  return (
    <>
      <Box style={{backgroundColor: '#141414', borderRadius: '8px', height: '100%'}}>
        <iframe title="scheme" src={"data:application/pdf;base64,"+selectedModel?.file?.drawingPDF}
        style={{height: 'calc(100% - 4px)', width: 'calc(100% - 4px)'}}></iframe>
      </Box>
    </>
  );
}

export default PdfScheme;
