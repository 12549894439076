import React from 'react';
import Rack from "../../Rack/Rack";
import Box from "@mui/material/Box";

const Screen1Mounting = () => {
    return (
        <Box style={{width: '100%', height: '100vh'}} className={'globalCentered'}>
            <Rack screenMode={true}/>
        </Box>
    );
};

export default Screen1Mounting;