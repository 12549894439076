import {
    CANCEL_SIMULATION, CANCEL_SIMULATION_FAIL, CANCEL_SIMULATION_SUCCESS,
    CLEAR_MODELS_PAGE_RESOURCES,
    DELETE_MODEL_FAIL,
    DELETE_MODEL_REQUEST,
    DELETE_MODEL_SUCCESS,
    GET_SIMULATION_TASKS_FAIL,
    GET_SIMULATION_TASKS_REQUESTS,
    GET_SIMULATION_TASKS_SUCCESS,
    GET_SINGLE_TASK_FAIL,
    GET_SINGLE_TASK_REQUEST,
    GET_SINGLE_TASK_SUCCESS,
    SET_MODELS_MISSED_PART_LIST,
    SET_SINGLE_MODELS_STATE,
    TURN_OFF_VIEWER_LOADING,
    TURN_ON_VIEWER_LOADING,
    UPDATE_MODEL_ASSEMBLING_STATUS_FAIL,
    UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST,
    UPDATE_MODEL_ASSEMBLING_STATUS_SUCCESS,
    UPDATE_MODEL_SEND_TO_TASK_FAIL,
    UPDATE_MODEL_SEND_TO_TASK_REQUEST,
    UPDATE_MODEL_SEND_TO_TASK_SUCCESS,
    UPDATE_MODEL_STATE_IN_REDUCER
} from "./types";

export const getSimulationTaskRequest = (filter) => ({type: GET_SIMULATION_TASKS_REQUESTS, filter})
export const getSimulationTaskSuccess = (models, total_pages) => ({type: GET_SIMULATION_TASKS_SUCCESS, models, total_pages})
export const getSimulationTaskFail = () => ({type: GET_SIMULATION_TASKS_FAIL})

export const getSingleModelRequest = (modelName, ind) => ({type: GET_SINGLE_TASK_REQUEST, payload: modelName, ind: ind})
export const getSingleModelSuccess = (data) => ({type: GET_SINGLE_TASK_SUCCESS, payload: data})
export const getSingleModelFail = () => ({type: GET_SINGLE_TASK_FAIL})

export const turnOnViewerLoading = () => ({type: TURN_ON_VIEWER_LOADING})
export const turnOffViewerLoading = () => ({type: TURN_OFF_VIEWER_LOADING})

export const setSingleModelState = (model, btnState) => ({
    type: SET_SINGLE_MODELS_STATE, payload: {model, btnState}
})


export const startPreAssembly = (modelName, filter) => ({
    type: UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST, payload: modelName, filter
})

export const updateModelStatusSuccess = (name) => ({
    type: UPDATE_MODEL_ASSEMBLING_STATUS_SUCCESS, payload: name
})

export const updateModelStatusFail = () => ({
    type: UPDATE_MODEL_ASSEMBLING_STATUS_FAIL
})


export const updateSimulationTaskStatus = (modelName) => ({
    type: UPDATE_MODEL_SEND_TO_TASK_REQUEST, payload: modelName
})
export const updateSimulationTaskStatusSuccess = (modelName) => ({
    type: UPDATE_MODEL_SEND_TO_TASK_SUCCESS, payload: modelName
})
export const updateSimulationTaskStatusFail = () => ({
    type: UPDATE_MODEL_SEND_TO_TASK_FAIL
})


export const deleteModelRequest = (modelName) => ({
    type: DELETE_MODEL_REQUEST, payload: modelName
})
export const deleteModelSuccess = (modelName) => ({
    type: DELETE_MODEL_SUCCESS, payload: modelName
})
export const deleteModelFail = () => ({
    type: DELETE_MODEL_FAIL
})

export const cancelSimulation = (model_name) => ({
    type: CANCEL_SIMULATION, payload: model_name
})
export const cancelSimSuccess = () => ({
    type: CANCEL_SIMULATION_SUCCESS
})
export const cancelSimFail = () => ({
    type: CANCEL_SIMULATION_FAIL
})

export const clearModelsPageResources = () => ({
    type: CLEAR_MODELS_PAGE_RESOURCES
})


export const updateModelStateInReducer = (modelName, state) => ({
    type: UPDATE_MODEL_STATE_IN_REDUCER, payload: {modelName, state}
})

export const setListOfMissedPartsModels = (list) => ({
    type: SET_MODELS_MISSED_PART_LIST, payload: list
})
