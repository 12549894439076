import styled from 'styled-components';

export const StyledOEE = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Fira sans",sans-serif;
  .hint_graph1{
    font-size: 14px;
    color: black;
    margin-right: -27px;
  }
  .hint_graph1_0{
    font-size: 14px;
    color: black;
    margin-right: -5px;
  }
  .hint_trans{
    transform: rotate(-25deg);
    padding-bottom: 10px;
  }
  .rv-xy-plot {
    font-family: "Fira sans",sans-serif;
  }
  .x_axis, .y_xis, .x_axis line, .y_axis line{
    stroke: black;
  }
  .y_axis text{
    fill: black;
    font-weight: 600;
  }
  .x_axis text{
    stroke: none;
    fill: black;
    font-weight: 600;
    transform: rotate(-15deg);
    transform-origin: 65px 0;
  }
  .graph_title{
    width: 100%;
    padding: 20px 0 10px 0;
  }
  .total_box{
    width: 21%;
    display: inline-flex;
    background-color: #6b6b76;
    height: 100%;
    position: relative;
    margin: 0 2px;
    min-width: 200px;
  }
  .total_box_last{
    width: 34%;
    display: inline-flex;
    background-color: #6b6b76;
    height: 100%;
    position: relative;
    margin: 0 2px;
  }
  .total_box_title{
    position: absolute;
    padding: 3px;
  }
  .total_box_value{
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
    width: 100%;
    min-width: 321px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
`;
