import styled from 'styled-components';

export const StyledConfirmModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 545px;
    border: 2px solid #000;
    box-shadow: 0 5px 15px rgba(0,0,0,0);
    padding: 2px;
    background-color: white;
		.modal_body{
				height: calc(90% - 10px);
				border-bottom: 2px solid #000;
				text-align: center;
				padding-top: 10px;
		}
		.modal_footer{
				height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
		}
		.modal_list{
				text-align: start;
		}
    .title{
				padding: 10px 20px 0px 20px;
		}
		.modal_content{
				height: 75%;
		}
		.warning{
        height: 25%;
		}
		.warning_body{
        background-color: #FF8800;
        color: rgb(0, 12, 42);
        border-radius: 16px;
        font-size: 18px;
        line-height: 1.5em;
        margin: 0px 10px 1.5em;
        padding: 1em 1.25em;
		}
		.parts_div{
				background: #ddd;
				max-height: 55px;
				overflow-y: scroll;
        color: white;
				padding: 0px;
				word-break: break-all;
		}
`;
